import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCoacheeSession } from "utils/apiMethods";
const initialState = {
  proposed_session_list: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
  },
  confirmed_session_list: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
  },
  allItemsCheckedProposed: false,
  allItemsCheckedConfirmed: false,
  isAnyItemCheckedProposed: false,
  isAnyItemCheckedConfirmed: false,
  isAnyItemChecked: true,
  searchQuery: "",
  checkedItemsProposed: [],
  checkedItemsConfirmed: [],
};
export const mySessionSlice = createSlice({
  name: "mySession",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMyProposedConfirmedSession.fulfilled,
      (state, { payload }) => {
        const { confirmed_session_list, proposed_session_list } = payload;
        setStateData(
          state,
          {
            // currentPage: confirmed_session_list.current_page,
            // pageSize: confirmed_session_list.page_size,
            // totalItem: confirmed_session_list.total_item,
            // totalPage: confirmed_session_list.total_page,
            data: confirmed_session_list,
          },
          "confirmed_session_list"
        );
        setStateData(
          state,
          {
            // currentPage: proposed_session_list.current_page,
            // pageSize: proposed_session_list.page_size,
            // totalItem: proposed_session_list.total_item,
            // totalPage: proposed_session_list.total_page,
            data: proposed_session_list,
          },
          "proposed_session_list"
        );
      }
    );
  },
});
const setStateData = (state, payload, dataType) => {
  if (payload.data) {
    // this step is taken to add another property 'isChecked' to the data table
    // payload.data.map((item) => {
    //   if (dataType === "confirmed_session_list") {
    //     item.isCheckedIndividual = false;
    //   } else if (dataType === "group_data") {
    //     item.isCheckedGroup = false;
    //   }
    //   return item;
    // });
    // if (dataType === "proposed_session_list") {
    //   state.allItemsCheckedIndividual = false;
    // } else if (dataType === "group_data") {
    //   state.allItemsCheckedGroup = false;
    // }
    state[dataType] = payload;
  } else {
    state[dataType] = [];
  }
  return state;
};
export const getMyProposedConfirmedSession = createAsyncThunk(
  "mySession/getMyProposedConfirmedSession",
  async ({ current_page = 1, page_size = 5 }) => {
    const response = await getCoacheeSession.getProposedConfirmedSession(
      current_page,
      page_size
    );
    return response.data;
  }
);

export const {} = mySessionSlice.actions;
export default mySessionSlice.reducer;
