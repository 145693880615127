const form = {
  customer_id: {
    name: "customer_id",
    label: "Customer Organization *",
    type: "select",
    options: [],
    errorMsg: "Customer Organization is required.",
  },
  sponsoring_entity_name: {
    name: "sponsoring_entity_name",
    label: "Entity Name *",
    type: "text",
    errorMsg: "Entity Name is required.",
  },
  country: {
    name: "country",
    label: "Country",
    type: "select",
    options: [],
  },
  state: {
    name: "state",
    label: "State",
    type: "select",
    options: [],
  },
  city: {
    name: "city",
    label: "City",
    type: "select",
    options: [],
  },
  postal_code: {
    name: "postal_code",
    label: "Postal Code",
    type: "text",
  },
  address: {
    name: "address",
    label: "Address",
    type: "textarea",
  },
  document_name: {
    name: "document_name",
    label: "Upload Document",
    type: "file",
    hidden: true,
  },
  affiliatedSponsors: {
    name: "affiliatedSponsors",
    label: "Affiliated Sponsors",
    type: "grid",
    gridPage: "sponsorEntity",
    rows: {
      currentPage: 1,
      data: [],
      pageSize: 5,
      totalItem: 5,
      totalPage: 1,
    },
  },
};

export default form;
