/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { transparent, background, disabled, primary } = colors;

const select = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      padding: `${pxToRem(16)}`,
      background: background.default,
      whiteSpace: "normal",
      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
      "& ~ .MuiSelect-icon": {
        fontSize: `${pxToRem(32)} !important`,
      },
      "&.Mui-disabled": {
        background: disabled.main,
        border: `0 solid ${rgba(0, 0, 0, 0.125)}`,
      },
      "&.Mui-disabled ~ .MuiOutlinedInput-notchedOutline": {
        border: `none !important`,
      },
      "&.Mui-disabled ~ .MuiSelect-icon": {
        display: "none",
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      color: primary.main,
    },
  },
};

export default select;
