import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import usersReducer from "./features/dashboard/usersSlice";
import groupsReducer from "./features/dashboard/groupsSlice";
import customersReducer from "./features/dashboard/customersSlice";
import sponsoringEntityReducer from "./features/dashboard/sponsoringEntitySlice";
import dataTableReducer from "./features/dashboard/dataTableSlice";
import adminControlReducer from "./features/dashboard/adminControlSlice";
import engagementConsoleReducer from "./features/dashboard/engagementConsoleSlice";
import mySessionSliceReducer from "./features/dashboard/mySessionSlice";
import viewSessionListSlice from "./features/dashboard/viewSessionListSlice";
import snackbarSlice from "./features/dashboard/snackbarSlice";
import keywordReducer from "./features/dashboard/keywordSlice";
import RolesPermissionsReducer from "./features/dashboard/roleAndPermissions";
import loaderSlice from "./features/dashboard/loaderSlice";
import { isSnackBar } from "./features/dashboard/snackbarSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import toastifySlice from "./features/dashboard/toastifySlice";
import coacheeTabsSlice from "./features/dashboard/coacheeTabsSlice";

const authPersistConfig = {
  key: "auth",
  storage
};

const customerPersistConfig = {
  key: "customers",
  storage,
  blacklist: [
    "customers",
    "customerDropdown",
    "customerDropdownForForms",
    "selectedCustomerForForms",
    "allItemsChecked",
    "isAnyItemChecked"
  ]
};
const dataTablePersistConfig = {
  key: "dataTable",
  storage,
  whitelist: ["countriesDropDown"]
};

const keywordsPersistConfig = {
  key: "keywords",
  storage
};

const RolesPermissionsPersistConfig = {
  key: "roles_and_permissions",
  storage
};
const coacheeSlicePersistConfig = {
  key: "coacheeTabs",
  storage,
  blacklist: [
    "activePanel",
    "subActivePanel",
    "authorValuesChanged",
    "interactiveToolsList"
  ]
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  keywords: persistReducer(keywordsPersistConfig, keywordReducer),
  Roles_Permissions: persistReducer(
    RolesPermissionsPersistConfig,
    RolesPermissionsReducer
  ),
  users: usersReducer,
  groups: groupsReducer,
  customers: persistReducer(customerPersistConfig, customersReducer),
  sponsoringEntities: sponsoringEntityReducer,
  dataTable: persistReducer(dataTablePersistConfig, dataTableReducer),
  adminControl: adminControlReducer,
  engagementConsole: engagementConsoleReducer,
  mySession: mySessionSliceReducer,
  viewSessionList: viewSessionListSlice,
  snackbar: snackbarSlice,
  loader: loaderSlice,
  toastify: toastifySlice,
  coacheeTabs: persistReducer(coacheeSlicePersistConfig, coacheeTabsSlice)
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
setTimeout(() => {
  store.dispatch(isSnackBar({ isOpen: false }));
}, 5000);
export const persistor = persistStore(store);
