const customerForm = {
  organization_name: {
    name: "customer_name",
    label: "Organization Name *",
    type: "text",
    errorMsg: "Organization Name is required.",
  },

  country: {
    name: "country",
    label: "Country",
    type: "select",
    options: [],
  },
  state: {
    name: "state",
    label: "State",
    type: "select",
    options: [],
  },
  city: {
    name: "city",
    label: "City",
    type: "select",
    options: [],
  },
  postal_code: {
    name: "postal_code",
    label: "Postal Code",
    type: "text",
  },
  address: {
    name: "address",
    label: "Address",
    type: "textarea",
  },
  contacts: [
    {
      first_name: {
        name: "first_name",
        label: "First Name *",
        type: "text",
        errorMsg: "First Name is required.",
      },
      last_name: {
        name: "last_name",
        label: "Last Name *",
        type: "text",
        errorMsg: "Last Name is required.",
      },
      email: {
        name: "email",
        label: "Email Address *",
        type: "text",
        errorMsg: "Email Address is required.",
        invalidMsg: "Email Address is invalid",
      },
      position: {
        name: "position",
        label: "Position/Title",
        type: "text",
      },
      direct_phone_number: {
        name: "direct_phone_number",
        label: "Direct Phone #",
        // type: "text",
        type: "phone",
        errorMsg: "Direct Phone Number is required.",
      },
      phone_number: {
        name: "phone_number",
        label: "Cell Phone #",
        // type: "text",
        type: "phone",
        errorMsg: "PhoneNumber is required.",
      },
    },
  ],
};

export default customerForm;
