import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "custom-hooks/useAuth";

function PublicRoute() {
  const isAuthenticated = useAuth();

  const location = useLocation();

  const origin = location.state?.from?.pathname || "/dashboard";

  // If user is already logged in, redirect them to the page them came from, otherwise let them access public routes
  return isAuthenticated ? <Navigate to={origin} replace /> : <Outlet />;
}

export default PublicRoute;
