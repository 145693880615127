import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { coacheeProgress } from "utils/apiMethods";
import { activityReport } from "utils/apiMethods";
import { coacheeDashboardAPIs } from "utils/apiMethods";
import { getCoacheeActionPlansbyEngagment } from "utils/apiMethods";
import { coachingProgramByMe } from "utils/apiMethods";
import { engagementConsoleAPIs } from "utils/apiMethods";
import { coacheeActionPlan } from "utils/apiMethods";
import { actionPlanTemplateAPIs } from "utils/apiMethods";
import {
  dashboard,
  accessAuditLog,
  manageDocumentConsole,
  getCoacheeSession
} from "utils/apiMethods";
import { SelectAllItemsText } from "utils/constants";

const genericConfigs = {
  method: "get",
  headers: {
    "X-CSCAPI-KEY": "U0xxcjdKS0ZHcE90RXFZZ1VuYVNnWlM2ZU54cUh6MzVybWZWajFSTg=="
  }
};
const initialState = {
  data: { currentPage: 1, data: [], pageSize: 10, totalItem: 5, totalPage: 1 },
  todolist: {
    currentPage: 1,
    data: [],
    pageSize: 10,
    totalItem: 5,
    totalPage: 1
  },
  assignDocuments: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1
  },
  activityReport: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1
  },
  upcomingSessionLoading: true,
  historySessionLoading: true,
  metaData: {},
  engagementData: [],
  coachingProgram: [],
  allItemsChecked: false,
  allAssignedItemsChecked: false,
  allActivityItemChecked: false,
  isAnyItemChecked: true,
  isAnyAssignedItemChecked: false,
  isAnyActivityItemChecked: false,
  searchQuery: "",
  upcomingError: false,
  historyError: false,
  checkedItems: [],
  assignedCheckedItems: [],
  activityCheckedItems: [],
  countriesDropDown: [],
  statesDropDown: [],
  citiesDropDown: [],
  selectedCountry: "",
  selectedState: "",
  selectedCity: "",
  session_type: "",
  selectedDatePicker: new Date(),
  hookSnack: {
    show: false,
    type: "error",
    title: "",
    content: "",
    icon: "error"
  },
  calenderSessionData: [],
  ActionPlanDropdown: [],
  GroupCoacheeDropdown: [],
  ResourceData: null,
  selectedCoachee: null,
  selectedActionPlan: null,
  loading: false,
  error: null,
  success: null,
  reloadData: false,
  isSessionsLoading: false
};

export const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setSelectedDatePicker: (state, { payload }) => {
      if (payload) {
        state.selectedDatePicker = payload;
      }
    },
    setSessionType: (state, { payload }) => {
      state.session_type = payload;
    },
    resetDataTable: (state) => {
      state = initialState;
    },
    resetEngagementData: (state) => {
      state.engagementData = [];
    },
    resetApiData: (state) => {
      state.data.data = [];
      state.calenderSessionData = [];
      state.upcomingSessionLoading = true;
      state.historySessionLoading = true;
    },
    setHookSnack: (state, { payload }) => {
      // const { show, type, title, content } = payload;
      state.hookSnack = { ...state.hookSnack, ...payload };
    },
    setAllItemsChecked: (state, { payload: allItemsChecked }) => {
      // if data length is empty, check box would not checked
      state.allItemsChecked = state.data.data.length ? allItemsChecked : false;
      state.isAnyItemChecked = allItemsChecked;
      if (allItemsChecked) {
        state.checkedItems = state.data.data;
      } else {
        state.checkedItems = [];
      }
      state.data.data.map((item) => {
        item.isChecked = allItemsChecked;
        return item;
      });
    },
    setAllAssignItemChecked: (state, { payload: allAssignedItemsChecked }) => {
      state.allAssignedItemsChecked = state.assignDocuments.data.length
        ? allAssignedItemsChecked
        : false;
      state.isAnyAssignedItemChecked = allAssignedItemsChecked;
      if (allAssignedItemsChecked) {
        state.assignedCheckedItems = state.assignDocuments.data;
      } else {
        state.assignedCheckedItems = [];
      }
      state.assignDocuments.data.map((item) => {
        item.isChecked = allAssignedItemsChecked;
        return item;
      });
    },
    setAllActivityItemChecked: (state, { payload: allActivityItemChecked }) => {
      state.allActivityItemChecked = state.activityReport.data.length
        ? allActivityItemChecked
        : false;
      state.isAnyActivityItemChecked = allActivityItemChecked;
      if (allActivityItemChecked) {
        state.activityCheckedItems = state.activityReport.data;
      } else {
        state.activityCheckedItems = [];
      }
      state.activityReport.data.map((item) => {
        item.isChecked = allActivityItemChecked;
        return item;
      });
    },
    setItemChecked: (state, { payload: id }) => {
      let matchedItem = state?.data?.data?.find((item) => {
        return (
          item.session_id === id ||
          item.id === id ||
          item.customer_id === id ||
          item.action_plan_template_id === id ||
          item.coaching_program_id === id ||
          item.audit_id === id ||
          item.journal_entry_id === id ||
          item.feed_back_id === id ||
          item.document_id === id ||
          item.coachee_id === id
        );
      });

      matchedItem.isChecked = !matchedItem?.isChecked;
      // adding and removing data from checked item array
      if (matchedItem.isChecked) {
        state.checkedItems = [...state.checkedItems, matchedItem];
      } else {
        // if any of item unchecked so check all item will be false so checked would be remove from check all
        state.allItemsChecked = false;
        state.checkedItems = state.checkedItems.filter((item) => {
          const {
            id,
            customer_id,
            coaching_program_id,
            action_plan_template_id,
            audit_id,
            session_id,
            journal_entry_id,
            feed_back_id,
            coachee_id
          } = item;
          return (
            (id && item.id !== matchedItem.id) ||
            (customer_id && item.customer_id !== matchedItem.customer_id) ||
            (coaching_program_id &&
              item.coaching_program_id !== matchedItem.coaching_program_id) ||
            (action_plan_template_id &&
              item.action_plan_template_id !==
                matchedItem.action_plan_template_id) ||
            (audit_id && item.audit_id !== matchedItem.audit_id) ||
            (session_id && item.session_id !== matchedItem.session_id) ||
            (journal_entry_id &&
              journal_entry_id !== matchedItem.journal_entry_id) ||
            (feed_back_id && feed_back_id !== matchedItem.feed_back_id) ||
            (coachee_id && item.coachee_id !== matchedItem.coachee_id)
            //&& item.sponsoring_entity_id !== matchedItem.sponsoring_entity_id
          );
        });
      }

      // sorting in alphabetical order
      state.checkedItems = state.checkedItems.sort((a, b) => {
        let a_name =
          a.first_name ||
          a.customer_name ||
          a.coaching_program_name ||
          a.action_plan_template_id ||
          a.session_name ||
          a.audit_id ||
          a.coachee_id;
        let b_name =
          b.first_name ||
          b.customer_name ||
          b.coaching_program_name ||
          b.action_plan_template_id ||
          b.session_name ||
          b.audit_id ||
          b.coachee_id;

        // a_name = a_name.toLowerCase();
        // b_name = b_name.toLowerCase();

        if (a_name < b_name) {
          return -1;
        }
        if (a_name > b_name) {
          return 1;
        }
        return 0;
      });

      // check if any item is checked
      if (state.data.data.some((item) => item.isChecked === true)) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },
    setAssignItemChecked: (state, { payload: id }) => {
      let matchedItem = state.assignDocuments.data.find((item) => {
        return item.document_id === id;
      });
      matchedItem.isChecked = !matchedItem?.isChecked;
      if (matchedItem.isChecked) {
        state.assignedCheckedItems = [
          ...state.assignedCheckedItems,
          matchedItem
        ];
      } else {
        state.allAssignedItemsChecked = false;
        state.assignedCheckedItems = state.assignedCheckedItems.filter(
          (item) => {
            const { document_id } = item;
            return document_id && item.document_id !== matchedItem.document_id;
          }
        );
      }
      state.assignedCheckedItems = state.assignedCheckedItems.sort((a, b) => {
        let a_name = a.user_first_name;
        let b_name = b.user_first_name;
        if (a_name < b_name) {
          return -1;
        }
        if (a_name > b_name) {
          return 1;
        }
        return 0;
      });
    },
    setActivityItemChecked: (state, { payload: id }) => {
      let matchedItem = state?.activityReport?.data?.find(
        (item) => item.engagement_id === id
      );
      matchedItem.isChecked = !matchedItem?.isChecked;
      // adding and removing data from checked item array
      if (matchedItem.isChecked) {
        state.activityCheckedItems = [
          ...state.activityCheckedItems,
          matchedItem
        ];
      } else {
        state.activityCheckedItems = state.activityCheckedItems.filter(
          (item) => {
            return item.engagement_id !== matchedItem.engagement_id;
          }
        );
      }

      // check if any item is checked
      if (state.activityReport.data.some((item) => item.isChecked === true)) {
        state.isAnyActivityItemChecked = true;
      } else {
        state.isAnyActivityItemChecked = false;
      }
    },
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;
    },
    setClearCheckedItem: (state) => {
      state.checkedItems = [];
      state.isAnyItemChecked = true;
      state.assignedCheckedItems = [];
      state.isAnyAssignedItemChecked = true;
    },
    setClearStates: (state) => {
      state.statesDropDown = [];
    },
    setClearCities: (state) => {
      state.citiesDropDown = [];
    },
    setselectedCountry: (state, { payload }) => {
      state.selectedCountry = payload;
    },
    setselectedState: (state, { payload }) => {
      state.selectedState = payload;
    },
    setSelectedCity: (state, { payload }) => {
      state.selectedCity = payload;
    },

    setResource_Data: (state, { payload: { value1, isNode = false } }) => {
      if (value1 !== null) {
        if (isNode) {
          state.ResourceData = { node: value1 };
        } else {
          state.ResourceData = { ...state.ResourceData, value: value1 };
        }
      } else {
        state.ResourceData = null;
      }
    },
    setSelectedActionPlan: (state, { payload: index }) => {
      if (state.ActionPlanDropdown) {
        if (typeof index === "object") {
          state.selectedActionPlan = index;
        } else {
          state.selectedActionPlan = state.ActionPlanDropdown[index];
        }
      }
    },
    setSelectedCoachee: (state, { payload: index }) => {
      if (state.GroupCoacheeDropdown) {
        state.selectedCoachee = state.GroupCoacheeDropdown[index];
      }
    },
    resetSuccessMessage: (state) => {
      state.success = null;
    },
    setReloadData: (state, { payload: confirmStatus }) => {
      state.reloadData = confirmStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersData.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCoacheeFromSponsor.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCoachUsers.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCoacheehUsers.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getProgramManager.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCoachingProgram.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCustomersData.fulfilled, (state, { payload }) => {
      if (payload.data) {
        setStateData(state, payload);
      } else {
        let innerDataArray = [];
        innerDataArray.push(payload);
        let data = {
          currentPage: 1,
          data: innerDataArray,
          pageSize: 5,
          totalItem: 5,
          totalPage: 1
        };
        state.data = data;
      }
    });
    builder.addCase(getSponsoringEntities.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getCoachingPrograms.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getPMByCoachingProgram.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(
      getCoachingProgramsBySponsor.fulfilled,
      (state, { payload }) => {
        setStateData(state, payload);
      }
    );
    builder.addCase(getCoachingProgramByMe.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getActionPlans.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getAuditLogs.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getAllUploadedDocuments.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getAllAssignedDocuments.fulfilled, (state, { payload }) => {
      setAssignedData(state, payload);
    });
    builder.addCase(
      getSystemAdminAssignedDoc.fulfilled,
      (state, { payload }) => {
        setAssignedData(state, payload);
      }
    );
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countriesDropDown = payload;
    });
    builder.addCase(getStateByCountry.fulfilled, (state, { payload }) => {
      const States = [];
      if (payload) {
        payload?.map((item, key) => {
          const stateValues = {
            id: item.iso2,
            text: item.name,
            value: item.name
          };
          States.push(stateValues);
        });
      }
      state.statesDropDown = States;
    });
    builder.addCase(getCityByState.fulfilled, (state, { payload }) => {
      const Cities = [];
      if (payload) {
        payload?.map((item, key) => {
          const cityValues = {
            id: item.iso2,
            text: item.name,
            value: item.name
          };
          Cities.push(cityValues);
        });
        state.citiesDropDown = Cities;
      }
    });
    builder.addCase(getSessionsHistory.pending, (state) => {
      state.historySessionLoading = true;
    });
    builder.addCase(getSessionsHistory.fulfilled, (state, { payload }) => {
      if (payload.isCalender) {
        state.calenderSessionData = payload.response.data.session_list;
        state.historyError = false;
      } else {
        setSessionData(state, payload);
      }
      state.historySessionLoading = false;
    });
    builder.addCase(getSessionsHistory.rejected, (state, { payload }) => {
      state.historySessionLoading = false;
      state.historyError = true;
    });
    builder.addCase(getMyHistorySession.fulfilled, (state, { payload }) => {
      if (payload.isCalender) {
        state.calenderSessionData = payload.response.data.session_list;
        state.historyError = false;
      } else {
        setSessionData(state, payload);
      }
      state.historySessionLoading = false;
    });

    builder.addCase(getEngagements.fulfilled, (state, { payload }) => {
      state.engagementData = payload.data;
    });
    builder.addCase(getEngagementsByType.fulfilled, (state, { payload }) => {
      state.engagementData = payload.data;
    });
    builder
      .addCase(getSessions.pending, (state) => {
        state.isSessionsLoading = true;
      })
      .addCase(getSessions.fulfilled, (state, { payload }) => {
        state.isSessionsLoading = false;
        setSessionData(state, payload);
      })
      .addCase(getSessions.rejected, (state) => {
        state.isSessionsLoading = false;
      });
    builder.addCase(
      getCoachingProgramFilter.fulfilled,
      (state, { payload }) => {
        if (payload.data) {
          payload.data.unshift({
            coaching_program_id: null,
            coaching_program_name: SelectAllItemsText.ALL_COACHING_PROGRAMS
          });
          state.coachingProgram = payload.data;
        }
      }
    );
    builder.addCase(getUpcomingSessions.pending, (state, { payload }) => {
      state.upcomingSessionLoading = true;
    });
    builder.addCase(getMyUpcomingSessions.fulfilled, (state, { payload }) => {
      setSessionData(state, payload);
      state.upcomingSessionLoading = false;
      state.upcomingError = false;
    });
    builder.addCase(getUpcomingSessions.fulfilled, (state, { payload }) => {
      setSessionData(state, payload);
      state.upcomingSessionLoading = false;
      state.upcomingError = false;
    });

    builder.addCase(getUpcomingSessions.rejected, (state, { payload }) => {
      state.upcomingSessionLoading = false;
      state.upcomingError = true;
      setSessionData(state, payload);
    });
    builder.addCase(
      getUpcomingSessionsBySponsor.fulfilled,
      (state, { payload }) => {
        setSessionData(state, payload);
        state.upcomingSessionLoading = false;
        state.upcomingError = false;
      }
    );
    builder.addCase(
      getHistorySessionBySponsor.fulfilled,
      (state, { payload }) => {
        if (payload.isCalender) {
          state.calenderSessionData = payload.response.data.session_list;
          state.historyError = false;
        } else {
          setSessionData(state, payload);
        }
        state.historySessionLoading = false;
      }
    );
    builder.addCase(getAllJournalEnteries.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getMyUpcomingSession.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getMyJournalEnteries.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder
      .addCase(getAllFeedback.pending, (state) => {
        state.isFeedbackLoading = true;
      })
      .addCase(getAllFeedback.fulfilled, (state, { payload }) => {
        state.isFeedbackLoading = false;
        setStateData(state, payload);
      })
      .addCase(getAllFeedback.rejected, (state) => {
        state.isFeedbackLoading = false;
      });
    builder.addCase(getCoacheeProgress.fulfilled, (state, { payload }) => {
      setStateData(state, payload);
    });
    builder.addCase(getActivityReports.fulfilled, (state, { payload }) => {
      setActivityReport(state, payload);
    });
    builder.addCase(
      getActionPlanbyCoachee.fulfilled,
      (state, { payload: { data: payload, actionPlan } }) => {
        if (payload.data) {
          payload.data.unshift({
            action_plan_id: null,
            action_plan_title: `Select ${actionPlan}`
          });
          state.ActionPlanDropdown = payload.data;
          state.selectedActionPlan = state.ActionPlanDropdown[0];
        }
      }
    );
    builder.addCase(
      getCoacheeActionPlansByEngagment.fulfilled,
      (state, { payload: { data: payload, actionPlan } }) => {
        if (payload.data) {
          payload.data.unshift({
            action_plan_id: null,
            action_plan_title: `Select ${actionPlan}`
          });
          state.ActionPlanDropdown = payload.data;
          state.selectedActionPlan = state.ActionPlanDropdown[0];
        }
      }
    );
    // #region COACHEE DASHBOARD - TO DO LIST - GET ALL
    builder.addCase(getTodoList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTodoList.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.data) {
        payload.data.forEach((item) => {
          item.name = item.task_title;
          item.subRows = item.coachee_resources;
          item.subRows.forEach((subRow) => {
            subRow.name = subRow.resource_title;
          });
        });

        state.todolist = payload;
      }
    });
    builder.addCase(getTodoList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message || null;
    });
    // #endregion

    // #region COACHEE DASHBOARD - TO DO LIST - MARK COMPLETED
    builder.addCase(markCompleted.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(markCompleted.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.success = "Saved Successfully";
      }
    });
    builder.addCase(markCompleted.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message || null;
      console.log(error);
    });
    // #region COACH PORTAL - COACHEE PROGRESS - MARK OBJECTIVE GOALS DONE
    builder.addCase(mark_obj_goal_completed.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(mark_obj_goal_completed.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.success = "Saved Successfully";
      }
    });
    builder.addCase(mark_obj_goal_completed.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message || null;
      console.log(error);
    });
    //# filter for coachee for coachee progress
    builder.addCase(getCoacheehUsersForFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCoacheehUsersForFilter.fulfilled,
      (state, { payload: { data: payload, coachee } }) => {
        state.loading = false;

        if (payload) {
          payload.unshift({
            user_id: null,
            user_name: `Select ${coachee}`
          });
        } else {
          payload.unshift({
            user_id: null,
            user_name: `No ${coachee} Found`
          });
        }
        state.GroupCoacheeDropdown = payload;
        state.selectedCoachee = state.GroupCoacheeDropdown[0];
      }
    );
    builder.addCase(getCoacheehUsersForFilter.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCoacheeActionPlanByGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCoacheeActionPlanByGroup.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        setStateData(state, payload);
      }
    );
    builder.addCase(getCoacheeActionPlanByGroup.rejected, (state) => {
      state.loading = false;
    });
    // #endregion
  }
});

const setStateData = (state, payload) => {
  if (payload.data) {
    // this step is taken to add another property 'isChecked' to the data table
    payload.data.map((item) => {
      item.isChecked = false;
      return item;
    });

    state.allItemsChecked = false;
    state.isAnyItemChecked = false;
    state.data = payload;
  } else {
    state.data = [];
  }
  return state;
};
const setAssignedData = (state, payload) => {
  if (payload.data) {
    payload.data.map((item) => {
      item.isChecked = false;
      return item;
    });
    state.allAssignedItemsChecked = false;
    state.isAnyAssignedItemChecked = false;
    state.assignDocuments = payload;
  } else {
    state.assignDocuments = [];
  }
  return state;
};
const setActivityReport = (state, payload) => {
  if (payload.data) {
    payload.data.map((item) => {
      item.isChecked = false;
      return item;
    });
    state.allItemsChecked = false;
    state.isAnyItemChecked = false;
    state.activityReport = payload;
  } else {
    state.data = [];
  }
  return state;
};

const setSessionData = (state, payload) => {
  if (payload === undefined) {
    state.data = {
      currentPage: 1,
      data: [],
      pageSize: 10,
      totalItem: 5,
      totalPage: 1
    };
  } else if (payload?.data) {
    payload.data.session_list.map((item) => {
      item.isChecked = false;
      return item;
    });
    state.allItemsChecked = false;
    state.isAnyItemChecked = false;
    state.data = payload;
    state.metaData.totalSession = payload.data.total_sessions;
    state.metaData.sessionDuration = `${Math.floor(
      payload.data.total_duration_hours
    )} hrs ${Math.floor(payload.data.total_duration_minutes)} mins`;
    state.metaData.noShowSession = payload.data.total_no_show_sessions || 0;
    state.data.data = payload.data.session_list;
  } else {
    state.data = [];
  }
};
// thunks are used to making asynchronous api calls
export const getUsersData = createAsyncThunk(
  "dataTable/getUsersByRole",
  async ({
    userType,
    currentPage = 1,
    pageSize = 10,
    customer_id = null,
    searchQuery
  }) => {
    const response = await dashboard.getUsersByRole(
      userType,
      currentPage,
      pageSize,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getCoacheeFromSponsor = createAsyncThunk(
  "dataTable/getCoacheeFromSponsor",
  async ({ currentPage = 1, pageSize = 5, searchQuery }) => {
    const response = await dashboard.getCoacheeFromSponsor(
      currentPage,
      pageSize,
      searchQuery
    );
    return response;
  }
);
export const getCoachUsers = createAsyncThunk(
  "dataTable/getCoachUsers",
  async () => {
    const response = await manageDocumentConsole.getCoachUser();
    return response;
  }
);
export const getCoacheehUsers = createAsyncThunk(
  "dataTable/getCoacheehUsers",
  async () => {
    const response = await manageDocumentConsole.getCoacheeUser();
    return response;
  }
);
export const getProgramManager = createAsyncThunk(
  "dataTable/getProgramManager",
  async () => {
    const response = await manageDocumentConsole.getProgramManager();
    return response;
  }
);
export const getCoachingProgram = createAsyncThunk(
  "dataTable/getCoachingProgram",
  async () => {
    const response = await manageDocumentConsole.getCoachingProgram();
    return response;
  }
);
export const getCustomersData = createAsyncThunk(
  "dataTable/getCustomersData",
  async ({
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    searchQuery
  }) => {
    const response = await dashboard.getCustomers(
      currentPage,
      pageSize,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getSponsoringEntities = createAsyncThunk(
  "dataTable/getSponsoringEntities",
  async (currentPage = 1, pageSize = 5, customer_id = null) => {
    const response = await dashboard.getSponsoringEntities(
      currentPage,
      pageSize,
      customer_id
    );
    return response;
  }
);
export const getCoachingPrograms = createAsyncThunk(
  "dataTable/getCoachingPrograms",
  async ({
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    searchQuery
  }) => {
    const response = await dashboard.getCoachingPrograms(
      currentPage,
      pageSize,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getCoachingProgramsBySponsor = createAsyncThunk(
  "dataTable/getCoachingProgramsBySponsor",
  async ({ currentPage = 1, pageSize = 5, searchQuery }) => {
    const response = await dashboard.getCoachingProgramBySponsor(
      currentPage,
      pageSize,
      searchQuery
    );
    return response.data;
  }
);
export const getCoachingProgramByMe = createAsyncThunk(
  "datatable/getCoachingProgramByMe",
  async ({ current_page = 1, page_size = 5, searchQuery }) => {
    const response = await coachingProgramByMe.getCoachingProgramByMe(
      current_page,
      page_size,
      searchQuery
    );
    return response;
  }
);
export const getActionPlans = createAsyncThunk(
  "dataTable/getActionPlans",
  async ({
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    searchQuery
  }) => {
    const response = await actionPlanTemplateAPIs.getActionPlans(
      currentPage,
      pageSize,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getCountries = createAsyncThunk(
  "dataTable/getCountries",
  async (payload, { getState }) => {
    if (getState().dataTable.countriesDropDown.length > 0) {
      return getState().dataTable.countriesDropDown;
    }
    var config = {
      ...genericConfigs,
      url: "https://api.countrystatecity.in/v1/countries"
    };
    const response = await axios(config);
    const Countries = [];
    if (response.data) {
      response?.data.map((item, key) => {
        const countriesValues = {
          id: item.iso2,
          text: item.name,
          value: item.name
        };
        Countries.push(countriesValues);
      });
    }
    return Countries;
  }
);
export const getCityByState = createAsyncThunk(
  "dataTable/getCityByState",
  async ({ country, state }) => {
    var config = {
      ...genericConfigs,
      url: `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`
    };
    const response = await axios(config);
    return response.data;
  }
);
export const getStateByCountry = createAsyncThunk(
  "dataTable/getStateByCountry",
  async (country) => {
    var config = {
      ...genericConfigs,
      url: `https://api.countrystatecity.in/v1/countries/${country}/states`
    };
    const response = await axios(config);
    return response.data;
  }
);
export const getSessionsHistory = createAsyncThunk(
  "dataTable/getSessionsHistory",
  async ({
    current_page,
    page_size,
    customer_id = null,
    coaching_program_id = null,
    isCalender = false,
    search
  }) => {
    const response = await dashboard.getSessionsHistory(
      current_page,
      page_size,
      customer_id,
      coaching_program_id,
      search
    );
    if (isCalender) return { response, isCalender };
    return response;
  }
);

export const getMyHistorySession = createAsyncThunk(
  "dataTable/getMyHistorySession",
  async ({
    current_page,
    page_size,
    isCalender = false,
    search,
    coaching_program_id = null
  }) => {
    const response = await dashboard.getMyHistorySession(
      current_page,
      page_size,
      search,
      coaching_program_id
    );
    if (isCalender) return { response, isCalender };
    return response;
  }
);

export const getEngagements = createAsyncThunk(
  "dataTable/getEngagements",
  async ({ current_page = 1, page_size = 5, customer_id = null }) => {
    const response = await dashboard.getEngagements(
      current_page,
      page_size,
      customer_id
    );
    return response;
  }
);

export const getEngagementsByType = createAsyncThunk(
  "dataTable/getEngagementsByType",
  async ({ type, customer_id }) => {
    const response = await engagementConsoleAPIs.getEngagementByType({
      customer_id,
      type
    });
    return response;
  }
);

export const getSessions = createAsyncThunk(
  "dataTable/getSessions",
  async ({
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    engagement_id = null,
    search
  }) => {
    const response = await dashboard.getSessions(
      currentPage,
      pageSize,
      customer_id,
      engagement_id,
      search
    );
    return response;
  }
);
export const getUpcomingSessions = createAsyncThunk(
  "dataTable/getUpcomingSessions",
  async ({
    current_page,
    page_size,
    customer_id = null,
    engagement_id = null,
    coaching_program_id = null,
    search
  }) => {
    const response = await dashboard.getUpcomingSessions({
      current_page,
      page_size,
      customer_id,
      engagement_id,
      search,
      coaching_program_id
    });
    return response.data;
  }
);
export const getMyUpcomingSessions = createAsyncThunk(
  "dataTable/getMyUpcomingSessions",
  async ({ current_page, page_size, search, coaching_program_id = null }) => {
    const response = await dashboard.getMyUpcomingSessions(
      current_page,
      page_size,
      search,
      coaching_program_id
    );
    return response;
  }
);
export const getUpcomingSessionsBySponsor = createAsyncThunk(
  "dataTable/getUpcomingSessionsBySponsor",
  async ({
    current_page,
    page_size,
    coaching_id,
    coaching_program_id = null,
    search
  }) => {
    const response = await dashboard.getUpcomingSessionsBySponsor({
      current_page,
      page_size,
      coaching_id: coaching_id || coaching_program_id,
      search
    });
    return response;
  }
);
export const getHistorySessionBySponsor = createAsyncThunk(
  "dataTable/getHistorySessionBySponsor",
  async ({
    current_page,
    page_size,
    coaching_id,
    coaching_program_id = null,
    isCalender = false,
    search
  }) => {
    const response = await dashboard.getHistorySessionBySponsor({
      current_page,
      page_size,
      coaching_id: coaching_id || coaching_program_id,
      search
    });
    if (isCalender) return { response, isCalender };
    return response;
  }
);
//Get Access Audit Log
export const getAuditLogs = createAsyncThunk(
  "dataTable/getAuditLogs",
  async ({
    current_page = 1,
    page_size = 5,
    customer_id,
    user_name,
    from_date,
    to_date,
    action_type
  }) => {
    const response = await accessAuditLog.getAllAuditLogs(
      customer_id,
      user_name,
      from_date,
      to_date,
      action_type,
      page_size,
      current_page
    );
    return response.data;
  }
);
export const getCoachingProgramFilter = createAsyncThunk(
  "dataTable/getCoachingProgramFilter",
  async () => {
    const response = await dashboard.getCoachingPrograms();
    return response;
  }
);

// Get Journal Enteries Against specific session
export const getAllJournalEnteries = createAsyncThunk(
  "dataTable/getAllJournalEnteries",
  async ({ current_page, page_size, session_id }) => {
    const response = await dashboard.getAllJournalEnteries({
      current_page,
      page_size,
      session_id
    });
    return response.data;
  }
);
export const getMyJournalEnteries = createAsyncThunk(
  "dataTable/getMyJournalEnteries",
  async ({ current_page, page_size, session_id }) => {
    const response = await dashboard.getMyJournalEnteries({
      current_page,
      page_size,
      session_id
    });
    return response.data;
  }
);

// Get All Feedback session against specific session
export const getAllFeedback = createAsyncThunk(
  "dataTable/getAllFeedback",
  async ({ session_id, pageSize, currentPage = null }) => {
    const response = await dashboard.getAllFeedback({
      session_id,
      pageSize,
      currentPage
    });
    return response.data;
  }
);
//Get Manage Document
export const getAllUploadedDocuments = createAsyncThunk(
  "datatable/getAllUploadedDocuments",
  async ({ current_page = 1, page_size = 5, search, customer_id }) => {
    const response = await manageDocumentConsole.getAllUploadedDocument(
      current_page,
      page_size,
      search,
      customer_id
    );
    return response.data;
  }
);
export const getAllAssignedDocuments = createAsyncThunk(
  "datatable/getAllAssignedDocuments",
  async ({ current_page = 1, page_size = 5, search, customer_id }) => {
    const response = await manageDocumentConsole.getAssignedDocuments(
      current_page,
      page_size,
      search,
      customer_id
    );
    return response.data;
  }
);
export const getSystemAdminAssignedDoc = createAsyncThunk(
  "datatable/getSystemAdminAssignedDoc",
  async ({ current_page = 1, page_size = 5, search, customer_id }) => {
    const response = await manageDocumentConsole.getSystemAdminAssignedDoc(
      current_page,
      page_size,
      search,
      customer_id
    );
    return response.data;
  }
);
export const getMyUpcomingSession = createAsyncThunk(
  "datatable/getMyUpcomingSession",
  async ({
    current_page = 1,
    page_size = 5,
    search,
    coaching_program_id = null
  }) => {
    const response = await getCoacheeSession.getMyUpcomingSessions(
      current_page,
      page_size,
      search,
      coaching_program_id
    );
    return response.data;
  }
);
export const getActionPlanbyCoachee = createAsyncThunk(
  "datatable/getActionPlanbyCoachee",
  async ({ current_page = -1, page_size = 5 }, { getState }) => {
    let actionPlan = getState().keywords.keywords.action_plan;
    const response = await coacheeActionPlan.getActionPlan(
      current_page,
      page_size
    );
    return { data: response.data, actionPlan };
  }
);
export const getCoacheeActionPlansByEngagment = createAsyncThunk(
  "datatable/getCoacheeActionPlansByEngagment",
  async ({ current_page = -1, page_size = 5, id }, { getState }) => {
    let actionPlan = getState().keywords.keywords.action_plan;
    const response =
      await getCoacheeActionPlansbyEngagment.getActionPlansbyEngagment(
        current_page,
        page_size,
        id
      );
    return { data: response.data, actionPlan };
  }
);
export const getCoacheeProgress = createAsyncThunk(
  "datatable/getCoacheeProgress",
  async ({ currentPage, pageSize, coaching_program_id = null }) => {
    const response = await coacheeProgress.getMyProgress({
      currentPage,
      pageSize,
      coaching_program_id
    });
    return response;
  }
);
export const getActivityReports = createAsyncThunk(
  "dataTable/getActivityReports",
  async ({
    engagement_id = null,
    coaching_program_id,
    coachee_id,
    coach_id,
    start_date,
    end_date,
    current_page,
    page_size,
    searchQuery
  }) => {
    const response = await activityReport.getActivityReports({
      engagement_id,
      coaching_program_id,
      coachee_id,
      coach_id,
      start_date,
      end_date,
      current_page,
      page_size,
      searchQuery
    });
    return response.data;
  }
);
export const getTodoList = createAsyncThunk(
  "dataTable/getTodoList",
  async ({ current_page, page_size, coachee_id = null }) => {
    const response = await coacheeDashboardAPIs.todoList.getAll({
      current_page,
      page_size,
      coachee_id
    });

    return response.data;
  }
);
export const markCompleted = createAsyncThunk(
  "dataTable/markCompleted",
  async ({ body }) => {
    const response = await coacheeDashboardAPIs.todoList.markCompleted({
      body
    });

    return response.data;
  }
);
export const mark_obj_goal_completed = createAsyncThunk(
  "dataTable/mark_obj_goal_completed",
  async ({ body }) => {
    const response =
      await coacheeDashboardAPIs.todoList.mark_obj_goal_completed({
        body
      });
    return response.data;
  }
);
export const getCoacheehUsersForFilter = createAsyncThunk(
  "dataTable/getCoacheehUsersForFilter",
  async ({ group_id }, { getState }) => {
    let coachee = getState().keywords.keywords.coachee;
    const response = await dashboard.getGroupById(group_id);
    let data = response?.data?.coachees;
    return { data, coachee };
  }
);
export const getCoacheeActionPlanByGroup = createAsyncThunk(
  "dataTable/getCoacheeActionPlanByGroup",
  async ({ engagement_id }) => {
    const response = await engagementConsoleAPIs.getCoacheeActionPlanByGroup({
      engagement_id
    });
    return response;
  }
);
export const getPMByCoachingProgram = createAsyncThunk(
  "dataTable/getPMByCoachingProgram",
  async ({ currentPage = 1, pageSize = 10, searchQuery }) => {
    const response = await dashboard.getPMByCoachingProgram({
      currentPage,
      pageSize,
      searchQuery
    });
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setAllItemsChecked,
  setAllAssignItemChecked,
  setAllActivityItemChecked,
  setItemChecked,
  setAssignItemChecked,
  setActivityItemChecked,
  setClearCheckedItem,
  setSearchQuery,
  setClearStates,
  setClearCities,
  setselectedCountry,
  setselectedState,
  setSelectedCity,
  setHookSnack,
  resetDataTable,
  resetApiData,
  setSessionType,
  resetEngagementData,
  setSelectedDatePicker,
  setSelectedActionPlan,
  setSelectedCoachee,
  resetSuccessMessage,
  setReloadData,
  setResource_Data
} = dataTableSlice.actions;

export default dataTableSlice.reducer;
