import { lazy } from "react";
import { dashboard } from "./apiMethods";
import {
  systemAdmin,
  customerAdmin,
  programManager,
  userConsole,
  manageGroups,
  customerOrganizations,
  sponsor,
  sponsoringEntity,
  historySessions,
  upcomingSessions,
  sessions,
  manageDocument,
  accessAuditLog,
  calenderSession,
  manageProfile,
  changePassword,
  activityReports,
  userDashboard,
  videoSessionPermission,
  keywordSubstitution,
  sessionFeedbackPermission,
  rolePermissions,
  permissionsType,
  all_roles
} from "./constants";
import { SideNavIcon } from "components/custom-components/IconifyIcon";
import useKeywords from "custom-hooks/useKeywords";
import systemAdminForm from "layouts/dashboard/user-console/manage-system-admin/schemas/form";
import systemAdminValidations from "layouts/dashboard/user-console/manage-system-admin/schemas/validations";
import systemAdminInitialValues from "layouts/dashboard/user-console/manage-system-admin/schemas/initialValues";
import manageGroupForm from "layouts/dashboard/user-console/manage-groups/schemas/form";
import manageGroupValidations from "layouts/dashboard/user-console/manage-groups/schemas/validation";
import manageGroupInitialValues from "layouts/dashboard/user-console/manage-groups/schemas/initialValues";
import customerAdminValidations from "layouts/dashboard/user-console/manage-customer-admin/schemas/validations";
import customerAdminInitialValues from "layouts/dashboard/user-console/manage-customer-admin/schemas/initialValues";
import customerAdminForm from "layouts/dashboard/user-console/manage-customer-admin/schemas/form";
import programManagerValidations from "layouts/dashboard/user-console/manage-program-manager/schemas/validations";
import programManagerInitialValues from "layouts/dashboard/user-console/manage-program-manager/schemas/initialValues";
import coachesValidations from "layouts/dashboard/user-console/manage-coaches/schemas/validations";
import coachesInitialValues from "layouts/dashboard/user-console/manage-coaches/schemas/initialValues";
import coacheesValidations from "layouts/dashboard/user-console/manage-coachees/schemas/validations";
import coacheesInitialValues from "layouts/dashboard/user-console/manage-coachees/schemas/initialValues";
import customerForm from "layouts/dashboard/user-console/manage-customer-organization/schemas/form";
import customerValidations from "layouts/dashboard/user-console/manage-customer-organization/schemas/validations";
import customerInitialValues from "layouts/dashboard/user-console/manage-customer-organization/schemas/initialValues";
import sponsorsValidations from "layouts/dashboard/user-console/manage-sponsor/schemas/validations";
import sponsorsInitialValues from "layouts/dashboard/user-console/manage-sponsor/schemas/initialValues";
import sponsoringEntityForm from "layouts/dashboard/user-console/manage-sponsoring-entity/schemas/form";
import sponsoringEntityValidations from "layouts/dashboard/user-console/manage-sponsoring-entity/schemas/validations";
import sponsoringEntityInitialValues from "layouts/dashboard/user-console/manage-sponsoring-entity/schemas/initialValues";
import coachingProgramForm from "layouts/dashboard/coaching-program-console/schemas/form";
import coachingProgramValidations from "layouts/dashboard/coaching-program-console/schemas/validations";
import coachingProgramInitialValues from "layouts/dashboard/coaching-program-console/schemas/initialValues";
import sessionForm from "layouts/dashboard/view-sessions/upcoming-sessions/schema/form";
import sessionInitialValues from "layouts/dashboard/view-sessions/upcoming-sessions/schema/initialValues";
import sessionValidations from "layouts/dashboard/view-sessions/upcoming-sessions/schema/validations";
import journalEnteryForm from "layouts/dashboard/view-sessions/upcoming-sessions/schema/journalEnteryForm";

import journalEntryInitialValues from "layouts/dashboard/view-sessions/upcoming-sessions/schema/journalEntryInitialValues";

import journalEntryValidations from "layouts/dashboard/view-sessions/upcoming-sessions/schema/journalEntryValidations";
import coachForm from "layouts/dashboard/user-console/manage-coaches/schemas/form";
import programManagerForm from "layouts/dashboard/user-console/manage-program-manager/schemas/form";
import sponsorForm from "layouts/dashboard/user-console/manage-sponsor/schemas/form";
import coacheesForm from "layouts/dashboard/user-console/manage-coachees/schemas/form";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { useParams } from "react-router-dom";
const ManageSystemAdmin = lazy(() =>
  import("layouts/dashboard/user-console/manage-system-admin")
);
const ManageGroup = lazy(() =>
  import("layouts/dashboard/user-console/manage-groups")
);

const ManageCustomerAdmin = lazy(() =>
  import("layouts/dashboard/user-console/manage-customer-admin")
);
const ManageProgramManagers = lazy(() =>
  import("layouts/dashboard/user-console/manage-program-manager")
);

const MyActionPlan = lazy(() =>
  import("layouts/coachee-dashboard/my-action-plan")
);

const ManageCoaches = lazy(() =>
  import("layouts/dashboard/user-console/manage-coaches")
);

const ManageCoachees = lazy(() =>
  import("layouts/dashboard/user-console/manage-coachees")
);

const ManageCustomerOrganizations = lazy(() =>
  import("layouts/dashboard/user-console/manage-customer-organization")
);
const ManageSponsors = lazy(() =>
  import("layouts/dashboard/user-console/manage-sponsor")
);
const ManageSponsoringEntities = lazy(() =>
  import("layouts/dashboard/user-console/manage-sponsoring-entity")
);
const CoachingProgramsConsole = lazy(() =>
  import("layouts/dashboard/coaching-program-console")
);
const KeywordsSubstitution = lazy(() =>
  import("layouts/dashboard/admin-controls/keywords-substitution")
);
const ManageProfile = lazy(() => import("layouts/profile/manage-profile"));
const ChangePassword = lazy(() => import("layouts/profile/change-password"));
const VideoSession = lazy(() =>
  import("layouts/dashboard/admin-controls/video-session")
);
const RolePermission = lazy(() =>
  import("layouts/dashboard/admin-controls/role-permission-customization")
);
const HistorySession = lazy(() =>
  import("layouts/dashboard/view-sessions/view-history-session")
);
const UpcomingSessions = lazy(() =>
  import("layouts/dashboard/view-sessions/upcoming-sessions")
);
const EngagementConsole = lazy(() =>
  import("layouts/dashboard/engagement-console")
);
const SessionFeedback = lazy(() =>
  import("layouts/dashboard/admin-controls/session-feedback")
);
const ViewSessionList = lazy(() =>
  import("layouts/dashboard/engagement-console/pages/view-session-list")
);
const ActivityReport = lazy(() => import("layouts/dashboard/activity-report"));
const ViewSessionFeedback = lazy(() =>
  import(
    "layouts/dashboard/view-sessions/view-history-session/pages/view-session-feedback"
  )
);
const CreateEngagements = lazy(() =>
  import("layouts/dashboard/engagement-console/pages/create-engagements")
);
const EditEngagement = lazy(() =>
  import("layouts/dashboard/engagement-console/pages/edit-engagement")
);
const ViewReport = lazy(() =>
  import("layouts/dashboard/engagement-console/pages/view-report")
);
const CreateActionPlan = lazy(() =>
  import("layouts/dashboard/action-plan-template/pages/create")
);
const ActionPlanTemplate = lazy(() =>
  import("layouts/dashboard/action-plan-template")
);
const ViewActionPlan = lazy(() =>
  import("layouts/dashboard/action-plan-template/pages/view-action-plan")
);
const ManageDocument = lazy(() => import("layouts/dashboard/manage-document"));
const AddDocument = lazy(() =>
  import("layouts/dashboard/manage-document/pages/add-document")
);
const EditDocument = lazy(() =>
  import("layouts/dashboard/manage-document/pages/edit-document")
);
const AssignDocumet = lazy(() =>
  import("layouts/dashboard/manage-document/pages/assign-document")
);

const AccessAuditLog = lazy(() => import("layouts/dashboard/access-audit-log"));
const CalenderSession = lazy(() =>
  import("layouts/dashboard/view-sessions/calender-sessions")
);
const CreatePage = lazy(() =>
  import("layouts/dashboard/components/FormPages/create-page")
);

const EditPage = lazy(() =>
  import("layouts/dashboard/components/FormPages/edit-page")
);
const ViewPage = lazy(() =>
  import("layouts/dashboard/components/FormPages/view-page")
);

const ViewJournalGrid = lazy(() =>
  import(
    "layouts/dashboard/view-sessions/upcoming-sessions/pages/view-journal-grid"
  )
);
const EditExistingPlan = lazy(() =>
  import("layouts/dashboard/action-plan-template/pages/edit-existing-plan")
);
const MainDashboard = lazy(() => import("layouts/dashboard/main-dashboard"));
const CoacheeProgressReport = lazy(() =>
  import(
    "layouts/dashboard/user-console/manage-groups/pages/coachee-progress-report"
  )
);
const ViewIndiviualProgress = lazy(() =>
  import("layouts/dashboard/engagement-console/pages/view-indiviual-progress")
);
const AddHtmlText = lazy(() =>
  import("layouts/dashboard/action-plan-template/pages/add-html-text")
);
const InteractiveToolBuilder = lazy(() =>
  import(
    "layouts/dashboard/action-plan-template/pages/interactive-tool-builder"
  )
);
const CoacheeInteractiveToolBuilder = lazy(() =>
  import("layouts/coachee-dashboard/coachee-interactive-tool")
);

const useSideNavRoutes = () => {
  const {
    coaches,
    ActionPlanTemplateConstants,
    coachees,
    coachingPrograms,
    engagementConsole
  } = useKeywords();
  return [
    // ?? ADMIN DASHBOARD SECTION
    {
      type: "collapse",
      name: userDashboard.userType,
      key: userDashboard.route,
      route: `/${userDashboard.route}`,
      component: <MainDashboard />,
      icon: <SideNavIcon icon="akar-icons:dashboard" />,
      noCollapse: true,
      permissionName: "default"
    },
    // ?? ADMIN CONTROLS SECTION
    {
      type: "collapse",
      name: "Admin Controls",
      key: "admin-controls",
      icon: <SideNavIcon icon="ant-design:control-outlined" />,
      collapse: [
        {
          name: "Keyword Substitutions",
          key: "keyword-substitutions",
          route: "/admin-controls/keyword-substitutions",
          permissionName: keywordSubstitution.permissionName,
          component: <KeywordsSubstitution />
        },
        {
          name: "Video Sessions Controls",
          key: "video-sessions",
          route: "/admin-controls/video-sessions",
          permissionName: videoSessionPermission.permissionName,
          component: <VideoSession />
        },
        {
          name: "Session Feedback Controls",
          key: "session-feedback-control",
          route: "/admin-controls/session-feedback",
          component: <SessionFeedback />,
          permissionName: sessionFeedbackPermission.permissionName
        },
        {
          name: rolePermissions.name,
          key: "role-permission-customization",
          route: "/admin-controls/role-permission-customization",
          component: <RolePermission />,
          permissionName: rolePermissions.permissionName
        }
      ]
    },

    // ?? USER CONSOLE SECTION
    {
      type: "collapse",
      name: "Users",
      key: userConsole.route,
      icon: <SideNavIcon icon="la:users" />,
      collapse: [
        // ?? SYSTEM ADMIN SECTION
        {
          name: systemAdmin.routeTitle,
          permissionName: systemAdmin.permissionName,
          key: systemAdmin.route,
          route: `${userConsole.route}/${systemAdmin.route}`,
          component: <ManageSystemAdmin />,
          children: [
            {
              name: systemAdmin.addPageTitle,
              key: systemAdmin.addPageRoute,
              type: permissionsType.create,
              route: `${userConsole.route}/${systemAdmin.route}/${systemAdmin.addPageRoute}`,
              component: (
                <CreatePage
                  title={systemAdmin.pageTitle}
                  subTitle={systemAdmin.addPageTitle}
                  parentRoute={`/${userConsole.route}/${systemAdmin.route}`}
                  form={systemAdminForm}
                  validations={systemAdminValidations}
                  initialValues={systemAdminInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={systemAdmin.userType}
                />
              )
            },
            {
              name: systemAdmin.editPageTitle,
              key: systemAdmin.editPageRoute,
              route: `${userConsole.route}/${systemAdmin.route}/${systemAdmin.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={systemAdmin.pageTitle}
                  subTitle={systemAdmin.editPageTitle}
                  parentRoute={`/${userConsole.route}/${systemAdmin.route}`}
                  form={systemAdminForm}
                  validations={systemAdminValidations}
                  initialValues={systemAdminInitialValues}
                  apiMethod={dashboard.updateUser}
                  pageType={systemAdmin.userType}
                />
              )
            }
          ]
        },

        // ?? CUSTOMER ORGANIZATION SECTION
        {
          name: customerOrganizations.userType,
          key: customerOrganizations.route,
          route: `${userConsole.route}/${customerOrganizations.route}`,
          component: <ManageCustomerOrganizations />,
          permissionName: customerOrganizations.permissionName,
          children: [
            {
              name: customerOrganizations.addPageTitle,
              key: customerOrganizations.addPageRoute,
              route: `${userConsole.route}/${customerOrganizations.route}/${customerOrganizations.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={customerOrganizations.pageTitle}
                  subTitle={customerOrganizations.addPageTitle}
                  parentRoute={`/${userConsole.route}/${customerOrganizations.route}`}
                  form={customerForm}
                  validations={customerValidations}
                  initialValues={customerInitialValues}
                  apiMethod={dashboard.createCustomer}
                  pageType={customerOrganizations.userType}
                />
              )
            },
            {
              name: customerOrganizations.editPageTitle,
              key: customerOrganizations.editPageRoute,
              route: `${userConsole.route}/${customerOrganizations.route}/${customerOrganizations.editPageRoute}/:customer_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={customerOrganizations.pageTitle}
                  subTitle={customerOrganizations.editPageTitle}
                  parentRoute={`/${userConsole.route}/${customerOrganizations.route}`}
                  form={customerForm}
                  validations={customerValidations}
                  initialValues={customerInitialValues}
                  apiMethod={dashboard.updateCustomer}
                  pageType={customerOrganizations.userType}
                />
              )
            },
            {
              name: customerOrganizations.viewPageTitle,
              key: customerOrganizations.viewPageRoute,
              route: `${userConsole.route}/${customerOrganizations.route}/${customerOrganizations.viewPageRoute}/:customer_id`,
              type: permissionsType.view,
              component: (
                <ViewPage
                  title={customerOrganizations.pageTitle}
                  subTitle={customerOrganizations.viewPageTitle}
                  parentRoute={`/${userConsole.route}/${customerOrganizations.route}`}
                  initialValues={customerInitialValues}
                  form={customerForm}
                  getApiMethod={dashboard.getCustomerById}
                  pageType={customerOrganizations.userType}
                />
              )
            }
          ]
        },

        // ?? CUSTOMER ADMIN SECTION
        {
          name: customerAdmin.routeTitle,
          key: customerAdmin.route,
          route: `${userConsole.route}/${customerAdmin.route}`,
          component: <ManageCustomerAdmin />,
          permissionName: customerAdmin.permissionName,
          children: [
            {
              name: customerAdmin.addPageTitle,
              key: customerAdmin.addPageRoute,
              route: `${userConsole.route}/${customerAdmin.route}/${customerAdmin.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={customerAdmin.pageTitle}
                  subTitle={customerAdmin.addPageTitle}
                  parentRoute={`/${userConsole.route}/${customerAdmin.route}`}
                  form={customerAdminForm}
                  validations={customerAdminValidations}
                  initialValues={customerAdminInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={customerAdmin.userType}
                />
              )
            },
            {
              name: customerAdmin.editPageTitle,
              key: customerAdmin.editPageRoute,
              route: `${userConsole.route}/${customerAdmin.route}/${customerAdmin.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={customerAdmin.pageTitle}
                  subTitle={customerAdmin.editPageTitle}
                  parentRoute={`/${userConsole.route}/${customerAdmin.route}`}
                  form={customerAdminForm}
                  validations={customerAdminValidations}
                  apiMethod={dashboard.updateUser}
                  pageType={customerAdmin.userType}
                  initialValues={customerAdminInitialValues}
                />
              )
            }
          ]
        },
        // ?? PROGRAM MANAGER SECTION
        {
          name: programManager.userType,
          key: programManager.route,
          route: `${userConsole.route}/${programManager.route}`,
          component: <ManageProgramManagers />,
          permissionName: programManager.permissionName,
          children: [
            {
              name: programManager.addPageTitle,
              key: programManager.addPageRoute,
              route: `${userConsole.route}/${programManager.route}/${programManager.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={programManager.pageTitle}
                  subTitle={programManager.addPageTitle}
                  parentRoute={`/${userConsole.route}/${programManager.route}`}
                  form={programManagerForm}
                  validations={programManagerValidations}
                  initialValues={programManagerInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={programManager.userType}
                />
              )
            },
            {
              name: programManager.editPageTitle,
              key: programManager.editPageRoute,
              route: `${userConsole.route}/${programManager.route}/${programManager.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={programManager.pageTitle}
                  subTitle={programManager.editPageTitle}
                  parentRoute={`/${userConsole.route}/${programManager.route}`}
                  form={programManagerForm}
                  validations={programManagerValidations}
                  apiMethod={dashboard.updateUser}
                  pageType={programManager.userType}
                  initialValues={programManagerInitialValues}
                />
              )
            }
          ]
        },

        // ?? MANAGE COACHES SECTION
        {
          name: coaches.sideNavTitle,
          key: coaches.route,
          route: `${userConsole.route}/${coaches.route}`,
          component: <ManageCoaches />,
          permissionName: coaches.permissionName,
          children: [
            {
              name: coaches.addPageTitle,
              key: coaches.addPageRoute,
              route: `${userConsole.route}/${coaches.route}/${coaches.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={coaches.pageTitle}
                  subTitle={coaches.addPageTitle}
                  parentRoute={`/${userConsole.route}/${coaches.route}`}
                  form={coachForm}
                  validations={coachesValidations}
                  initialValues={coachesInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={coaches.userType}
                />
              )
            },
            {
              name: coaches.editPageTitle,
              key: coaches.editPageRoute,
              route: `${userConsole.route}/${coaches.route}/${coaches.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={coaches.pageTitle}
                  subTitle={coaches.editPageTitle}
                  parentRoute={`/${userConsole.route}/${coaches.route}`}
                  form={coachForm}
                  validations={coachesValidations}
                  apiMethod={dashboard.updateUser}
                  pageType={coaches.userType}
                  initialValues={coachesInitialValues}
                />
              )
            }
          ]
        },

        // ?? MANAGE COACHEES SECTION
        {
          name: coachees.sideNavTitle,
          key: coachees.route,
          route: `${userConsole.route}/${coachees.route}`,
          component: <ManageCoachees />,
          permissionName: coachees.permissionName,
          children: [
            {
              name: coachees.addPageTitle,
              key: coachees.addPageRoute,
              route: `${userConsole.route}/${coachees.route}/${coachees.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={coachees.pageTitle}
                  subTitle={coachees.addPageTitle}
                  parentRoute={`/${userConsole.route}/${coachees.route}`}
                  form={coacheesForm}
                  validations={coacheesValidations}
                  initialValues={coacheesInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={coachees.userType}
                />
              )
            },
            {
              name: coachees.editPageTitle,
              key: coachees.editPageRoute,
              route: `${userConsole.route}/${coachees.route}/${coachees.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={coachees.pageTitle}
                  subTitle={coachees.editPageTitle}
                  parentRoute={`/${userConsole.route}/${coachees.route}`}
                  form={coacheesForm}
                  validations={coacheesValidations}
                  apiMethod={dashboard.updateUser}
                  pageType={coachees.userType}
                  initialValues={coacheesInitialValues}
                />
              )
            }
          ]
        },

        // ?? MANAGE GROUPS SECTION
        {
          name: "Groups",
          key: "manage-groups",
          route: `${userConsole.route}/manage-groups`,
          component: <ManageGroup />,
          permissionName: manageGroups.permissionName,
          children: [
            {
              name: manageGroups.addPageTitle,
              key: manageGroups.addPageRoute,
              route: `${userConsole.route}/${manageGroups.route}/${manageGroups.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={manageGroups.pageTitle}
                  subTitle={manageGroups.addPageTitle}
                  parentRoute={`/${userConsole.route}/${manageGroups.route}`}
                  form={manageGroupForm}
                  validations={manageGroupValidations}
                  initialValues={manageGroupInitialValues}
                  apiMethod={dashboard.createNewGroup}
                  pageType={manageGroups.userType}
                />
              )
            },
            {
              name: manageGroups.editPageTitle,
              key: manageGroups.editPageRoute,
              route: `${userConsole.route}/${manageGroups.route}/${manageGroups.editPageRoute}/:group_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={manageGroups.pageTitle}
                  subTitle={manageGroups.editPageTitle}
                  parentRoute={`/${userConsole.route}/${manageGroups.route}`}
                  form={manageGroupForm}
                  validations={manageGroupValidations}
                  apiMethod={dashboard.updateGroup}
                  pageType={manageGroups.userType}
                  initialValues={manageGroupInitialValues}
                />
              )
            },
            {
              name: manageGroups.viewPageTitle,
              key: manageGroups.viewPageRoute,
              route: `${userConsole.route}/${manageGroups.route}/${manageGroups.viewPageRoute}/:group_id`,
              type: permissionsType.view,
              component: (
                <ViewPage
                  title={manageGroups.pageTitle}
                  subTitle={manageGroups.viewPageTitle}
                  parentRoute={`/${userConsole.route}/${manageGroups.route}`}
                  formId={manageGroups.viewPageRoute}
                  form={manageGroupForm}
                  getApiMethod={dashboard.getGroupById}
                  pageType={manageGroups.userType}
                  initialValues={manageGroupInitialValues}
                />
              )
            },
            {
              name: manageGroups.COACHEE_PROGRESS_TITLE,
              key: manageGroups.COACHEE_PROGRESS_ROUTE,
              route: `${userConsole.route}/${manageGroups.route}/${manageGroups.viewPageRoute}/${manageGroups.COACHEE_PROGRESS_ROUTE}/:coachee_id`,
              type: permissionsType.view,
              component: (
                <CoacheeProgressReport
                  title={manageGroups.viewPageTitle}
                  subTitle={manageGroups.COACHEE_PROGRESS_TITLE}
                  parentRoute={`/${userConsole.route}/${manageGroups.route}/${manageGroups.viewPageRoute}`}
                  pageType={manageGroups.userType}
                />
              )
            }
          ]
        },
        // ?? MANAGE SPONSORING ENTITIES SECTION
        {
          name: sponsoringEntity.userType,
          key: sponsoringEntity.route,
          route: `${userConsole.route}/${sponsoringEntity.route}`,
          component: <ManageSponsoringEntities />,
          permissionName: sponsoringEntity.permissionName,
          children: [
            {
              type: permissionsType.create,
              name: sponsoringEntity.addPageTitle,
              key: sponsoringEntity.addPageRoute,
              route: `${userConsole.route}/${sponsoringEntity.route}/${sponsoringEntity.addPageRoute}`,
              component: (
                <CreatePage
                  key={sponsoringEntity.addPageRoute}
                  title={sponsoringEntity.pageTitle}
                  subTitle={sponsoringEntity.addPageTitle}
                  parentRoute={`/${userConsole.route}/${sponsoringEntity.route}`}
                  form={sponsoringEntityForm}
                  validations={sponsoringEntityValidations}
                  initialValues={sponsoringEntityInitialValues}
                  apiMethod={dashboard.createNewSponsoringEntity}
                  pageType={sponsoringEntity.userType}
                />
              )
            },
            {
              name: sponsoringEntity.editPageTitle,
              key: sponsoringEntity.editPageRoute,
              route: `${userConsole.route}/${sponsoringEntity.route}/${sponsoringEntity.editPageRoute}/:sponsoring_entity_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={sponsoringEntity.pageTitle}
                  subTitle={sponsoringEntity.editPageTitle}
                  parentRoute={`/${userConsole.route}/${sponsoringEntity.route}`}
                  form={sponsoringEntityForm}
                  validations={sponsoringEntityValidations}
                  apiMethod={dashboard.updateSponsoringEntity}
                  pageType={sponsoringEntity.userType}
                  initialValues={sponsoringEntityInitialValues}
                />
              )
            },
            {
              name: sponsoringEntity.viewPageTitle,
              key: sponsoringEntity.viewPageRoute,
              route: `${userConsole.route}/${sponsoringEntity.route}/${sponsoringEntity.viewPageRoute}/:sponsoring_entity_id`,
              type: permissionsType.view,
              component: (
                <ViewPage
                  title={sponsoringEntity.pageTitle}
                  subTitle={sponsoringEntity.viewPageTitle}
                  parentRoute={`/${userConsole.route}/${sponsoringEntity.route}`}
                  formId={sponsoringEntity.viewPageRoute}
                  form={sponsoringEntityForm}
                  getApiMethod={dashboard.getSponsoringEntityByID}
                  pageType={sponsoringEntity.userType}
                  initialValues={sponsoringEntityInitialValues}
                />
              )
            }
          ]
        },

        // ?? MANAGE SPONSORS SECTION
        {
          name: sponsor.userType,
          key: sponsor.route,
          route: `${userConsole.route}/${sponsor.route}`,
          component: <ManageSponsors />,
          permissionName: sponsor.permissionName,
          children: [
            {
              name: sponsor.addPageTitle,
              key: sponsor.addPageRoute,
              route: `${userConsole.route}/${sponsor.route}/${sponsor.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={sponsor.pageTitle}
                  subTitle={sponsor.addPageTitle}
                  parentRoute={`/${userConsole.route}/${sponsor.route}`}
                  form={sponsorForm}
                  validations={sponsorsValidations}
                  initialValues={sponsorsInitialValues}
                  apiMethod={dashboard.createNewUser}
                  pageType={sponsor.userType}
                />
              )
            },
            {
              name: sponsor.editPageTitle,
              key: sponsor.editPageRoute,
              route: `${userConsole.route}/${sponsor.route}/${sponsor.editPageRoute}/:user_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={sponsor.pageTitle}
                  subTitle={sponsor.editPageTitle}
                  parentRoute={`/${userConsole.route}/${sponsor.route}`}
                  form={sponsorForm}
                  validations={sponsorsValidations}
                  apiMethod={dashboard.updateUser}
                  pageType={sponsor.userType}
                  initialValues={sponsorsInitialValues}
                />
              )
            }
          ]
        }
      ]
    },

    // ?? MANAGE COACHING PROGRAMS
    {
      permissionName: coachingPrograms.permissionName,
      name: coachingPrograms.pageTitle,
      key: coachingPrograms.route,
      type: "collapse",
      route: `/${coachingPrograms.route}`,
      component: <CoachingProgramsConsole />,
      noCollapse: true,
      icon: <SideNavIcon icon="material-symbols:play-lesson-outline-rounded" />,
      children: [
        {
          name: coachingPrograms.addPageTitle,
          key: coachingPrograms.addPageRoute,
          route: `${coachingPrograms.route}/${coachingPrograms.addPageRoute}`,
          type: permissionsType.create,
          component: (
            <CreatePage
              title={coachingPrograms.pageTitle}
              subTitle={coachingPrograms.addPageTitle}
              parentRoute={`/${coachingPrograms.route}`}
              form={coachingProgramForm}
              validations={coachingProgramValidations}
              initialValues={coachingProgramInitialValues}
              apiMethod={dashboard.createCoachingProgram}
              pageType={coachingPrograms.userType}
            />
          )
        },
        {
          name: coachingPrograms.editPageTitle,
          key: coachingPrograms.editPageRoute,
          route: `${coachingPrograms.route}/${coachingPrograms.editPageRoute}/:coaching_program_id`,
          type: permissionsType.edit,
          component: (
            <EditPage
              title={coachingPrograms.pageTitle}
              subTitle={coachingPrograms.editPageTitle}
              parentRoute={`/${coachingPrograms.route}`}
              form={coachingProgramForm}
              validations={coachingProgramValidations}
              apiMethod={dashboard.updateCoachingProgram}
              pageType={coachingPrograms.userType}
              initialValues={coachingProgramInitialValues}
            />
          )
        }
      ]
    },

    // ?? ENGAGEMENT CONSOLE
    {
      type: "collapse",
      name: engagementConsole.pageTitle,
      key: engagementConsole.route,
      route: engagementConsole.route,
      permissionName: engagementConsole.permissionName,
      component: <EngagementConsole />,
      icon: <SideNavIcon icon="akar-icons:grid" />,
      noCollapse: true,
      children: [
        {
          name: engagementConsole.addPageTitle,
          key: engagementConsole.addPageRoute,
          route: `/${engagementConsole.route}/${engagementConsole.addPageRoute}`,
          type: permissionsType.create,
          component: <CreateEngagements />
        },
        {
          name: engagementConsole.editPageTitle,
          key: engagementConsole.editPageRoute,
          route: `/${engagementConsole.route}/${engagementConsole.editPageRoute}/:engagement_id`,
          type: permissionsType.edit,
          component: <EditEngagement />
        },
        {
          name: upcomingSessions.addPageTitle,
          key: upcomingSessions.addPageRoute,
          route: `/${engagementConsole.route}/${upcomingSessions.addPageRoute}`,
          permissionName: upcomingSessions.permissionName,
          type: permissionsType.create,
          component: (
            <CreatePage
              title={engagementConsole.pageTitle}
              subTitle={upcomingSessions.addPageTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={sessionForm}
              validations={sessionValidations}
              initialValues={sessionInitialValues}
              apiMethod={dashboard.createSessions}
              userType={upcomingSessions.userType}
            />
          )
        },
        {
          name: engagementConsole.viewSessionListTitle,
          key: engagementConsole.viewSessionListRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id`,
          component: (
            <ViewSessionList
              pageTitle={engagementConsole.viewSessionListTitle}
            />
          ),
          permissionName: "default"
        },
        {
          name: engagementConsole.viewReportTitle,
          key: engagementConsole.viewReportRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewReportRoute}/:engagement_id`,
          permissionName: "default",
          component: (
            <ViewReport
              title={engagementConsole.pageTitle}
              subTitle={engagementConsole.viewReportTitle}
              parentRoute={`/${engagementConsole.route}`}
            />
          )
        },
        {
          name: engagementConsole.EEDIT_COACHEE_PROGRESS_TITLE,
          key: engagementConsole.EDIT_COACHEE_PROGRESS_ROUTE,
          route: `/${engagementConsole.route}/${engagementConsole.EDIT_COACHEE_PROGRESS_ROUTE}/:engagement_id`,
          component: (
            <MyActionPlan
              title={engagementConsole.pageTitle}
              subTitle={engagementConsole.EEDIT_COACHEE_PROGRESS_TITLE}
              parentRoute={`/${engagementConsole.route}`}
            />
          ),
          permissionName: "default"
        },
        {
          name: engagementConsole.VIEW_INDIVIUAL_PROGRESS_TITLE,
          key: engagementConsole.VIEW_INDIVIUAL_PROGRESS_ROUTE,
          route: `/${engagementConsole.route}/${engagementConsole.VIEW_INDIVIUAL_PROGRESS_ROUTE}/:engagement_id`,
          component: (
            <ViewIndiviualProgress
              title={engagementConsole.pageTitle}
              subTitle={engagementConsole.VIEW_INDIVIUAL_PROGRESS_TITLE}
              parentRoute={`/${engagementConsole.route}`}
            />
          ),
          permissionName: "default"
        },
        //upcoming session from session list
        {
          name: upcomingSessions.viewJournalEnteriesGrid,
          key: upcomingSessions.viewJournalEnteriesGrid,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.viewJournalEnteriesGrid}/:session_id`,
          permissionName: upcomingSessions.journalEnteryPermissionName,
          component: (
            <ViewJournalGrid
              subTitle={upcomingSessions.viewJournalEnteriesGridTitle}
              parentRoute={`/${engagementConsole.route}`}
              pageTitle={engagementConsole.pageTitle}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        {
          name: upcomingSessions.addJournalTitle,
          key: upcomingSessions.addJournalRoute,
          route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.addJournalRoute}/:session_id`,
          type: permissionsType.create,
          permissionName: upcomingSessions.journalEnteryPermissionName,
          component: (
            <CreatePage
              title={upcomingSessions.pageTitle}
              subTitle={upcomingSessions.addJournalTitle}
              parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
              form={journalEnteryForm}
              validations={journalEntryValidations}
              initialValues={journalEntryInitialValues}
              apiMethod={dashboard.createJournalEntry}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        {
          name: upcomingSessions.viewJournalTitle,
          key: upcomingSessions.viewJournalRoute,
          route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.viewJournalRoute}/:journal_entry_id`,
          type: permissionsType.view,
          permissionName: upcomingSessions.journalEnteryPermissionName,
          component: (
            <ViewPage
              title={upcomingSessions.pageTitle}
              subTitle={upcomingSessions.viewJournalTitle}
              parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
              form={journalEnteryForm}
              validations={journalEntryValidations}
              initialValues={journalEntryInitialValues}
              getApiMethod={dashboard.getJournalEntryById}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        //#endorigin journal enteries

        {
          name: upcomingSessions.addPageTitle,
          key: upcomingSessions.addPageRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.addPageRoute}`,
          type: permissionsType.create,
          component: (
            <CreatePage
              title={upcomingSessions.pageTitle}
              subTitle={upcomingSessions.addPageTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={sessionForm}
              validations={sessionValidations}
              initialValues={sessionInitialValues}
              apiMethod={dashboard.createSessions}
              pageType={upcomingSessions.userType}
            />
          )
        },
        {
          name: upcomingSessions.editPageTitle,
          key: upcomingSessions.editPageRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.editPageRoute}/:session_id`,
          permissionName: "default",
          component: (
            <EditPage
              title={engagementConsole.pageTitle}
              subTitle={upcomingSessions.editPageTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={sessionForm}
              validations={sessionValidations}
              initialValues={sessionInitialValues}
              apiMethod={dashboard.updateSession}
              pageType={upcomingSessions.userType}
            />
          )
        },
        {
          name: upcomingSessions.addJournalTitle,
          key: upcomingSessions.addJournalRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.addJournalRoute}/:session_id`,
          type: permissionsType.create,
          permissionName: upcomingSessions.journalEnteryPermissionName,

          component: (
            <CreatePage
              title={engagementConsole.pageTitle}
              subTitle={upcomingSessions.addJournalTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={journalEnteryForm}
              validations={journalEntryValidations}
              initialValues={journalEntryInitialValues}
              apiMethod={dashboard.createJournalEntry}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        {
          name: upcomingSessions.viewJournalTitle,
          key: upcomingSessions.viewJournalRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.viewJournalRoute}/:journal_entry_id`,
          type: permissionsType.view,
          permissionName: upcomingSessions.journalEnteryPermissionName,
          component: (
            <ViewPage
              title={historySessions.pageTitle}
              subTitle={upcomingSessions.viewJournalTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={journalEnteryForm}
              validations={journalEntryValidations}
              initialValues={journalEntryInitialValues}
              getApiMethod={dashboard.getJournalEntryById}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        {
          name: upcomingSessions.editJournalTitle,
          key: upcomingSessions.editJournalRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.editJournalRoute}/:journal_entry_id`,
          type: permissionsType.edit,
          permissionName: upcomingSessions.journalEnteryPermissionName,
          component: (
            <EditPage
              title={engagementConsole.pageTitle}
              subTitle={upcomingSessions.editJournalTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={journalEnteryForm}
              validations={journalEntryValidations}
              initialValues={journalEntryInitialValues}
              apiMethod={dashboard.updateJournalEntry}
              pageType={upcomingSessions.journalEnteryUserType}
            />
          )
        },
        {
          name: upcomingSessions.editPageTitle,
          key: upcomingSessions.editPageRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${historySessions.editPageRoute}/:session_id`,
          permissionName: "default",
          component: (
            <EditPage
              title={engagementConsole.pageTitle}
              subTitle={upcomingSessions.viewPageTitle}
              parentRoute={`/${engagementConsole.route}`}
              form={sessionForm}
              validations={sessionValidations}
              initialValues={sessionInitialValues}
              apiMethod={dashboard.updateSession}
              pageType={historySessions.userType}
            />
          )
        },

        {
          name: historySessions.viewFeedbackPageTitle,
          key: historySessions.viewFeedbackRoute,
          route: `/${engagementConsole.route}/${engagementConsole.viewSessionListRoute}/:engagement_id/${historySessions.viewFeedbackRoute}/:session_id`,
          permissionName: historySessions.sessionFeedbackPermissionName,
          component: (
            <ViewSessionFeedback
              pageTitle={engagementConsole.pageTitle}
              subTitle={historySessions.viewFeedbackPageTitle}
              parentRoute={`/${engagementConsole.route}`}
              formId={historySessions.viewFeedbackRoute}
              form={sessionForm}
              // apiMethod={dashboard.updateSession}
              userType={historySessions.userType}
            />
          )
        }
      ]
    },

    // ?? SESSIONS SECTION
    {
      type: "collapse",
      name: "Sessions",
      key: "sessions",
      icon: <SideNavIcon icon="ic:outline-upcoming" />,
      collapse: [
        {
          name: "Upcoming Sessions",
          key: upcomingSessions.route,
          route: "/sessions/upcoming-sessions",
          component: <UpcomingSessions userType={upcomingSessions.userType} />,
          permissionName: upcomingSessions.permissionName,
          children: [
            //#startorigin Jounral Enteries
            {
              name: upcomingSessions.viewJournalEnteriesGrid,
              key: upcomingSessions.viewJournalEnteriesGrid,
              route: `/${sessions.route}/${upcomingSessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id`,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <ViewJournalGrid
                  subTitle={upcomingSessions.viewJournalEnteriesGridTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  pageTitle={upcomingSessions.pageTitle}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.addJournalTitle,
              key: upcomingSessions.addJournalRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.addJournalRoute}/:session_id`,
              type: permissionsType.create,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <CreatePage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.addJournalTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  apiMethod={dashboard.createJournalEntry}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.viewPageTitle,
              key: upcomingSessions.viewPageRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.viewPageRoute}/:session_id`,
              type: permissionsType.view,
              component: (
                <ViewPage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.viewPageTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  getApiMethod={dashboard.getSessionById}
                  pageType={upcomingSessions.userType}
                />
              )
            },
            {
              name: upcomingSessions.editJournalTitle,
              key: upcomingSessions.editJournalRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.editJournalRoute}/:journal_entry_id`,
              type: permissionsType.edit,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <EditPage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.editJournalTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  apiMethod={dashboard.updateJournalEntry}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.viewJournalTitle,
              key: upcomingSessions.viewJournalRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.viewJournalRoute}/:journal_entry_id`,
              type: permissionsType.view,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <ViewPage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.viewJournalTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  getApiMethod={dashboard.getJournalEntryById}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            //#endorigin journal enteries

            {
              name: upcomingSessions.addPageTitle,
              key: upcomingSessions.addPageRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.addPageRoute}`,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.addPageTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  apiMethod={dashboard.createSessions}
                  pageType={upcomingSessions.userType}
                />
              )
            },
            {
              name: upcomingSessions.editPageTitle,
              key: upcomingSessions.editPageRoute,
              route: `${sessions.route}/${upcomingSessions.route}/${upcomingSessions.editPageRoute}/:session_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={upcomingSessions.pageTitle}
                  subTitle={upcomingSessions.editPageTitle}
                  parentRoute={`/${sessions.route}/${upcomingSessions.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  apiMethod={dashboard.updateSession}
                  pageType={upcomingSessions.userType}
                />
              )
            }
          ]
        },
        {
          name: "Previous Sessions",
          key: "view-previous-sessions",
          route: `/${sessions.route}/${historySessions.route}`,
          component: <HistorySession userType={historySessions.userType} />,
          permissionName: historySessions.permissionName,
          children: [
            {
              name: upcomingSessions.viewJournalEnteriesGrid,
              key: upcomingSessions.viewJournalEnteriesGrid,
              route: `${sessions.route}/${historySessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id`,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <ViewJournalGrid
                  subTitle={upcomingSessions.viewJournalEnteriesGridTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  pageTitle={historySessions.pageTitle}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.addJournalTitle,
              key: upcomingSessions.addJournalRoute,
              route: `${sessions.route}/${historySessions.route}/${upcomingSessions.addJournalRoute}/:session_id`,

              type: permissionsType.create,
              permissionName: upcomingSessions.journalEnteryPermissionName,

              component: (
                <CreatePage
                  title={historySessions.pageTitle}
                  subTitle={upcomingSessions.addJournalTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  apiMethod={dashboard.createJournalEntry}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.viewJournalTitle,
              key: upcomingSessions.viewJournalRoute,
              route: `${sessions.route}/${historySessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.viewJournalRoute}/:journal_entry_id`,
              type: permissionsType.view,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <ViewPage
                  title={historySessions.pageTitle}
                  subTitle={upcomingSessions.viewJournalTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  getApiMethod={dashboard.getJournalEntryById}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },
            {
              name: upcomingSessions.editJournalTitle,
              key: upcomingSessions.editJournalRoute,
              route: `${sessions.route}/${historySessions.route}/${upcomingSessions.viewJournalEnteriesGrid}/:session_id/${upcomingSessions.editJournalRoute}/:journal_entry_id`,
              type: permissionsType.edit,
              permissionName: upcomingSessions.journalEnteryPermissionName,
              component: (
                <EditPage
                  title={historySessions.pageTitle}
                  subTitle={upcomingSessions.editJournalTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  form={journalEnteryForm}
                  validations={journalEntryValidations}
                  initialValues={journalEntryInitialValues}
                  apiMethod={dashboard.updateJournalEntry}
                  pageType={upcomingSessions.journalEnteryUserType}
                />
              )
            },

            {
              name: historySessions.viewPageTitle,
              key: historySessions.viewPageRoute,
              route: `${sessions.route}/${historySessions.route}/${historySessions.viewPageRoute}/:session_id`,
              type: permissionsType.view,
              component: (
                <ViewPage
                  title={historySessions.pageTitle}
                  subTitle={historySessions.viewPageTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  getApiMethod={dashboard.getSessionById}
                  pageType={historySessions.userType}
                />
              )
            },
            {
              name: upcomingSessions.editPageTitle,
              key: upcomingSessions.editPageRoute,
              route: `${sessions.route}/${historySessions.route}/${upcomingSessions.editPageRoute}/:session_id`,
              type: permissionsType.edit,
              component: (
                <EditPage
                  title={historySessions.pageTitle}
                  subTitle={upcomingSessions.viewPageTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  apiMethod={dashboard.updateSession}
                  pageType={historySessions.userType}
                />
              )
            },
            {
              name: historySessions.viewFeedbackPageTitle,
              key: historySessions.viewFeedbackRoute,
              route: `${sessions.route}/${historySessions.route}/${historySessions.viewFeedbackRoute}/:session_id`,

              permissionName: historySessions.sessionFeedbackPermissionName,

              component: (
                <ViewSessionFeedback
                  pageTitle={historySessions.pageTitle}
                  subTitle={historySessions.viewFeedbackPageTitle}
                  parentRoute={`/${sessions.route}/${historySessions.route}`}
                  formId={historySessions.viewFeedbackRoute}
                  form={sessionForm}
                  // apiMethod={dashboard.updateSession}
                  userType={historySessions.userType}
                />
              )
            }
          ]
        },
        {
          name: calenderSession.pageTitle,
          key: calenderSession.route,
          route: `${sessions.route}/${calenderSession.route}`,
          component: <CalenderSession pageTitle={calenderSession.pageTitle} />,
          permissionName: calenderSession.permissionName,
          children: [
            {
              name: upcomingSessions.addPageTitle,
              key: upcomingSessions.addPageRoute,
              route: `/${sessions.route}/${calenderSession.route}/${upcomingSessions.addPageRoute}`,
              permissionName: upcomingSessions.permissionName,
              type: permissionsType.create,
              component: (
                <CreatePage
                  title={calenderSession.pageTitle}
                  subTitle={upcomingSessions.addPageTitle}
                  parentRoute={`/${sessions.route}/${calenderSession.route}`}
                  form={sessionForm}
                  validations={sessionValidations}
                  initialValues={sessionInitialValues}
                  apiMethod={dashboard.createSessions}
                  userType={upcomingSessions.userType}
                />
              )
            }
          ]
        }
      ]
    },
    // ?? ACTION PLAN TEMPLATE SECTION
    {
      name: ActionPlanTemplateConstants.pageTitle,
      key: ActionPlanTemplateConstants.route,
      type: "collapse",
      route: `/${ActionPlanTemplateConstants.route}`,
      component: <ActionPlanTemplate />,
      noCollapse: true,
      icon: <SideNavIcon icon="icomoon-free:insert-template" />,
      permissionName: ActionPlanTemplateConstants.permissionName,
      children: [
        {
          name: ActionPlanTemplateConstants.addPageTitle,
          key: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.addPageRoute}`,
          route: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.addPageRoute}`,
          type: permissionsType.create,
          component: <CreateActionPlan />
        },
        {
          name: ActionPlanTemplateConstants.editPageTitle,
          key: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.editPageRoute}`,
          route: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.editPageRoute}/:action_plan_template_id`,
          type: permissionsType.edit,
          component: <ViewActionPlan />
        },
        {
          name: ActionPlanTemplateConstants.viewPageTitle,
          key: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.viewPageRoute}`,
          route: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.viewPageRoute}/:action_plan_template_id`,
          type: permissionsType.view,
          component: (
            <ViewActionPlan
              subTitle={ActionPlanTemplateConstants.viewPageTitle}
            />
          )
        },

        {
          name: ActionPlanTemplateConstants.EDIT_EXISTING_ACTION_PLAN_TITLE,
          key: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.EDIT_EXISTING_ACTION_PLAN_ROUTE}`,
          route: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.EDIT_EXISTING_ACTION_PLAN_ROUTE}`,
          component: <EditExistingPlan />,
          type: permissionsType.edit,
          permissionName:
            ActionPlanTemplateConstants.EDIT_EXISTING_ACTION_PLAN_PermissionName
        },
        {
          name: ActionPlanTemplateConstants.ADD_HTML_TEXT_TITLE,
          key: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.ADD_HTML_TEXT_ROUTE}`,
          route: `${ActionPlanTemplateConstants.route}/${ActionPlanTemplateConstants.ADD_HTML_TEXT_ROUTE}`,
          component: <AddHtmlText />,
          type: permissionsType.edit,
          permissionName:
            ActionPlanTemplateConstants.EDIT_EXISTING_ACTION_PLAN_PermissionName
        },
        {
          name: ActionPlanTemplateConstants.INTERACTIVE_TOOL_BUILDER,
          key: `${ActionPlanTemplateConstants.route}/:action_plan_template_id/${ActionPlanTemplateConstants.INTERACTIVE_TOOL_BUILDER_ROUTE}/:resource_id`,
          route: `${ActionPlanTemplateConstants.route}/:action_plan_template_id/${ActionPlanTemplateConstants.INTERACTIVE_TOOL_BUILDER_ROUTE}/:resource_id`,
          component: <InteractiveToolBuilder />,
          type: permissionsType.create
        }
      ]
    },

    // ?? MANAGE DOCUMENT
    {
      type: "collapse",
      name: manageDocument.pageTitle,
      key: manageDocument.route,
      route: manageDocument.route,
      component: <ManageDocument />,
      icon: (
        <SideNavIcon
          icon="healthicons:i-documents-accepted-outline"
          width={26}
          height={26}
        />
      ),
      noCollapse: true,
      permissionName: manageDocument.permissionName,
      children: [
        {
          name: manageDocument.addPageTitle,
          key: `${manageDocument.route}/${manageDocument.addPageRoute}`,
          route: `${manageDocument.route}/${manageDocument.addPageRoute}`,
          type: permissionsType.create,
          component: <AddDocument />
        },
        {
          name: manageDocument.editPageTitle,
          key: `${manageDocument.route}/${manageDocument.editPageRoute}`,
          route: `${manageDocument.route}/${manageDocument.editPageRoute}/:document_id`,
          type: permissionsType.edit,
          component: <EditDocument />
        },
        {
          name: manageDocument.assignPageTitle,
          key: `${manageDocument.route}/${manageDocument.assignPageRoute}`,
          route: `${manageDocument.route}/${manageDocument.assignPageRoute}/:document_id`,
          component: <AssignDocumet />,
          permissionName: "default"
        }
      ]
    },

    // ?? ACTIVITY REPORT SECTION
    {
      type: "collapse",
      name: activityReports.pageTitle,
      key: "activity-report",
      route: "/activity-report",
      component: <ActivityReport />,
      icon: <SideNavIcon icon="carbon:report-data" />,
      noCollapse: true,
      permissionName: activityReports.permissionName
    },

    // ?? AUDIT LOGS SECTION
    {
      type: "collapse",
      name: accessAuditLog.pageTitle,
      key: accessAuditLog.route,
      route: `/${accessAuditLog.route}`,
      component: <AccessAuditLog />,
      icon: <SideNavIcon icon="carbon:cloud-auditing" />,
      noCollapse: true,
      permissionName: accessAuditLog.permissionName
    },

    // ?? MANAGE PROFILE SECTION
    {
      name: manageProfile.pageTitle,
      key: manageProfile.route,
      route: `/${manageProfile.route}`,
      component: <ManageProfile pageType={manageProfile.userType} />,
      permissionName: "default"
    },

    // ?? CHANGE PASSWORD SECTION
    {
      name: changePassword.pageTitle,
      key: changePassword.route,
      route: `/${changePassword.route}`,
      component: <ChangePassword pageType={changePassword.pageType} />,
      permissionName: "default"
    },

    {
      name: ActionPlanTemplateConstants.COACHEE_INTERACTIVE_TOOL_BUILDER,
      key: `${ActionPlanTemplateConstants.route}/:coachee_action_plan_id/${ActionPlanTemplateConstants.COACHEE_INTERACTIVE_TOOL_BUILDER_ROUTE}/:resource_id`,
      route: `${ActionPlanTemplateConstants.route}/:coachee_action_plan_id/${ActionPlanTemplateConstants.COACHEE_INTERACTIVE_TOOL_BUILDER_ROUTE}/:resource_id`,
      component: <CoacheeInteractiveToolBuilder />,
      type: permissionsType.create
    }
  ];
};
export default useSideNavRoutes;
