import coachForm from "./form";

const {
  prefix,
  first_name,
  middle_name,
  last_name,
  user_name,
  state,
  password,
  postal_code,
  email_address,
  country,
  address,
  confirm_password,
  position,
  user_role,
  phone_number,
  sponsoring_entity,
  customer_id,
  city,
} = coachForm;

const initialValues = {
  [prefix.name]: "",
  [first_name.name]: "",
  [middle_name.name]: "",
  [last_name.name]: "",
  [user_name.name]: "",
  [state.name]: "",
  [country.name]: "United States",
  [city.name]: "",
  [postal_code.name]: "",
  [address.name]: "",
  [password.name]: "",
  [confirm_password.name]: "",
  [phone_number.name]: "",
  [position.name]: "",
  [user_role.name]: "Coach",
  [sponsoring_entity.name]: "",
  [email_address.name]: "",
  [customer_id.name]: "",
};

export default initialValues;
