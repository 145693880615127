import form from "./form";

const { group_title, group_description, customer_id } = form;

const initialValues = {
  [group_title.name]: "",
  [group_description.name]: "",
  [customer_id.name]: "",
};

export default initialValues;
