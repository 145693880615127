const journalEnteryForm = {
  entry_title: {
    name: "entry_title",
    label: "Title *",
    type: "text",
    errorMsg: "Title is required",
  },
  entry_description: {
    name: "entry_description",
    label: "Description *",
    type: "textarea",
    errorMsg: "Description is required",
  },
  session_id: {
    name: "session_id",
    label: "Session *",
    type: "text",
    errorMsg: "Session is required",
  },
  entry_date: {
    name: "entry_date",
    label: "Date *",
    type: "date",
    errorMsg: "Date is required",
  },
};

export default journalEnteryForm;
