import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";

const initialState = {
  sponsoringEntities: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1
  },
  allItemsChecked: false,
  isAnyItemChecked: true,
  checkedItems: [],
  alreadyAddedSponsor: [],
  sponsorData: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1
  },
  isSponsoringSponsorLoading: false
};

export const sponsoringEntitySlice = createSlice({
  name: "sponsoringEntities",
  initialState,
  reducers: {
    resetSponsorEntities: (state) => {
      state = initialState;
    },
    resetSponsorsForSE: (state) => {
      state.sponsorData = {
        currentPage: 1,
        data: [],
        pageSize: 5,
        totalItem: 5,
        totalPage: 1
      };
    },
    setAllItemsChecked: (
      state,
      { payload: { allItemsChecked, isSubTable = false } }
    ) => {
      state.allItemsChecked =
        (!isSubTable && state.sponsoringEntities.data.length) ||
        (isSubTable && state.sponsorData.data.length)
          ? allItemsChecked
          : false;
      state.isAnyItemChecked = allItemsChecked;
      if (allItemsChecked) {
        state.checkedItems = isSubTable
          ? state.sponsorData.data.filter((x) => x.is_active)
          : state.sponsoringEntities.data;
      } else {
        state.checkedItems = [];
      }
      let dataArray = isSubTable
        ? state.sponsorData.data
        : state.sponsoringEntities.data;
      dataArray.map((item) => {
        if (isSubTable) {
          item.isChecked = item.is_active ? allItemsChecked : false;
          return item;
        } else {
          item.isChecked = allItemsChecked;
          return item;
        }
      });
    },

    setItemChecked: (state, { payload: { id, isSubTable = false } }) => {
      let matchedItem;
      if (isSubTable) {
        // isSubTable will be true if the table for intended for sub table in add or edit group
        matchedItem = state?.sponsorData?.data?.find((item) => item.id === id);
      } else {
        matchedItem = state?.sponsoringEntities?.data?.find(
          (item) => item.sponsoring_entity_id === id
        );
      }
      matchedItem.isChecked = !matchedItem?.isChecked;
      // adding and removing data from checked item array
      if (matchedItem.isChecked) {
        state.checkedItems = [...state.checkedItems, matchedItem];
      } else {
        // if any of item unchecked so check all item will be false so checked would be remove from check all
        state.allItemsChecked = false;

        state.checkedItems = state.checkedItems.filter((item) => {
          if (isSubTable) {
            return item.id !== matchedItem.id;
          } else {
            return (
              item.sponsoring_entity_id !== matchedItem.sponsoring_entity_id
            );
          }
        });
      }

      // check if any item is checked
      if (
        state.sponsoringEntities?.data?.some((item) => item.isChecked === true)
      ) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },
    setClearCheckedItem: (state) => {
      state.checkedItems = [];
      state.isAnyItemChecked = [];
      state.alreadyAddedSponsor = [];
    },
    getAlreadyAddedSponsor: (state, { payload }) => {
      state.alreadyAddedSponsor = payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getSponsoringEntities.fulfilled, (state, { payload }) => {
      if (payload.data) {
        payload.data.map((item) => {
          item.isChecked = false;
          return item;
        });

        state.sponsoringEntities = payload;
        state.allItemsChecked = false;
        state.isAnyItemChecked = false;
      } else {
        state.sponsoringEntities = [];
      }
    });
    builder.addCase(getSponsors.pending, (state) => {
      state.isSponsoringSponsorLoading = true;
    });
    builder.addCase(getSponsors.fulfilled, (state, { payload }) => {
      state.isSponsoringSponsorLoading = false;
      if (payload.data) {
        state.checkedItems = [];
        payload.data.sponsors.map((item) => {
          if (state.alreadyAddedSponsor?.includes(item.id)) {
            item.isChecked = true;
            state.checkedItems = [...state.checkedItems, item];
          } else {
            item.isChecked = false;
          }
          return item;
        });
        // this step is taken to add another property 'isChecked' to the data table
        state.sponsorData.data = payload.data.sponsors;
        state.sponsorData.pageSize = payload.data.sponsors.length;
        state.sponsorData.totalPage = 1;
      }
    });
    builder.addCase(getSponsors.rejected, (state) => {
      state.isSponsoringSponsorLoading = false;
    });
  }
});

export const getSponsoringEntities = createAsyncThunk(
  "sponsoringEntities/getSponsoringEntities",
  async ({ currentPage, pageSize, customer_id = null, searchQuery }) => {
    const response = await dashboard.getSponsoringEntities(
      currentPage,
      pageSize,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getSponsors = createAsyncThunk(
  "sponsoringEntities/getSponsors",
  async ({ sponsoring_entity_id }) => {
    const response = await dashboard.getSponsoringEntityByID(
      sponsoring_entity_id
    );
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setSelectedCustomer,
  setAllItemsChecked,
  setItemChecked,
  setClearCheckedItem,
  resetSponsorEntities,
  resetSponsorsForSE,
  getAlreadyAddedSponsor
} = sponsoringEntitySlice.actions;

export default sponsoringEntitySlice.reducer;
