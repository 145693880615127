import { useMaterialUIController } from "context";
import useSideNavRoutes from "utils/sideNav-routes";
import Sidenav from "layouts/common/Navs/Sidenav";

export default function SideNavLayout() {
  const [controller] = useMaterialUIController();
  const { brandDark, sidenavColor } = controller;

  return (
    <Sidenav
      color={sidenavColor}
      brand={brandDark}
      brandName="RungJumper"
      routes={useSideNavRoutes()}
    />
  );
}
