import { useState, useEffect } from "react";

// react-router components
import { Link, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import NotificationItem from "components/Items/NotificationItem";
import { logOut } from "redux-store/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ProfileImg from "../../../../assets/images/profile.jpg";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarRightRow,
} from "layouts/common/Navs/DashboardNavbar/styles";
import sidenavLogoLabel from "layouts/common/Navs/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import pxToRem from "assets/theme/functions/pxToRem";
import { MenuItemIcon } from "components/custom-components/IconifyIcon";
import { resetKeywords } from "redux-store/features/dashboard/keywordSlice";
import { resetRolePermissions } from "redux-store/features/dashboard/roleAndPermissions";
import CustomLogo from "components/custom-components/custom-logo";
import {
  setActivePanel,
  setSubActivePanel,
} from "redux-store/features/dashboard/coacheeTabsSlice";
import {
  setSelectedCustomer,
  resetCustomerDropdown,
  resetCustomerSlice,
} from "redux-store/features/dashboard/customersSlice";
import { resetDataTable } from "redux-store/features/dashboard/dataTableSlice";
import { resetGroupSlice } from "redux-store/features/dashboard/groupsSlice";
import { resetSponsorEntities } from "redux-store/features/dashboard/sponsoringEntitySlice";
import iconBg from "./../../../../assets/images/Group 2134.svg";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";
import colors from "assets/theme/base/colors";
import { CustomTooltip } from "components/custom-components/custom-tooltip";

function DashboardNavbar({ absolute, light, isMini }) {
  const {
    keywords: { coach, coachee },
  } = useSelector((state) => state.keywords);

  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const { innerWidth } = window;
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  const dispatchAction = useDispatch();
  const { logo, user_name, user_role, user_logo } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  //checking the size and automatically truns into miniSidenav
  const handleMiniSidenavClick = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu();
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    if (windowWidth <= 1200) {
      setMiniSidenav(dispatch, true);
    } else {
      setMiniSidenav(dispatch, false);
    }
  }, [windowWidth]);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <Link to="/manage-profile" onClick={handleCloseMenu}>
        <NotificationItem
          icon={<MenuItemIcon icon="bytesize:settings" />}
          title="Manage Profile"
        />
      </Link>
      <Link to="/change-password" onClick={handleCloseMenu}>
        <NotificationItem
          icon={<MenuItemIcon icon="codicon:lock" />}
          title="Change Password"
        />
      </Link>
      <NotificationItem
        icon={<MenuItemIcon icon="codicon:sign-out" />}
        title="Sign Out"
        onClick={() => {
          handleCloseMenu();
          dispatchAction(resetKeywords());
          dispatchAction(resetRolePermissions());
          dispatchAction(setActivePanel(1));
          dispatchAction(setSubActivePanel(false));
          dispatchAction(setSelectedCustomer(null));
          dispatchAction(resetCustomerDropdown());
          dispatchAction(resetDataTable());
          dispatchAction(resetCustomerSlice());
          dispatchAction(resetGroupSlice());
          dispatchAction(resetSponsorEntities());
          dispatchAction(logOut());
          // window.location.reload();
          // after signing-out, set state to undefined so that the user does not get directed to the previous page
          navigate("/sign-in", { state: undefined, replace: true });
        }}
      />
    </Menu>
  );
  // useEffect(() => {
  //   handleMiniSidenavClick();
  // }, []);

  const upload_Document = () => {
    if (`{/dashboard}`) {
      dispatchAction(setActivePanel(5));
    }
    if (`{/sessions}`) {
      navigate("/dashboard");
    }
  };

  const isCoachee = useIsThisUser(all_roles.coachee);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <MDBox pt={1} pb={1} px={0} textAlign="center">
            <MDBox display="flex" alignItems="center">
              <IconButton
                size="large"
                disableRipple
                color="primary"
                onClick={() => {
                  if (miniSidenav && innerWidth <= 1200) {
                    return;
                  } else {
                    handleMiniSidenavClick();
                  }
                }}
                sx={{ paddingLeft: 0, paddingRight: pxToRem(20) }}
              >
                {/* <Icon fontSize="large">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon> */}
                <CustomTooltip
                  title={miniSidenav ? "expand" : "collapse"}
                  placement="right"
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      color: isCoachee
                        ? colors.coacheePortal.textColor
                        : colors.primary.main,
                    }}
                  >
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </CustomTooltip>
              </IconButton>
              <MDBox
                width="100%"
                component={NavLink}
                to="/admin-dashboard"
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                <MDTypography
                  component="h1"
                  variant="button"
                  fontWeight="medium"
                  // color="primary"
                  // sx={{ fontSize: "1.5rem" }}
                  sx={{
                    fontSize: "1.5rem",
                    color: isCoachee
                      ? colors.coacheePortal.primary
                      : colors.primary.main,
                    // width:"150px",
                    // height:"80px"
                    marginTop: "10px",
                  }}
                >
                  {/* RungJumper */}
                  <img src={iconBg} width="150px" height="100%" />
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        {logo && (
          <MDBox>
            <CustomLogo src={logo} alt={logo} />
          </MDBox>
        )}
        <MDBox sx={(theme) => navbarRightRow(theme, { isMini })}>
          {/* {isCoachee && (
            <MDBox
              onClick={upload_Document}
              sx={{ display: "flex", alignItems: "center", marginRight: 2 }}
            >
              <MDTypography
                sx={{
                  color: colors.coacheePortal.primary,
                  paddingTop: 1.2,
                  paddingRight: 0.6,
                }}
              >
                <MenuItemIcon icon="ant-design:upload-outlined" />
              </MDTypography>
              <MDTypography
                variant="h5"
                color="primary"
                sx={{
                  textDecoration: "underline",
                  color: colors.coacheePortal.primary,
                  cursor: "pointer",
                }}
              >
                Go to your uploaded files
              </MDTypography>
            </MDBox>
          )} */}
          <MDBox color={light ? "white" : "inherit"}>
            <IconButton
              sx={navbarIconButton}
              size="large"
              disableRipple
              onClick={handleOpenMenu}
            >
              <MDAvatar
                src={user_logo ? user_logo : ProfileImg}
                alt="profile-img"
                size="md"
                variant=""
                sx={{ objectFit: "cover !important" }}
              />
              <Typography
                fontWeight="regular"
                variant="h6"
                color={light ? "white" : "dark"}
                display="block"
                textAlign="left"
                sx={{
                  color: isCoachee ? colors.coacheePortal.primary : "primary",
                }}
              >
                {user_name}
                <span style={{ display: "block", fontSize: "1rem" }}>
                  <Typography variant="caption">
                    {user_role === "Coach"
                      ? coach
                      : user_role === "Coachee"
                      ? coachee
                      : user_role}
                  </Typography>
                </span>
              </Typography>

              <Icon
                color="primary"
                fontSize="large"
                sx={{
                  color: isCoachee
                    ? colors.coacheePortal.primary
                    : colors.primary.main,
                }}
              >
                arrow_drop_down_icon
              </Icon>
            </IconButton>

            {renderMenu()}
          </MDBox>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
