import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ErrorMessages } from "utils/constants";
import { checkifTokenExpired } from "utils/helper-method";

export const toastifySlice = createSlice({
  name: "toastify",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(successToast.fulfilled, (state, { payload }) => {
      const { expiry, message } = payload;

      if (expiry && !checkifTokenExpired(expiry)) {
        toast.info(message);
      }
    });
    builder.addCase(errorToast.fulfilled, (state, { payload }) => {
      const { expiry, message } = payload;
      const messageText =
        (message.response?.data?.error_message &&
          message.response?.data?.error_message[0]) ||
        message.response?.data?.title ||
        (typeof message === "string" && message) ||
        "Error";

      if (expiry && !checkifTokenExpired(expiry)) {
        toast.error(messageText);
      }
    });
  },
});

export const successToast = createAsyncThunk(
  "toastifySlice/successToast",
  async (payload, { getState }) => {
    const { expiry } = getState().auth;

    let updatedPayload = {
      expiry,
      message: payload,
    };
    return updatedPayload;
  }
);
export const errorToast = createAsyncThunk(
  "toastifySlice/errorToast",
  async (payload, { getState }) => {
    const { expiry } = getState().auth;

    let updatedPayload = {
      expiry,
      message: payload,
    };
    return updatedPayload;
  }
);

export default toastifySlice.reducer;
