import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tooltip } from "utils/apiMethods";
import { dashboard } from "utils/apiMethods";
import { adminControl } from "utils/apiMethods";

export const adminControlSlice = createSlice({
  name: "adminControl",
  initialState: {
    video_session: "",
    allPermissions: [],
    updatedPermissions: [],
    tooltipData: []
  },
  reducers: {
    resetPermissions: (state) => {
      state.allPermissions = [];
      state.updatedPermissions = [];
    },
    updatePermission: (state, { payload }) => {
      if (state.updatedPermissions.indexOf(payload) === -1) {
        state.updatedPermissions.push(payload);
      } else {
        state.updatedPermissions = state.updatedPermissions.filter(
          (permission) => {
            return permission !== payload;
          }
        );
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setVideoSessionUpdate.fulfilled, (state, { payload }) => {
      if (payload) {
        state.video_session = payload.video_session;
      } else {
        state.video_session = "";
      }
    });
    builder.addCase(getAllPermissions.fulfilled, (state, { payload }) => {
      if (payload) {
        state.allPermissions = payload.data;
      }
      payload.data.map((permission) => {
        if (permission.Checked) {
          state.updatedPermissions.push(permission.Value);
        }
      });
    });
    builder.addCase(getTooltipData.fulfilled, (state, { payload }) => {
      state.tooltipData = payload;
    });
  }
});

// thunks are used to making asynchronous api calls
export const setVideoSessionUpdate = createAsyncThunk(
  "adminControl/setVideoSessionUpdate",
  async ({ value, customer_id }) => {
    const response = await adminControl.videoSessionUpdate(value, customer_id);
    return response;
  }
);

export const getAllPermissions = createAsyncThunk(
  "adminControl/getAllPermissions",
  async ({ user_id = null, customer_id, role }) => {
    const response = await dashboard.getPermisionsByRole(
      user_id,
      customer_id,
      role
    );
    return response;
  }
);

export const setUpdatedPermissions = createAsyncThunk(
  "customers/setUpdatedPermissions",
  async ({ user_id, customer_id, role, future_update }, { getState }) => {
    const updatePersmissionsForApi =
      getState().adminControl.updatedPermissions.map((permission) => {
        return { Value: permission, Type: "permission type" };
      });
    const response = await dashboard.setUpdatedPermissions(
      customer_id,
      role,
      user_id,
      future_update,
      updatePersmissionsForApi
    );
  }
);
export const getTooltipData = createAsyncThunk(
  "customers/getTooltipData",
  async ({ tool_name }) => {
    const response = await tooltip.getTooltip({ tool_name });
    return response;
  }
);
export const getUsersByRole = createAsyncThunk("adminControl/getUsersByRole");
// Action creators are generated for each case reducer function
export const { resetPermissions, updatePermission } = adminControlSlice.actions;

export default adminControlSlice.reducer;
