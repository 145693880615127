import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import React from "react";

export default function ButtonSpinner({ isLoading, handleSubmit, text, Icon }) {
  return (
    <MDButton
      variant="contained"
      hovercolor="#095fb2"
      size="large"
      style={{
        width: "15rem",
        "& .MuiButton-root": {
          padding: "0px !important",
        },
        backgroundColor: "#495fb2",
        textAlign: "center",
        borderRadius: "0px !important",
      }}
      onClick={handleSubmit}
    >
      {isLoading ? (
        <CircularProgress color="white" size={22} />
      ) : (
        <>
          {/* {Icon} */}
          &nbsp;&nbsp;
          {Icon}
          <span
            style={{
              marginLeft: 7,
              color: "white",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {text}
          </span>
          &nbsp;&nbsp;
        </>
      )}
    </MDButton>
  );
}
