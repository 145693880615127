import * as Yup from "yup";
import customerForm from "./form";

const { organization_name, contacts } = customerForm;

const contact_item = contacts[0];

const validations = Yup.object().shape({
  [organization_name.name]: Yup.string().required(organization_name.errorMsg),
  contacts: Yup.array().of(
    Yup.object().shape({
      [contact_item.first_name?.name]: Yup.string()
        .required(contact_item.first_name.errorMsg)
        .nullable(),
      [contact_item.last_name?.name]: Yup.string()
        .required(contact_item.last_name.errorMsg)
        .nullable(),
      [contact_item.email.name]: Yup.string()
        .required(contact_item.email.errorMsg)
        .email(contact_item.email.invalidMsg)
        .nullable(),
      [contact_item.position?.name]: Yup.string().nullable(),
      [contact_item.direct_phone_number?.name]: Yup.string().nullable(),
      [contact_item.phone_number?.name]: Yup.string().nullable(),
    })
  ),
});

export default validations;
