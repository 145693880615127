import form from "./form";

const {
  customer_id,
  coaching_program_name,
  coaching_program_description,
  coaches_id,
  action_plan_templates_id,
  program_managers_id,
} = form;

const coachingProgramInitialValues = {
  [customer_id.name]: "",
  [coaching_program_name.name]: "",
  [coaching_program_description.name]: "",
  [action_plan_templates_id.name]: [],
  [coaches_id.name]: [],
  [program_managers_id.name]: [],
};

export default coachingProgramInitialValues;
