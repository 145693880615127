import sponsoringEntityForm from "./form";

const {
  sponsoring_entity_name,
  state,
  city,
  postal_code,
  country,
  address,
  customer_id,
} = sponsoringEntityForm;

const initialValues = {
  [sponsoring_entity_name.name]: "",
  [state.name]: "",
  [country.name]: "United States",
  [postal_code.name]: "",
  [address.name]: "",
  [city.name]: "",
  [customer_id.name]: "",
};

export default initialValues;
