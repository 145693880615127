import moment from "moment";
import jwt_decode from "jwt-decode";
import MDTypography from "components/MDTypography";
import momentTz from "moment-timezone";
import { programManager, coaches, coachees, sponsor } from "utils/constants";
import DefaultCell from "layouts/dashboard/components/Tables/DataTable/DefaultCell";
import colors from "assets/theme/base/colors";
import { stopLoading } from "redux-store/features/dashboard/loaderSlice";
import {
  hideSnackbar,
  isError
} from "redux-store/features/dashboard/snackbarSlice";
import useRoutes from "./routes";
import { store } from "redux-store/store";
import { auth } from "./apiMethods";
import { api } from "utils/axios-config";
import {
  logOut,
  resetRefreshToken,
  refreshAuthToken
} from "redux-store/features/auth/authSlice";

export const isThisRouteAllowed = (module, allowedModules) => {
  const { permissionName } = module;
  if (permissionName) {
    if (permissionName in allowedModules || permissionName === "default")
      return true;
  }
  return false;
};

export const sortArray = (array, sortByField) => {
  array.sort(function (a, b) {
    if (a[sortByField] < b[sortByField]) {
      return -1;
    }
    if (a[sortByField] > b[sortByField]) {
      return 1;
    }
    return 0;
  });
  return array;
};

export const isChildRouteAllowed = (
  permissionName,
  allowedModules,
  type,
  childPermission
) => {
  let allowedActions;
  if (!childPermission) {
    allowedActions = allowedModules[permissionName];
  }
  // if type and childpermissions both are true so allowed action would be taken from childpermissions
  else if (type && childPermission) {
    allowedActions = allowedModules[childPermission];
  }
  // if type is undefined, it's mean that childRoute have permissionName and treated as module from BE
  if (
    type &&
    allowedActions?.indexOf !== undefined &&
    allowedActions?.indexOf(type) !== -1
  ) {
    return true;
  }

  // if type is not given means it is not intent child route
  if (!type) {
    return isThisRouteAllowed(
      { permissionName: childPermission },
      allowedModules
    );
  }

  return false;
};

// capitalize the first letter of each word in a sentence
export const capitalizeFirstLetters = (str) => {
  if (str) {
    if (typeof str === "string")
      return str?.charAt(0).toUpperCase() + str?.substring(1).toLowerCase();
    if (str?.length <= 1) return str;
    let newStr = str?.map((element) => {
      return (
        element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
      );
    });
    return newStr?.join(" ");
  }
};

// add the 'hours' parameter in the provided 'data' object
export const addHours = (date, hours) => {
  const newDate = moment(date).add(hours, "hours").format();
  return newDate;
};

// check if route path/url is public or protected
export const useIs_CurrentPath_a_PublicRoute = (currentPath) => {
  const routes = useRoutes();
  if (routes.publicRoutes.some((route) => currentPath?.includes(route.route))) {
    return true;
  }
  return false;
};

export const checkifTokenExpired = (token_expiry) => {
  let expiry =
    token_expiry !== null && token_expiry !== "" ? token_expiry : null;
  // if token's expiry date is same or after current datetime, return false (indicating that token is not expired),
  // else return true (indicating that token has expired)
  if (expiry) return moment(token_expiry).isSameOrAfter() ? false : true;
  else return true;
};

export const parseAuthToken = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
// drop down updater of form
export const dropDownUpdater = (name, dropDown, formField, props) => {
  formField[name] = {
    ...formField[name],
    options: dropDown,
    ...props
  };
};

// token decoder, creating helper function to avoid calling library on components
export const decodeToken = (token) => {
  const result = jwt_decode(token);
  return result;
};

export const LabelAesterikReder = (label) => {
  let customizeLabel;
  let aesterik;
  if (label?.includes("*")) {
    let splittedLabel = label.split(" ");
    aesterik = splittedLabel.pop();
    customizeLabel = splittedLabel.join(" ");
    return (
      <>
        {customizeLabel}{" "}
        <MDTypography variant="subtitle" color="error">
          {aesterik}
        </MDTypography>
      </>
    );
  }
  return label;
};

export const isCurrentUserPage = (currentPage, wantedPageType) => {
  if (currentPage === wantedPageType) return true;
  return false;
};

export const UTC_to_local = (value) => {
  const local = new Date(`${value}Z`);
  const UTC = new Date(value);

  let localDate = moment(UTC).format("YYYY-MM-DD");
  let localDateAlt = moment(local).format("YYYY-MM-DD");
  let UTCDate = moment(local).format("MM/DD/YYYY");
  let localTime = moment(local).format("h:mm:ss a");
  let localString = moment(local);
  //format August-23-2022
  let fullMonthYear = moment(local).format("MMMM Do YYYY");
  let fullMonthYearLocal = moment(UTC).format("MMMM Do YYYY");
  //format h:mm A
  let loaclTimeHrsMin = moment(UTC).format("h:mm A");
  //format MMMM YYYY
  let localMonthYear = moment(local).format("DD MMMM YYYY");
  return {
    localDateAlt,
    UTCDate,
    localDate,
    localTime,
    localString,
    fullMonthYear,
    loaclTimeHrsMin,
    localMonthYear,
    fullMonthYearLocal
  };
  // return convertedDate;
};
export const convertTimeZones = {
  onlyZone: (value, timeZone) => {
    return moment.tz(value, timeZone).format();
  },
  convertTime: (value, currentZone, initialZone = "UTC") => {
    return momentTz
      .tz(value, initialZone)
      .tz(currentZone)
      .format("YYYY-MM-DDTHH:mm");
  },
  convertTheZone: function (value, time_zone) {
    let localZone = moment.tz.guess(true);
    let convertedTime = this.convertTime(value, time_zone);
    let localZoneTime = this.onlyZone(convertedTime, localZone);
    return localZoneTime;
  }
};

export const isCoachCoacheeSponsorOrPM = (pageType) => {
  if (
    pageType === programManager.userType ||
    pageType === coaches.userType ||
    pageType === coachees.userType ||
    pageType === sponsor.userType
  ) {
    return true;
  }
  return false;
};

// drop down lenght checker if values have been fetched, will show value otherwise No Value Found
export const dropDownValueChecker = (text, dropDown) => {
  if (dropDown.length === 0) {
    return [
      {
        text: `No ${text} Found`,
        value: null
      }
    ];
  }

  return dropDown;
};

// custom method for grid
export const isDisabledFieldForGrid = (disabled) => {
  return {
    Cell: ({ value, ...props }) => {
      let active = props?.row?.original?.is_active;
      return (
        <DefaultCell
          cellStyle={
            active || disabled ? null : { color: colors.disabled.main }
          }
          value={value}
        />
      );
    }
  };
};

export const updateFormKeywords = (label, keywords) => {
  if (!label) {
    return;
  }
  if (!keywords) {
    return label;
  }
  const { coach, action_plan, coachee, coaching, engagement } = keywords;

  // using this logic so we can differentiate coach and coachee
  if (
    label.split(" ").some((x) => {
      return (
        x === "Coach" ||
        x === "coach" ||
        x === "Coach(es)" ||
        x === "(Coach)" ||
        x === "(Coach"
      );
    })
  ) {
    label = label.replace(/Coach/, coach);
  }
  //label = label.replace(/Coach/gi, coach);
  label = label.replace(/Action Plan/gi, action_plan);
  label = label.replace(/Coachee/gi, coachee);
  label = label.replace(/Coaching/gi, coaching);
  label = label.replace(/Engagement/gi, engagement);
  return label;
};
export const setCustomItemLabel = (propsLabel, fieldindex) => {
  if (propsLabel?.includes("/")) {
    const index = propsLabel.indexOf("/");
    const updatedLabel =
      propsLabel.slice(0, index) + `${fieldindex}` + propsLabel.slice(index);
    return updatedLabel;
  } else if (
    propsLabel?.includes("category") ||
    propsLabel?.includes("Object")
  ) {
    const updatedLabel = propsLabel + ` ${fieldindex}`;
    return updatedLabel;
  } else {
    return propsLabel;
  }
};
export const setCustomLabel = (propLabel, keywords) => {
  let label = updateFormKeywords(propLabel, keywords);
  let customizeLabel;

  // updating label as per keyword name

  let aesterik;
  if (label?.includes("*")) {
    let splittedLabel = label.split(" ");
    aesterik = splittedLabel.pop();

    customizeLabel = splittedLabel.join(" ");

    const updatedLabel = (
      <>
        {customizeLabel}{" "}
        <MDTypography variant="subtitle" color="error">
          {aesterik}
        </MDTypography>
      </>
    );

    return updatedLabel;
  }
  return label;
};

export const hideLoaderAndSnackbar = (dispatch) => {
  dispatch(stopLoading());

  setTimeout(() => {
    dispatch(hideSnackbar());
  }, 10000);
};
export const page_type_for_notifications = (pageType, keywords, subTitle) => {
  const { coach, action_plan, coachee, coaching, engagement } = keywords;

  return pageType === "Coach"
    ? coach
    : pageType === "Coachee"
    ? coachee
    : pageType === "Upcoming Sessions"
    ? "Session"
    : subTitle === "Create Session"
    ? "Session"
    : pageType === "Manage Groups"
    ? "Group"
    : pageType === "Coaching Program"
    ? `${coaching} Program`
    : pageType === "Engagements"
    ? `${engagement}`
    : pageType === "Action Plan Template"
    ? `${action_plan} Template`
    : pageType === "Action Plan"
    ? action_plan
    : pageType;
};

export const removeNullOrUndefined = (arrayList) => {
  let filteredList = arrayList.filter((x) => x !== null);
  return filteredList;
};

export const logoutAfterTokenExpiry = (dispatch, navigate, pathname) => {
  return async (error) => {
    const tokenExpiry = store?.getState()?.auth?.expiry;

    if (tokenExpiry && checkifTokenExpired(tokenExpiry)) {
      dispatch(
        isError({
          title: "Session timed out.",
          content: "Please log in again."
        })
      );
      dispatch(logOut());
      navigate("/sign-in", { state: { from: { pathname } }, replace: true });
    }

    return Promise.reject(error);
  };
};

export const persistFormData = (data) => {
  localStorage.setItem("formData", JSON.stringify(data));
};

export const debounce = (cb, delay = 2000) => {
  let timeout;
  return (args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(args);
    }, delay);
  };
};

export const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};
