import * as Yup from "yup";
import programManagerForm from "./form";
import countryList from "country-telephone-data";

const {
  first_name,
  last_name,
  user_name,
  password,
  confirm_password,
  user_role,
  email_address,
  phone_number,
  customer_id,
} = programManagerForm;

Yup.addMethod(Yup.string, "phoneNumberFormat", function (errorMessage) {
  return this.test(`test-phone-number`, errorMessage, function (value) {
    const { path, createError } = this;

    let itemMatched = countryList.allCountries.some(
      (country) => value === `+${country.dialCode}`
    );

    return (
      (itemMatched && createError({ path, message: errorMessage })) || value
    );
  });
});

const validations = Yup.object().shape({
  [first_name.name]: Yup.string().required(first_name.errorMsg),
  [last_name.name]: Yup.string().required(last_name.errorMsg),
  [user_name.name]: Yup.string().required(user_name.errorMsg),
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .matches(
      "(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$",
      "Password must contain: 7+ characters, 1 upper case, 1 lower case, 1 numeric value and 1 special character with no space"
    ),
  [confirm_password.name]: Yup.string()
    .required(confirm_password.errorMsg)
    .matches(
      "(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$",
      "Password must contain: 7+ characters, 1 upper case, 1 lower case, 1 numeric value and 1 special character with no space"
    )
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        confirm_password.invalidMsg
      ),
    }),
  [phone_number.name]: Yup.string().phoneNumberFormat(phone_number.errorMsg),
  [user_role.name]: Yup.string().required(user_role.errorMsg),
  [email_address.name]: Yup.string()
    .required(email_address.errorMsg)
    .email(email_address.invalidMsg),
  [customer_id.name]: Yup.string().required(customer_id.errorMsg).nullable(),
});

export default validations;
