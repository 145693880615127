import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";

const CenteredLoader = ({ size = 70 }) => {
  return (
    <MDBox
      height={"calc(100vh - 84px)"}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={size} />
    </MDBox>
  );
};

export default CenteredLoader;
