import { createSlice } from "@reduxjs/toolkit";

import jwtDecode from "jwt-decode";
import moment from "moment";

export const authSlice = createSlice({
  name: "authenticate",
  initialState: {
    token: null,
    user_name: "",
    expiry: "",
    user_role: "",
    customer_id: "",
    logo: "",
    refreshToken: null,
    user_logo: "",
    user_id: null
  },
  reducers: {
    logIn: (state, { payload }) => {
      let decodedToken = jwtDecode(payload.token);
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = payload.token;
      state.user_name = payload.user_name;
      state.expiry = moment(decodedToken.exp * 1000).format();
      // state.expiry = moment().add(15, "minutes").format();
      state.user_role = payload.user_role;
      state.user_id = decodedToken.userid;
      state.customer_id = decodedToken.customerid;
      state.logo = decodedToken.Logo;
      state.refreshToken = payload.refresh_token;
      state.user_logo = decodedToken.user_logo;
    },
    logOut: (state) => {
      state.token = null;
      state.user_name = null;
      state.expiry = null;
      state.user_role = null;
      state.customer_id = null;
      state.user_logo = null;
      state.refreshToken = null;
      state.logo = null;
      state.user_id = null
    },
    setProfilePhoto: (state, { payload }) => {
      state.user_logo = payload;
    },
    refreshAuthToken: (state, { payload }) => {
      let decodedToken = jwtDecode(payload.token);
      state.token = payload.token;
      state.expiry = moment(decodedToken.exp * 1000).format();
    },
    resetRefreshToken: (state) => {
      state.refreshToken = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  logIn,
  logOut,
  refreshAuthToken,
  resetRefreshToken,
  setProfilePhoto,
} = authSlice.actions;

export default authSlice.reducer;
