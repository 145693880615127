// #region Imports
import { useLayoutEffect, useState } from "react";
import "assets/theme/CustomCss/verifyInput.css";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material UI icons
import CheckCircle from "@mui/icons-material/CheckCircle";

// Authentication layout components
import BasicLayout from "./BasicLayout.jsx";

// custom components
import ButtonSpinner from "components/custom-components/LoadingButton.jsx";

// util imports
import { auth } from "utils/apiMethods.js";
import { capitalizeFirstLetters } from "utils/helper-method.js";

import ReactInputVerificationCode from "react-input-verification-code";

import { Backdrop, CircularProgress } from "@mui/material";
import MDSnackbar from "components/MDSnackbar/index.js";
// #endregion

function VerifyCode() {
  const [isLoading, setIsLoading] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [VerifyCodeError, setVerifyCodeError] = useState({
    isError: false,
    message: "",
  });
  const [snackbar, setSnackbar] = useState({
    show: false,
    title: "",
    content: "",
    type: "",
    icon: "",
  });
  const [value, setValue] = useState("");

  const navigate = useNavigate();
  const toggleSnackbar = () =>
    setSnackbar({ ...snackbar, show: !snackbar.show }); // toggle "show" property of snackbar on x button click
  const resendCode = async () => {
    setValue("");
    setBackDrop(true); // set isLoading to true to show loading icon
    let body = { email: localStorage.getItem("forgotPassword-email") };
    try {
      let result = await auth.forgotPassword(body);
      if (result && result.data && result.data.id) {
        setBackDrop(false);
        setSnackbar({
          ...snackbar,
          show: true,
          type: "info",
          title: "Success",
          content: `Code Sent Successfully`,
          icon: "check_circle",
        });
        hideLoaderAndErrors();
      }
    } catch (err) {
      setBackDrop(false);
      hideLoaderAndErrors();
    } finally {
      hideLoaderAndErrors();
    }
  };

  const handleSubmit = async () => {
    if (value && value.length === 6) {
      try {
        setIsLoading(true);
        let body = {
          email: localStorage.getItem("forgotPassword-email"),
          token: value,
        };
        let result = await auth.verifyToken(body);
        if (result?.statusText === "OK") {
          setIsLoading(false);
          localStorage.setItem("resetPassword-token", value);
          navigate("/reset-password");
        }
      } catch (err) {
        setVerifyCodeError({
          ...VerifyCodeError,
          isError: true,
          message: err.response
            ? err.response.data
              ? err.response.data.title
              : "Error"
            : null,
        }); // show sign in error on catch
        hideLoaderAndErrors();
      }
    } else {
      if (value.length === 0) {
        setVerifyCodeError({
          ...VerifyCodeError,
          isError: true,
          message: "Code is required",
        });
      } else {
        setVerifyCodeError({
          ...VerifyCodeError,
          isError: true,
          message: "Incomplete Code",
        });
      }
    }
  };

  // function to hide/reset error messages
  const hideLoaderAndErrors = () => {
    setIsLoading(false); // set isLoading to false to hide loading icon
    setTimeout(() => {
      setVerifyCodeError({ ...VerifyCodeError, isError: false, message: "" });
      setSnackbar({
        ...snackbar,
        show: false,
        title: "",
        content: "",
        type: "",
        icon: "",
      });
    }, 3000);
  };

  // useLayoutEffect is being used to apply styling to input fields
  useLayoutEffect(() => {
    let elements = document.querySelectorAll(
      ".ReactInputVerificationCode__item"
    );

    elements.forEach((box) => {
      box.style.color = "white";
    });
  }, []);

  return (
    <BasicLayout>
      <MDBox mx={2} mt={-3} p={2} mb={6} textAlign="center">
        {/* Heading */}
        <MDTypography
          variant="h1"
          color="white"
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ fontWeight: "400" }}
        >
          <span style={{ marginLeft: 5 }}>Verification Code</span>
        </MDTypography>
        <MDTypography
          variant="subtitle1"
          fontWeight="light"
          color="white"
          mt={1}
          textAlign="center"
          sx={{ maxWidth: 310, margin: "auto", marginTop: 2 }}
        >
          Please enter your verification code recieved on your email
        </MDTypography>
      </MDBox>

      {/* Form */}
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          {/* user_name Section*/}
          <MDBox mb={4} display="flex" justifyContent="center">
            <div className="custom-styles">
              <ReactInputVerificationCode
                placeholder=""
                onChange={setValue}
                value={value}
                length={6}
              />
            </div>
            {/* <OtpInput onChange={setValue} value={value} numInputs={6} containerStyle={{width:"2rem"}}/> */}
          </MDBox>

          {/* Remember Me & Forgot Password Section */}
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            ml={-1}
          >
            {/* Forgot Password */}
            <MDButton
              variant="text"
              color="white"
              fontWeight="medium"
              id="btn-forgotPassword"
              sx={{ paddingRight: 0 }}
              onClick={resendCode}
            >
              <MDTypography
                variant="subtitle1"
                fontWeight="light"
                color="white"
                style={{textDecoration: "underline" , textDecorationStyle: "dashed" ,  textUnderlinePosition: "under", fontWeight: "400"}}
              >
                Resend Code
              </MDTypography>
            </MDButton>
          </MDBox>

          {/* Sign-In Button Section */}
          <MDBox
            mt={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonSpinner
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              text="Confirm"
              Icon={<CheckCircle color="white" />}
            />
          </MDBox>

          {/* Error Section */}
          {VerifyCodeError.isError && (
            <>
              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  fontWeight="medium"
                >
                  {capitalizeFirstLetters(VerifyCodeError.message.split(" "))}
                </MDTypography>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
      {backDrop && (
        <Backdrop open={true}>
          <CircularProgress color="secondary" size={100} />
        </Backdrop>
      )}
      {/* snack bar */}
      {snackbar?.show && (
        <MDSnackbar
          color={snackbar.type}
          icon={snackbar.icon}
          title={snackbar.title}
          content={snackbar.content}
          open={snackbar.show}
          close={toggleSnackbar}
        />
      )}
    </BasicLayout>
  );
}

export default VerifyCode;
