import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorMessages } from "utils/constants";
import { updateFormKeywords, checkifTokenExpired } from "utils/helper-method";
export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    isOpen: false,
    type: "",
    title: "",
    content: "",
    icon: "",
    time: 10000,
  },
  reducers: {
    hideSnackbar: (state) => {
      state.isOpen = false;
      state.type = "";
      state.title = "";
      state.content = "";
      state.icon = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(isSnackBar.fulfilled, (state, { payload }) => {
      const { isOpen, type, title, content, icon, expiry } = payload;
      if (expiry && checkifTokenExpired(expiry)) {
        state.isOpen = isOpen;
        state.type = type;
        state.title = title;
        state.content = content;
        state.icon = icon;
      }
    });

    builder.addCase(isSuccess.fulfilled, (state, { payload }) => {
      const { content, expiry } = payload;

      if (expiry && !checkifTokenExpired(expiry)) {
        state.isOpen = true;
        state.type = "info";
        state.title = "Success";
        state.content = content;
        state.icon = "check_circle";
      }
    });

    builder.addCase(isError.fulfilled, (state, { payload }) => {
      const { content, title, expiry } = payload;

      if (
        (expiry && !checkifTokenExpired(expiry)) ||
        title === ErrorMessages.SESSION_TIMEDOUT
      ) {
        state.isOpen = true;
        state.type = "error";
        state.title = title;
        state.content = content;
        state.icon = "error";
      }
    });
  },
});

//  customize snackbar
export const isSnackBar = createAsyncThunk(
  "snackbarSlice/isSnackBar",
  async (payload, { getState }) => {
    const { keywords } = getState().keywords;
    const { expiry } = getState().auth;
    const { title, content } = payload;

    let updatedPayload = {
      ...payload,
      expiry,
      title: updateFormKeywords(title, keywords),
      content: updateFormKeywords(content, keywords),
    };
    return updatedPayload;
  }
);

//  success snackbar
export const isSuccess = createAsyncThunk(
  "snackbarSlice/isSuccess",
  async (payload, { getState }) => {
    const { keywords } = getState().keywords;
    const { expiry } = getState().auth;

    const { content } = payload;
    let updatedPayload = {
      expiry,
      content: updateFormKeywords(content, keywords),
    };
    return updatedPayload;
  }
);

// error snackbar
export const isError = createAsyncThunk(
  "snackbarSlice/isError",
  async (payload, { getState }) => {
    const { keywords } = getState().keywords;
    const { expiry } = getState().auth;

    const { title, content } = payload;

    // extracting the message from content object
    let contentData =
      (content.response?.data?.error_message &&
        content.response?.data?.error_message[0]) ||
      content.response?.data?.title ||
      (typeof content === "string" && content) ||
      "Error";

    // updating the keywords in error msgs and title
    let updatedPayload = {
      expiry,
      content: updateFormKeywords(contentData, keywords),
      title: updateFormKeywords(title, keywords),
    };
    return updatedPayload;
  }
);

export const { hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
