/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";
import bgSideNav from "./../../../../assets/images/g18.svg";
export default styled(Drawer)(({ theme, ownerState }) => {
  const { boxShadows, transitions, breakpoints, functions, palette } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 300;
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { xxl } = boxShadows;
  const { pxToRem } = functions;
  const isCoachee = useIsThisUser(all_roles.coachee);
  let backgroundValue = palette.background.primary;
  let backgroundValue1 = "#0DEF85";
  // let backgroundValue2 = "#000";
  let backgroundValue2 = bgSideNav;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    backgroundImage: isCoachee ? `url(${backgroundValue2})` : backgroundValue1,
    transform: "translateX(0)",
    zIndex: 0,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),
    backgroundPosition: "center",
    backgroundAttachment: "fixed",

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen
      })
    }
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    // zIndex: 0,
    background: isCoachee ? "#E7E7E7" : backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    // transition: transitions.create("transform", {
    //   easing: transitions.easing.sharp,
    //   duration: transitions.duration.shorter,
    // }),

    boxShadow: transparentSidenav ? "none" : xxl,
    marginBottom: transparentSidenav ? 0 : "inherit",
    left: "0",
    width: pxToRem(96),
    overflowX: "hidden",
    transform: "translateX(0)",
    transition: transitions.create(["width", "background-color"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter
      })
    }
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      "::-webkit-scrollbar": {
        width: "8px",
        height: "8px"
      },

      "::-webkit-scrollbar-track": {
        background: "#e6e6e6",
        borderRadius: "10px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#B3B3B3",
        borderRadius: "10px"
      },
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles())
    }
  };
});
