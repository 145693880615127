  import { Icon } from "@iconify/react";
import { CustomTooltip } from "./custom-tooltip";

export const MenuItemIcon = ({ icon, width = 28, height = 28,style=null }) => {
  return <Icon icon={icon} width={width} height={height} style={style} />;
};

export const SideNavIcon = ({ icon, width = 23, height = 23 }) => {
  return <Icon icon={icon} width={width} height={height} />;
};

export const ButtonIcon = ({
  icon,
  width = 20,
  height = 20,
  iconStyle = null,
  onClick = null
}) => {
  return (
    <Icon
      icon={icon}
      width={width}
      height={height}
      style={iconStyle}
      onClick={onClick}
    />
  );
};

export const RegularIcon = ({
  icon,
  width = 36,
  height = 36,
  iconStyle = null
}) => {
  return <Icon icon={icon} width={width} height={height} style={iconStyle} />;
};
export const IconButton = ({
  icon,
  width = 36,
  height = 36,
  iconStyle = null,
  clickAction = null,
  title = null,
  placement = null
}) => {
  return (
    <CustomTooltip title={title} placement={placement || "right"}>
      <Icon
        icon={icon}
        width={width}
        height={height}
        style={iconStyle}
        onClick={clickAction}
      />
    </CustomTooltip>
  );
};
