import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "components/Items/NotificationItem/styles";
import colors from "assets/theme/base/colors";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";

const NotificationItem = forwardRef(({ icon, title, ...rest }, ref) => {
  const isCoachee = useIsThisUser(all_roles.coachee);
  return (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <MDBox
        component={Link}
        py={0.5}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={0.75}
          sx={{
            color: isCoachee
              ? colors.coacheePortal.primary
              : colors.primary.main,
          }}
        >
          {icon}
        </MDTypography>
        <MDTypography
          variant="button"
          fontWeight="regular"
          // color="primary"
          sx={{
            ml: 1,
            color: isCoachee
              ? colors.coacheePortal.primary
              : colors.primary.main,
          }}
        >
          {title}
        </MDTypography>
      </MDBox>
    </MenuItem>
  );
});

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;
