import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";
import { SelectAllItemsText } from "utils/constants";
import { user_roles } from "utils/constants";
import { decodeToken } from "utils/helper-method";

const initialState = {
  customers: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
  },
  isCustomerOrganizationsLoading: false,
  error: null,
  customerDropdownBefore: [],
  selectedCustomerBefore: null,
  customerDropdown: [],
  customerDropdownForForms: [],
  customerDropDownForFilter: [],
  selectedCustomer: {},
  selectedRole: undefined,
  selectedUserRole: undefined,
  selectedCustomerForForms: undefined,
  allItemsChecked: false,
  isAnyItemChecked: true,
};
export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    resetCustomerSlice: (state) => {
      state = initialState;
    },
    setSelectedRole: (state, { payload }) => {
      state.selectedRole = user_roles[payload];
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    setClearCustomerDropDownForForm: (state) => {
      state.selectedCustomerForForms = undefined;
      state.customerDropDownForFilter = [];
      state.customerDropdownForForms = [];
      state.selectedRole = undefined;
      //state.selectedCustomer = {};
    },
    setClearCustomerDropdown: (state) => {
      state.selectedCustomer = {};
    },
    setSelectedCustomer: (state, { payload: index }) => {
      if (
        state.customerDropdown.length &&
        index !== null &&
        index !== undefined &&
        index !== -1
      ) {
        state.selectedCustomer = { ...state.customerDropdown[index], index };
      } else if (typeof index === "object") {
        state.selectedCustomer = { ...index, index: 0 };
        state.selectedCustomerBefore = [{ ...index }];
        state.customerDropDownForFilter = [{ ...index }];
      } else if (index === null) {
        state.selectedCustomer = null;
        state.selectedCustomerBefore = null;
        state.customerDropDownForFilter = null;
      }
    },
    setSelectedUserRole: (state, { payload: index }) => {
      state.selectedUserRole = index;
    },
    setSelectedCustomerAdminControl: (state, { payload: index }) => {
      if (state.customerDropdownBefore)
        state.selectedCustomerBefore = state.customerDropdownBefore[index];
    },
    setSelectedCustomerForForms: (state, { payload: index }) => {
      // if index is a string value, i.e. customer_id, then we will find the index of the customer whose ID matches with provided customer
      if (typeof index === "string") {
        let customer_id = index;
        if (state.customerDropdownBefore) {
          let matchedCustomer = state.customerDropdownBefore.find(
            (item) => item.customer_id === customer_id
          );
          let matchedIndex =
            state.customerDropdownBefore.indexOf(matchedCustomer);
          state.selectedCustomerForForms =
            state.customerDropdownBefore[matchedIndex];
        }
      } else {
        if (index === null) {
          state.selectedCustomerForForms = null;
        }
        if (state.customerDropdownBefore) {
          state.selectedCustomerForForms = state.customerDropdownBefore[index];
        }
      }
    },
    setAllItemsChecked: (state, { payload: allItemsChecked }) => {
      state.allItemsChecked = allItemsChecked;
      state.isAnyItemChecked = allItemsChecked;

      state.customers.data.map((item) => {
        item.isChecked = allItemsChecked;
        return item;
      });
    },
    setItemChecked: (state, { payload: id }) => {
      let matchedItem = state.customers.data.find(
        (item) => item.customer_id === id
      );
      matchedItem.isChecked = !matchedItem.isChecked;

      // check if any item is checked
      if (state.customers.data.some((item) => item.isChecked === true)) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },
    resetCustomerDropdown: (state) => {
      state.customerDropdown = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerOrganizationsForDropdown.pending, (state) => {
      state.isCustomerOrganizationsLoading = true;
    });
    builder.addCase(
      getCustomerOrganizationsForDropdown.fulfilled,
      (state, { payload }) => {
        state.isCustomerOrganizationsLoading = false;
        if (payload?.data) {
          let filteredArray = payload.data.filter(
            (item) => item.customer_id !== null
          );

          state.customerDropDownForFilter = filteredArray;
          // state.selectedCustomerForForms = filteredArray[0];
          state.customerDropdownBefore = filteredArray;
          state.selectedCustomerBefore = null;
          payload.data.unshift({
            customer_id: null,
            customer_name: SelectAllItemsText.ALL_CUSTOMER_ORGANIZATIONS,
          });

          state.customerDropdown = payload.data;
          if (!state.selectedCustomer?.customer_id) {
            state.selectedCustomer = { ...state.customerDropdown[0], index: 0 };
          }
        } else {
          state.customerDropDownForFilter = [];
        }
      }
    );
    builder.addCase(
      getCustomerOrganizationsForDropdown.rejected,
      (state, action) => {
        state.isCustomerOrganizationsLoading = false;
        // state.error = error;
      }
    );
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      if (payload) {
        payload.data.map((item) => {
          item.isChecked = false;
          return item;
        });

        state.customers = payload;
        state.allItemsChecked = false;
        state.isAnyItemChecked = false;
      } else {
        state.customers = [];
      }
    });
    builder.addCase(
      getCustomerOrganizationsForForms.fulfilled,
      (state, { payload }) => {
        if (payload.data) {
          let filteredArray = payload.data.filter((item) => {
            return item.customer_id !== null;
          });
          state.customerDropdownForForms = filteredArray;
        } else {
          state.customerDropdownForForms = [];
        }
      }
    );
    builder.addCase(
      getCustomerOrganizationInDropDown.fulfilled,
      (state, { payload }) => {
        if (payload.data) {
          let filteredArray = payload.data.filter(
            (item) => item.customer_id !== null
          );
          state.customerDropDownForFilter = filteredArray;
          state.customerDropdown = payload.data;
          state.selectedCustomer = { ...state.customerDropdown[0], index: 0 };
          state.customerDropdownBefore = state.customerDropdown;
        } else {
          state.customerDropDownForFilter = [];
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function

// thunks are used to making asynchronous api calls
export const getCustomerOrganizationsForDropdown = createAsyncThunk(
  "customers/getCustomerOrganizationsForDropdown",
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboard.getCustomerOrganizationsForDropdown();
      return response?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.statusText || "Error");
    }
  }
);

export const getCustomerOrganizationInDropDown = createAsyncThunk(
  "customers/getCustomerOrganizationsInDropdown",
  async (payload, { getState }) => {
    const customer_id = decodeToken(getState().auth.token).customerid;
    const { data } = await dashboard.getCustomerById(customer_id);

    // manipulating CAResponse in order to get resemblence of response of below api
    const response = {
      data: [data],
    };
    return response;
  }
);

// creating another customer organization request to render data independently on forms
export const getCustomerOrganizationsForForms = createAsyncThunk(
  "customers/getCustomerOrganizationsForForms",
  async (payload, { getState }) => {
    const tokenDetails = decodeToken(getState().auth.token);
    // api hit if customer admin is login
    if (tokenDetails.role === "Customer Admin") {
      return { data: getState().customers.customerDropDownForFilter };
    }

    const response = await dashboard.getCustomerOrganizationsForDropdown();
    return response?.data;
  }
);

export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async () => {
    const response = await dashboard.getCustomers();
    return response?.data;
  }
);

// Action creators are generated for each case reducer function
export const {
  setSelectedCustomer,
  setSelectedCustomerAdminControl,
  setSelectedCustomerForForms,
  setAllItemsChecked,
  setItemChecked,
  setSelectedRole,
  setClearCustomerDropDownForForm,
  setClearCustomerDropdown,
  setSelectedUser,
  setSelectedCustomerInLocalStorage,
  resetCustomerSlice,
  setSelectedUserRole,
  resetCustomerDropdown,
} = customersSlice.actions;

export default customersSlice.reducer;
