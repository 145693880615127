import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";
const initialState = {
  data: { currentPage: 1, data: [], pageSize: 5, totalItem: 5, totalPage: 1 },
  allItemsChecked: false,
  isAnyItemChecked: true,
  checkedItems: [],
};
export const viewSessionListSlice = createSlice({
  name: "viewSessionList",
  initialState,
  reducers: {
    resetDataTable: (state) => {
      state = initialState;
    },
    setAllItemChecked: (state, { payload: allItemsChecked }) => {
      state.allItemsChecked = allItemsChecked;
      state.isAnyItemChecked = allItemsChecked;
      if (allItemsChecked) {
        state.checkedItems = state.data.data;
      } else {
        state.checkedItems = [];
      }
    },
    setItemChecked: (state, { payload: id }) => {
      let matchedItem = state.data.data.find(
        (item) => (item.id || item.customer_id) === id
      );
      matchedItem.isChecked = !matchedItem?.isChecked;
      // adding and removing data from checked item array
      if (matchedItem.isChecked) {
        state.checkedItems = [...state.checkedItems, matchedItem];
      } else {
        state.checkedItems = state.checkedItems.filter((item) => {
          return (
            item.id !== matchedItem.id ||
            item.customer_id !== matchedItem.customer_id
          );
        });
      }
      //checked if any item is checked
      if (state.data.data.some((item) => item.isChecked === true)) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },
    setClearCheckedItem: (state) => {
      state.checkedItems = [];
      state.isAnyItemChecked = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getViewSessionList.fulfilled, (state, { payload }) => {
      //let manipulatedData = { ...payload.meta.page_size, data: payload.data[0].session_list };

      //   let sessionData = manipulatedData.data.map(item => {
      //     return item
      //   })

      setStateData(state, payload);
    });
  },
});
const setStateData = (state, payload) => {
  if (payload.data) {
    payload.data.map((item) => {
      item.isChecked = false;
      return item;
    });
    state.allItemsChecked = false;
    state.isAnyItemChecked = false;
    state.data = payload;
  } else {
    state.data = [];
  }
  return state;
};
//get view session list history
export const getViewSessionList = createAsyncThunk(
  "engagementConsole/getViewSessionList",
  async ({ current_page, page_size, customer_id = null }) => {
    const response = await dashboard.getSessionsHistory(
      current_page,
      page_size,
      customer_id
    );
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setAllItemChecked,
  setClearCheckedItem,
  setItemChecked,
  resetDataTable,
} = viewSessionListSlice.actions;
export default viewSessionListSlice.reducer;
