import { Suspense, useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import SideNavLayout from "../LayoutContainers/sideNav-layout";
import DashboardNavbar from "../Navs/DashboardNavbar";
import CenteredLoader from "components/custom-components/Centered-Loader";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname, dispatch]);

  return (
    <>
      <DashboardNavbar />
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          zIndex: 1,
          // marginTop: pxToRem(50),
          marginRight: pxToRem(10),
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(140) : pxToRem(335),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
          [breakpoints.up("md")]: {
            marginLeft: miniSidenav ? pxToRem(90) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <SideNavLayout />

        <Suspense fallback={<CenteredLoader size={100} />}>{children}</Suspense>
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  // children: PropTypes.node.isRequired,
};

export default DashboardLayout;
