import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "custom-hooks/useAuth";
import DashboardLayout from "layouts/common/LayoutContainers/dashboard-layout";

function ProtectedRoute() {
  const isAuthenticated = useAuth();
  const location = useLocation();

  // Redirect them to the /sign-in page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return isAuthenticated ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/sign-in" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
