import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";

let defaultKeyWords = {
  coachee: "Coachee",
  coach: "Coach",
  action_plan: "Action Plan",
  coaching: "Coaching",
  engagement: "Engagement",
};

export const keyWordSlice = createSlice({
  name: "keywords",
  initialState: {
    keywords: defaultKeyWords,
    isKeywordsLoading: false,
  },
  reducers: {
    updateKeywords: (state, { payload }) => {
      state.keywords = payload;
    },
    resetKeywords: (state) => {
      state.keywords = defaultKeyWords;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKeywords.pending, (state) => {
      state.isKeywordsLoading = true;
    });
    builder.addCase(getKeywords.fulfilled, (state, { payload }) => {
      state.isKeywordsLoading = false;
      if (payload?.length) {
        let updatedKeywords = {};

        payload.map((item) => {
          const { WordKey, WordValue } = item;
          if (WordValue) {
            updatedKeywords[WordKey] = WordValue;
          } else {
            updatedKeywords[WordKey] = defaultKeyWords[WordKey];
          }
        });
        state.keywords = updatedKeywords;
      }
    });
    builder.addCase(getKeywords.rejected, (state, { error }) => {
      state.isKeywordsLoading = false;
    });
  },
});

export const getKeywords = createAsyncThunk(
  "keywords/getKeywords",
  async (_, { getState }) => {
    const { customer_id, user_role } = getState().auth;
    if (user_role !== "System Admin") {
      const response = await dashboard.getKeywords(customer_id);
      return response;
    }
    return;
  }
);

export const { updateKeywords, resetKeywords } = keyWordSlice.actions;
export default keyWordSlice.reducer;
