import useMediaQuery from "@mui/material/useMediaQuery";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PageLayout from "layouts/common/LayoutContainers/Auth-Layout.js";
import bgImage from "assets/images/bg-sign-in-basic4.png";
import CustomLogo from "components/custom-components/custom-logo";

import logo from "./../../assets/images/profile.jpg";

function BasicLayout({ children }) {
  const matchesLG = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { primary } = colors;

  let image = bgImage;
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          // backgroundImage: ({
          //   functions: { linearGradient, rgba },
          //   palette: { gradients },
          // }) =>
          //   image &&
          //   `${linearGradient(
          //     rgba(gradients.dark.main, 0.6),
          //     rgba(gradients.dark.state, 0.6)
          //   )}, url(${image})`,
          backgroundImage: `url(${image})`,
          backgroundSize: "55%",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: primary.main,
        }}
        // bgColor="primary"
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto" mt={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignContent="center"
          alignItems="center"
          flexDirection="row-reverse"
          height="100%"
        >
          <Grid item xs={12} sm={10} md={6} lg={6} xl={5} sx={{ zIndex: 1 }}>
            <MDBox mx={2} mt={-10} p={2} mb={1} textAlign="center">
              <MDTypography
                variant="h1"
                fontWeight="regular"
                fontSize={matchesLG ? 80 : matchesMD ? 64 : 48}
                color="white"
              >
                RungJumper
              </MDTypography>
              {/* <MDBox>
                <CustomLogo src={logo} />
              </MDBox> */}
              <MDTypography
                sx={{
                  fontSize: matchesLG ? "24px" : matchesMD ? "20px" : "18px",
                }}
                // variant={matchesMD ? "h4" : "h5"}
                fontWeight="regular"
                color="white"
                mt={1}
              >
                Online Coaching Solution
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            mt={-8}
            item
            xs={12}
            sm={10}
            md={6}
            lg={5}
            xl={4}
            sx={{ zIndex: 1 }}
          >
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
