// Menu.js

import React from "react";
import boxShadows from "assets/theme/base/boxShadows";
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";
import { all_roles } from "utils/constants";
import { useIsThisUser } from "custom-hooks/useCurrentUser";

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

const Menu = () => {
  const isCoachee = useIsThisUser(all_roles.coachee);

  const menu = {
    defaultProps: {
      //disableAutoFocusItem: true,
    },

    styleOverrides: {
      paper: {
        minWidth: pxToRem(160),
        maxHeight: `${pxToRem(180)} !important`,
        boxShadow: lg,
        padding: `${pxToRem(16)} ${pxToRem(8)}`,
        fontSize: size.sm,
        color: text.main,
        textAlign: "left",
        backgroundColor: `${white.main} !important`,
        borderRadius: borderRadius.md,
        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "::-webkit-scrollbar-track": {
          background: "#e6e6e6",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#B3B3B3",
          borderRadius: "10px",
        },
        "& .css-1bemhkb-MuiList-root-MuiMenu-list": {
          maxHeight: !isCoachee ? `${pxToRem(180)} !important` : undefined, // Conditionally set maxHeight
        },
      },
    },
  };
};

export default Menu;
