import moment from "moment";
import * as Yup from "yup";
import form from "./form";

const {
  session_type,
  session_status,
  session_name,
  engagement_id,
  customer_id,
  session_date,
  start_time,
  end_time,
  time_zone,
  video_sessions
  // agenda_body
} = form;

const date_time = new Date();
const sessionValidations = Yup.object().shape({
  [customer_id.name]: Yup.string().required(customer_id.errorMsg),
  [session_name.name]: Yup.string().required(session_name.errorMsg),
  [time_zone.name]: Yup.string().required(time_zone.errorMsg),
  [session_type.name]: Yup.string().required(session_type.errorMsg),
  [session_status.name]: Yup.string().required(session_status.errorMsg),
  [engagement_id.name]: Yup.string().required(engagement_id.errorMsg),
  [session_date.name]: Yup.date(session_date.notTypeMsg).required(
    session_date.errorMsg
  ),
  // [agenda_body.name]: Yup.string().required(agenda_body.errorMsg),

  [start_time.name]: Yup.date()
    .required(start_time.errorMsg)
    .typeError(start_time.errorMsg),
  [end_time.name]: Yup.date()
    .min(Yup.ref(`${start_time.name}`), "Session can not end before start time")
    .required(end_time.errorMsg)
    .typeError(end_time.errorMsg)
});
export default sessionValidations;
