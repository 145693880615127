const coachingProgramForm = {
  customer_id: {
    name: "customer_id",
    label: "Customer Organization *",
    type: "select",
    options: [],
    errorMsg: "Customer Organization is required.",
  },
  coaching_program_name: {
    name: "coaching_program_name",
    label: "Coaching Program Name *",
    type: "text",
    errorMsg: "Coaching Program is required.",
  },
  coaching_program_description: {
    name: "coaching_program_description",
    label: "Description",
    type: "textarea",
  },
  action_plan_templates_id: {
    name: "action_plan_templates_id",
    label: "Action Plan Template *",
    type: "multicheck",
    options: [],
    errorMsg: "Action Plan Template is required.",
  },
  program_managers_id: {
    name: "program_managers_id",
    label: "Program Manager *",
    type: "multicheck",
    options: [],
    errorMsg: "Program Manager is required.",
  },
  coaches_id: {
    name: "coaches_id",
    label: "Coach *",
    type: "multicheck",
    options: [],
    errorMsg: "Coach is required.",
  },
};

export default coachingProgramForm;
