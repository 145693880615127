import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";
import SignIn from "layouts/authentication/sign-in";
import VerifyCode from "layouts/authentication/verify-code";
import sideNavRoutes from "utils/sideNav-routes";

const useRoutes = () => {
  return {
    publicRoutes: [
      {
        name: "Sign In",
        route: "sign-in",
        component: <SignIn />,
        key: "sign-in",
      },
      {
        name: "Forgot Password",
        route: "forgot-password",
        component: <ForgotPassword />,
        key: "forgot-password",
      },
      {
        name: "Verify Code",
        route: "verify-code",
        component: <VerifyCode />,
        key: "verify-code",
      },
      {
        name: "Reset Password",
        route: "reset-password",
        component: <ResetPassword />,
        key: "reset-password",
      },
    ],

    protectedRoutes: sideNavRoutes(),
  };
};
export default useRoutes;
