import { createContext, useContext, useMemo, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "DIALOG_BOX": {
      let {
        open,
        title,
        body,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnAction,
        primaryBtnColor,
        secondaryBtnAction,
        primaryAction,
        secondaryAction,
        cancelText,
        showCancelButton,
        isForm,
        justifyContent,
        isPrimaryLoading,
        cacnelBtnColor,
        btnWidth,
        isResourceBox,
        isDashboardPreview
      } = action.value;
      return {
        ...state,
        dialogBox: {
          ...state.dialogBox,
          open,
          title,
          body,
          primaryBtnText,
          primaryBtnAction,
          primaryBtnColor,
          secondaryBtnText,
          secondaryBtnAction,
          primaryAction,
          secondaryAction,
          cancelText,
          showCancelButton,
          isForm,
          justifyContent,
          isPrimaryLoading,
          cacnelBtnColor,
          btnWidth,
          isResourceBox,
          isDashboardPreview
        }
      };
    }
    case "SNACK_BAR": {
      let { show, title, type, content, icon } = action.value;
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          show,
          title,
          type,
          content,
          icon
        }
      };
    }
    case "TOGGLE_SNACK_BAR": {
      // let { show } = action.value;
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          show: !state.snackbar.show,
          title: "",
          type: "",
          content: "",
          icon: ""
        }
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    dialogBox: {
      open: false,
      title: "",
      body: "",
      primaryBtnText: "",
      primaryBtnColor: false,
      primaryAction: () => {},
      secondaryAction: null,
      cancelText: "",
      showCancelButton: true,
      isForm: false,
      isNoShowPopup: false,
      justifyContent: "space-between",
      isPrimaryLoading: true,
      cacnelBtnColor: false,
      btnWidth: "",
      isResourceBox: false,
      isDashboardPreview: false
    },
    snackbar: {
      show: false,
      type: "info",
      title: ``,
      content: "",
      icon: ""
    }
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setDialogBox = (dispatch, value) =>
  dispatch({ type: "DIALOG_BOX", value });
const setSnackbar = (dispatch, value) => dispatch({ type: "SNACK_BAR", value });
const toggleSnackbar = (dispatch, value) =>
  dispatch({ type: "TOGGLE_SNACK_BAR", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setDialogBox,
  setSnackbar,
  toggleSnackbar
};
