import customerForm, { primary_customer_contact } from "./form";

const {
  state,
  country,
  address,
  postal_code,
  organization_name,
  city,
  contacts,
} = customerForm;

const {
  first_name,
  last_name,
  position,
  email,
  direct_phone_number,
  phone_number,
} = contacts[0];

const initialValues = {
  [state.name]: "",
  [country.name]: "United States",
  [postal_code.name]: "",
  [address.name]: "",
  [city.name]: "",
  [organization_name.name]: "",
  contacts: [
    {
      [first_name.name]: "",
      [last_name.name]: "",
      [position.name]: "",
      [email.name]: "",
      [direct_phone_number.name]: "",
      [phone_number.name]: "",
    },
  ],
};

export default initialValues;
