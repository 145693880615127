// #region Imports
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";

// Material UI icons
import LockIcon from "@mui/icons-material/Lock";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "./BasicLayout.jsx";

// custom components
import ButtonSpinner from "components/custom-components/LoadingButton.jsx";

// util imports
import { auth } from "utils/apiMethods.js";
import { capitalizeFirstLetters } from "utils/helper-method.js";
import MDSnackbar from "components/MDSnackbar/index.js";

// #endregion

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [ResetPasswordError, setResetPasswordError] = useState({
    isError: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    show: false,
    title: "",
    content: "",
    type: "",
    icon: "",
  });
  const navigate = useNavigate();

  const formik = useFormik({
    // state values for user_name & password
    initialValues: {
      password: "",
      confirm_password: "",
    },
    // this onSubmit gets called when all fields are validated successfully, (after button click)
    onSubmit: async (values) => {
      setIsLoading(true); // set isLoading to true to show loading icon
      let body = {
        email: localStorage.getItem("forgotPassword-email"),
        password: values.password,
        token: localStorage.getItem("resetPassword-token"),
      }; // request body
      try {
        let result = await auth.resetPassword(body); // calling api
        if (result?.statusText === "OK") {
          setIsLoading(false);
          setSnackbar({
            ...snackbar,
            show: true,
            type: "info",
            title: "Success",
            content: `Password Updated Successfully`,
            icon: "check_circle",
          });
          setTimeout(() => {
            localStorage.removeItem("forgotPassword-email");
            localStorage.removeItem("resetPassword-token");
            navigate("/sign-in");
          }, 1000);
        }
      } catch (err) {
        setResetPasswordError({
          ...ResetPasswordError,
          isError: true,
          message:
            err.response?.data?.title ||
            err.response?.data?.error?.message ||
            "Error",
        }); // show error on catch
        hideLoaderAndErrors();
      }
    },
    // set up validations here
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .matches(
          "(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$",
          "Password must contain: 7+ characters, 1 upper case, 1 lower case, 1 numeric value and 1 special character with no space"
        ),
      confirm_password: Yup.string()
        .required("Confirm Password is required")
        .matches(
          "(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$",
          "Password must contain: 7+ characters, 1 upper case, 1 lower case, 1 numeric value and 1 special character with no space"
        )
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Confirm Password must be same as Password."
          ),
        }),
    }),
  });

  const { password, confirm_password } = formik.values;
  const toggleSnackbar = () =>
    setSnackbar({ ...snackbar, show: !snackbar.show }); // toggle "show" property of snackbar on x button click
  const hideLoaderAndErrors = () => {
    setIsLoading(false); // set isLoading to false to hide loading icon
    setTimeout(() => {
      setResetPasswordError({
        ...ResetPasswordError,
        isError: false,
        message: "",
      });
    }, 3000);
  };

  return (
    <BasicLayout>
      <MDBox mx={2} mt={-3} p={2} mb={6} textAlign="center">
        {/* Heading */}
        <MDTypography
          variant="h2"
          fontWeight="medium"
          color="white"
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <span style={{ marginLeft: 5 }}>Reset your password</span>
        </MDTypography>
        <MDTypography
          variant="body2"
          fontWeight="light"
          color="white"
          mt={1}
          textAlign="center"
        >
          Please enter your new password and confirm that password
        </MDTypography>
      </MDBox>

      {/* Form */}
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          {/* user_name Section*/}
          <MDBox mb={4}>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel htmlFor="user_name">Enter New Password *</InputLabel> */}
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={password}
                placeholder="Enter New Password *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon fontSize="medium" color="white" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      color="white"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formik.touched.password && formik.errors.password ? (
              <MDTypography variant="caption" color="error" fontWeight="bold">
                {formik.errors.password}
              </MDTypography>
            ) : null}
          </MDBox>

          {/* Password Section*/}
          <MDBox mb={4}>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel htmlFor="password">Confirm New Password *</InputLabel> */}
              <Input
                id="confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                label="Confirm Password"
                value={confirm_password}
                onChange={formik.handleChange}
                placeholder="Confirm New Password *"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon fontSize="medium" color="white" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      color="white"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <MDTypography variant="caption" color="error" fontWeight="bold">
                {formik.errors.confirm_password}
              </MDTypography>
            ) : null}
          </MDBox>

          {/* Sign-In Button Section */}
          <MDBox
            mt={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonSpinner
              isLoading={isLoading}
              handleSubmit={formik.handleSubmit}
              text="Change Password"
              Icon={<CheckCircle color="white" />}
            />
          </MDBox>

          {/* Error Section */}
          {ResetPasswordError.isError && (
            <>
              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  fontWeight="medium"
                >
                  {capitalizeFirstLetters(
                    ResetPasswordError.message.split(" ")
                  )}
                </MDTypography>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
      {/* snack bar */}
      {snackbar?.show && (
        <MDSnackbar
          color={snackbar.type}
          icon={snackbar.icon}
          title={snackbar.title}
          content={snackbar.content}
          open={snackbar.show}
          close={toggleSnackbar}
        />
      )}
    </BasicLayout>
  );
}

export default ResetPassword;
