import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import { styled } from "@mui/material/styles";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";

export const CustomTooltip = styled(
  ({ className, componentStyle, ...props }) => {
    return (
      <Tooltip {...props} classes={{ popper: className }} />
    );
  }
)(({ theme }) => {
  const isCoachee = useIsThisUser(all_roles.coachee);
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: colors.primary.main,
      backgroundColor: isCoachee ? "#80a55a" : colors.primary.main,
      color: colors.background.primary,
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: isCoachee ? "#80a55a" : colors.primary.main,
    },
  };
});
