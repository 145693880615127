/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, info , grey , primary} = colors;

const radio = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        width: pxToRem(20),
        height: pxToRem(20),
        // color: transparent.main,
        border: `${borderWidth[2]} solid ${borderColor}`,
        borderRadius: "100%",
        color: `${grey[600]} !important`,
      },
      "&:after": {
        transition: "opacity 250ms ease-in-out",
        position: "absolute",
        width: pxToRem(12),   
        height: pxToRem(12),
        borderRadius: "50%",
       backgroundImage: linearGradient(primary.main, primary.main),
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
       margin: "auto",
       content: `""`,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${primary.main} !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: primary.main,

        "& .MuiSvgIcon-root": {
          // borderColor: info.main,
          borderColor: primary.main,

        },

        "&:after": {
          opacity: 1,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "&.Mui-checked": {
        color: primary.main,
        "& .MuiSvgIcon-root": {
        borderColor: primary.main,
        },

        "&:after": {
          opacity: 1,
        },
      },
    },
  },
};

export default radio;
