// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "layouts/common/Navs/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";
export default function CustomSideNavCollapseItem({
    icon,
    name,
    children,
    active,
    noCollapse,
    open,
    ...rest
}){
    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
      controller;

      const isCoachee = useIsThisUser(all_roles.coachee);
  
    return(
      <>
      <MDBox display="flex" justifyContent="space-between" sx={{width: !miniSidenav ? "17rem !important": "auto"}}>
      <MDBox display="flex" sx={{alignItems :"center"}}>
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
                children,
                isCoachee
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active , children })}>
                {icon}
              </Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                children,
                active,
                isCoachee
              })
            }
          />
</MDBox>
          <Icon
            fontSize="large"
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                open,
                active,
                darkMode,
                isCoachee
              })
            }
          >
            arrow_drop_up_icon
          </Icon>
          </MDBox>
  </>
    )
}