import React from "react";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";

// to get the decode token value
export default function useCurrentUser() {
  const { token } = useSelector((state) => state.auth);
  const result = jwt_decode(token);
  return result;
}

export function useIsThisUser(user) {
  const { token } = useSelector((state) => state.auth);
  if (token) {
    const currentUser = jwt_decode(token);
    if (user === currentUser.role) {
      return true;
    }
  }

  return false;
}
