import moment from "moment";
import sessionForm from "./form";

const {
  session_name,
  session_type,
  session_status,
  engagement_id,
  customer_id,
  session_date,
  start_time,
  end_time,
  video_sessions,
  time_zone,
  agenda_body
} = sessionForm;

const sessionInitialValues = {
  [session_name.name]: "",
  [session_type.name]: "Individual",
  [session_status.name]: "Proposed",
  [engagement_id.name]: "",
  [customer_id.name]: "",
  [session_date.name]: moment().endOf("day"),
  [start_time.name]: "",
  [end_time.name]: "",
  [video_sessions.name]: true,
  [time_zone.name]: "",
  [agenda_body.name]: ""
};

export default sessionInitialValues;
