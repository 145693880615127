import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const exportCSV = (
  headers,
  rows,
  _,
  isFilters = false,
  extraHeaders,
  extraBody
) => {
  if (isFilters) {
    var csvBuilder = new CsvBuilder("Generated_Report.csv")
      .addRows([["Filters"], extraHeaders, extraBody])
      .addRows([[], headers])
      //as we want whole location not truncated so cant use this
      //.addRows(rowData.map((row) => headers.map((col) => row[col.accessor])))
      .addRows(rows)
      .exportFile();
    return;
  }
  var csvBuilder = new CsvBuilder("Generated_Report.csv")
    //as we want whole location not truncated so cant use this
    //.addRows(rowData.map((row) => headers.map((col) => row[col.accessor])))
    .addRow(headers)
    .addRows(rows)
    .exportFile();
};

export const exportPDF = (
  headers,
  rows,
  columnStyles,
  isFilters = false,
  extraHeaders,
  extraBody
) => {
  const doc = new jsPDF();
  if (isFilters) {
    const title = "Filters";
    doc.text(title, 5, 12);
  }
  autoTable(doc, {
    margin: { horizontal: 5 },
    head: [extraHeaders],
    body: extraBody,
    columnStyles,
  });
  autoTable(doc, {
    margin: { horizontal: 5 },
    head: [headers],
    body: rows,
    columnStyles,
  });
  doc.save("generated_report.pdf");
};
