// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { light, dark, primary } = colors;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(3)} ${pxToRem(5)}`,
      // padding: `${pxToRem(8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.lg,
      color: primary.main,
      transition: "background-color 300ms ease, color 300ms ease",

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: light.main,
        color: dark.main,
      },
      "& .MuiSvgIcon-root": {
        fontSize: `${pxToRem(26)} !important`,
      },
    },
  },
};

export default menuItem;
