const form = {
  prefix: {
    name: "prefix",
    label: "Prefix",
    type: "text",
  },
  first_name: {
    name: "first_name",
    label: "First Name *",
    type: "text",
    errorMsg: "First Name is required.",
  },
  middle_name: {
    name: "middle_name",
    label: "Middle Name",
    type: "text",
  },
  last_name: {
    name: "last_name",
    label: "Last Name *",
    type: "text",
    errorMsg: "Last Name is required.",
  },
  position: {
    name: "position",
    label: "Position/Title",
    type: "text",
  },
  email_address: {
    name: "email",
    label: "Email Address *",
    type: "text",
    errorMsg: "Email Address is required.",
    invalidMsg: "Email Address is invalid",
  },
  phone_number: {
    name: "phone_number",
    label: "Cell Phone # *",
    type: "phone",
    errorMsg: "Cell Phone # is required.",
  },
  country: {
    name: "country",
    label: "Country",
    type: "select",
    options: [],
  },
  state: {
    name: "state",
    label: "State",
    type: "select",
    options: [],
  },
  city: {
    name: "city",
    label: "City",
    type: "select",
    options: [],
  },
  postal_code: {
    name: "postal_code",
    label: "Postal Code",
    type: "text",
  },
  address: {
    name: "address",
    label: "Address",
    type: "textarea",
  },
  password: {
    name: "password",
    label: "Password",
    type: "password",
    errorMsg: "Password is required.",
    invalidMsg: "Password should be more than 6 characters.",
  },
  user_name: {
    name: "user_name",
    label: "Username *",
    type: "text",
    errorMsg: "Username is required.",
  },
  confirm_password: {
    name: "confirm_password",
    label: "Confirm Password",
    type: "password",
    errorMsg: "Confirm Password is required.",
    invalidMsg: "Confirm Password must be same as Password.",
  },
  user_role: {
    name: "user_role",
    label: "Role Type *",
    type: "select",
    options: [{ text: "System Admin", value: "System Admin" }],
    errorMsg: "Role Type is required.",
  },
};

export default form;
