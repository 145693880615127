import * as Yup from "yup";
import journalEnteryForm from "./journalEnteryForm";

const { entry_title, entry_description, session_id, entry_date } = journalEnteryForm;

const journalEntryValidations = Yup.object().shape({
  [entry_title.name]: Yup.string().required(entry_title.errorMsg),
  [entry_description.name]: Yup.string().required(entry_description.errorMsg),
  // [session_id.name]: Yup.string().required(session_id.errorMsg),
  [entry_date.name]: Yup.date()
    .required(entry_date.errorMsg)
    .typeError(entry_date.errorMsg),
});
export default journalEntryValidations;
