/* eslint-disable prefer-destructuring */

import colors from "assets/theme/base/colors";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function item(theme, ownerState) {
  const { palette, functions, transitions } = theme;
  const { active, miniSidenav, isCoachee } = ownerState;
  const { transparent, primary } = palette;
  const { pxToRem } = functions;

  return {
    pl: 2,
    mt: 0.379,
    mb: 0.3,
    width: miniSidenav ? "100%" : "85%",
    borderRadius: pxToRem(30),
    cursor: "pointer",
    marginLeft: !miniSidenav && "2rem",
    backgroundColor: () => {
      // let backgroundValue = active ? primary.main : transparent.main;
      let backgroundValue = miniSidenav
        ? isCoachee && active
          ? colors.coacheePortal.primary
          : active
          ? primary.main
          : transparent.main
        : isCoachee && active
        ? "#80a55a"
        : active
        ? primary.main
        : transparent.main;
      return backgroundValue;
    },
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.sharp
    }),

    "&:hover, &:focus": {
      // backgroundColor: primary.main
      backgroundColor: isCoachee ? "#70AB52" : primary.main
    },
    "&:hover *, &:focus *, &:hover .MuiBox-root::before, &:focus .MuiBox-root::before":
      {
        color: "#ffffff !important"
      }
  };
}

function itemContent(theme, ownerState) {
  const { typography, transitions, functions, palette } = theme;
  const { miniSidenav, active, isCoachee } = ownerState;

  const { size, fontWeightRegular } = typography;
  const isSafari = navigator.userAgent.indexOf("Safari") > -1;
  const { pxToRem } = functions;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: `${pxToRem(9)} ${pxToRem(15)}`,
    marginLeft: pxToRem(12),
    userSelect: "none",
    position: "relative",
    "& span": {
      // display: "none",
      color: active ? "#ffffff" : colors.secondary.main,
      fontWeight: "medium",
      fontSize: size.sm,
      marginRight: pxToRem(6),
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(["opacity", "color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard
      })
    },

    "&::before": {
      content: `"\\2022"`,
      color: active
        ? "#ffff"
        : isCoachee && !active
        ? colors.coacheePortal.primary
        : palette.primary.main,
      fontWeight: fontWeightRegular,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: pxToRem(-5),
      opacity: 1,
      borderRadius: "50%",
      fontSize: isSafari ? "3.2rem" : "xxx-large"
    }
  };
}

function itemArrow(theme, ownerState) {
  const { transitions, breakpoints, functions, palette } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

  const { pxToRem } = functions;

  return {
    // fontSize: `x-large !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: () => {
      let colorValue = palette.primary.main;

      return colorValue;
    },
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter
    }),
    display:
      noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
        ? "none !important"
        : "block !important"
    // [breakpoints.up("xl")]: {
    //   display:
    //     noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
    //       ? "none !important"
    //       : "block !important",
    // },
  };
}

export { item, itemContent, itemArrow };
