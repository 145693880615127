import * as Yup from "yup";
import form from "./form";

const {
  coaching_program_name,
  customer_id,
  coaches_id,
  action_plan_templates_id,
  program_managers_id,
} = form;

const coachingProgramValidations = Yup.object().shape({
  [coaching_program_name.name]: Yup.string().required(
    coaching_program_name.errorMsg
  ),
  [customer_id.name]: Yup.string().required(customer_id.errorMsg),
  // coaches_id: Yup.array()
  //   .of(Yup.string())
  //   .min(1, coaches_id.errorMsg)
  //   .nullable(),
  // program_managers_id: Yup.array()
  //   .min(1, program_managers_id.errorMsg)
  //   .nullable(),
  // action_plan_templates_id: Yup.array()
  //   .min(1, action_plan_templates_id.errorMsg)
  //   .nullable(),
  // coaches: Yup.array().min(1, coaches_id.errorMsg).nullable(),
  // program_managers: Yup.array().min(1, program_managers_id.errorMsg).nullable(),
  // action_plan_templates: Yup.array()
  //   .min(1, action_plan_templates_id.errorMsg)
  //   .nullable(),
});

export default coachingProgramValidations;
