import { useSelector } from "react-redux";

export const useAuth = () => {
  const { token } = useSelector((state) => state.auth);

  // if token's expiry date is same or after current datetime, return false (indicating that token is not expired),
  // else return true (indicating that token has expired)
  const isToken = token ? true : false;

  const isAuthenticated = isToken && true; // if token is valid and has not expired, authenticate user
  return isAuthenticated;
};
