import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";

export const Roles_Permissions_Slice = createSlice({
  name: "roles_permissions",
  initialState: {
    modulesPermissions: {},
    skeletonLoading: false,
    permissionsbyRole: {},
    defaultPermission: [],
    permissionData: [],
    customer_user_role_permission: {}
  },
  reducers: {
    resetRolePermissions: (state) => {
      state.modulesPermissions = {};
    },
    resetUserRolePermissiom: (state) => {
      state.customer_user_role_permission = {};
      state.defaultPermission = [];
      state.permissionsbyRole = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRolesAndPermissions.pending, (state) => {
      state.skeletonLoading = true;
    });
    builder.addCase(getRolesAndPermissions.fulfilled, (state, { payload }) => {
      if (payload) {
        let modulesObj = {};
        payload.map((module) => {
          const { module_name, values } = module;
          modulesObj[module_name] = values;
        });
        state.modulesPermissions = modulesObj;
        state.skeletonLoading = false;
      }
    });
    builder.addCase(getUserRolePermission.fulfilled, (state, { payload }) => {
      if (payload) {
        let modulesObj = {};
        payload.map((module) => {
          const { module_name, values } = module;
          modulesObj[module_name] = values;
        });
        state.customer_user_role_permission = modulesObj;
        state.defaultPermission = payload;
      }
    });
    builder.addCase(
      getRolesAndPermissionsbyRole.fulfilled,
      (state, { payload }) => {
        if (payload) {
          let modulesObj = {};
          payload.map((module) => {
            const { module_name, values } = module;
            modulesObj[module_name] = values;
          });
          state.permissionsbyRole = modulesObj;
          state.permissionData = payload;
        }
      }
    );
    builder.addCase(getCustomerRolesRight.fulfilled, (state, { payload }) => {
      if (payload) {
        let modulesObj = {};
        payload.map((module) => {
          const { module_name, values } = module;
          modulesObj[module_name] = values;
        });
        state.customer_user_role_permission = modulesObj;
        state.defaultPermission = payload;
      }
    });
    builder.addCase(getAllCO_RolesAndPermissions.fulfilled, (state, { payload }) => {
      if (payload) {
        let modulesObj = {};
        payload.map((module) => {
          const { module_name, values } = module;
          modulesObj[module_name] = values;
        });
        state.customer_user_role_permission = modulesObj;
        state.defaultPermission = payload;
      }
    });
  }
});

export const getRolesAndPermissions = createAsyncThunk(
  "Roles_Permissions/getRolesAndPermissions",
  async () => {
    let { data } = await dashboard.getModulesRolesPermissions();
    return data;
  }
);
export const getUserRolePermission = createAsyncThunk(
  "Roles_Permissions/getUserRolePermission",
  async ({ user_id }) => {
    let { data } = await dashboard.getUserRolePermission(user_id);
    return data;
  }
);
export const getRolesAndPermissionsbyRole = createAsyncThunk(
  "Roles_Permissions/getRolesAndPermissionsbyRole",
  async ({ role }) => {
    let { data } = await dashboard.getUserRolePermissionsAndModulesbyRole(role);
    return data;
  }
);
export const getAllCO_RolesAndPermissions = createAsyncThunk(
  "Roles_Permissions/getAllCO_RolesAndPermissions",
  async ({ role }) => {
    let { data } = await dashboard.getAllCustomerOrganizationPermission(role);
    return data;
  }
);
export const getCustomerRolesRight = createAsyncThunk(
  "Roles_Permissions/getCustomerRolesRight",
  async ({ role, customer_id }) => {
    let { data } = await dashboard.getCustomerRoleRights(role, customer_id);
    return data;
  }
);
export const { resetRolePermissions, resetUserRolePermissiom } =
  Roles_Permissions_Slice.actions;
export default Roles_Permissions_Slice.reducer;
