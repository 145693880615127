import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { setDialogBox, useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import colors from "assets/theme/base/colors";
import { CircularProgress } from "@mui/material";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";

const BootstrapDialog = styled(Dialog)(
  ({ theme, isResourceBox = false, isDashboardPreview }) => {
    return {
      "& .MuiDialogContent-root": {
        backgroundColor: colors.background.primary,
        padding: isDashboardPreview ? theme.spacing(2) : theme.spacing(6),
        textAlign: "center",
        ...(isResourceBox && { height: "calc(42rem - 60px);" })
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
      }
    };
  }
);

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  // children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function DialogBox({ noShowUser }) {
  const [controller, dispatch] = useMaterialUIController();
  const { dialogBox } = controller;
  const {
    open,
    title,
    body,
    primaryBtnText,
    primaryAction,
    secondaryAction = null,
    primaryBtnColor,
    cancelText,
    showCancelButton = true,
    isForm = false,
    isNoShowPopup,
    isPrimaryLoading = true,
    cacnelBtnColor,
    justifyContent = "space-between",
    btnWidth,
    isDashboardPreview,
    isResourceBox = false
  } = dialogBox;
  const [isDisabled, setIsDisabled] = useState(
    isNoShowPopup ? (noShowUser === undefined ? true : false) : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSecondary, setIsSecondary] = useState({
    loading: false,
    disabled: false
  });
  useEffect(() => {
    if (!isNoShowPopup) {
      setIsDisabled(false);
    }
    setIsLoading(false);
    setIsSecondary({
      loading: false,
      disabled: false
    });
  }, [open]);
  const handleClose = () => {
    setDialogBox(dispatch, { open: false });
  };
  const isCoachee = useIsThisUser(all_roles.coachee);
  return (
    <MDBox alignItems="center">
      <BootstrapDialog
        isResourceBox={isResourceBox}
        isDashboardPreview={isDashboardPreview}
        onClose={handleClose}
        onKeyPress={(e) => {
          if (e.code === "Enter") {
            setIsDisabled(!isPrimaryLoading ? isPrimaryLoading : true);
            setIsLoading(!isPrimaryLoading ? isPrimaryLoading : true);
            primaryAction();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            border: isCoachee
              ? `3px solid ${colors.coacheePortal.primary}`
              : `3px solid ${colors.primary.main}`,
            minWidth: isDashboardPreview ? "65rem" : "40rem",
            height: isResourceBox
              ? "41rem"
              : isDashboardPreview
              ? "50rem"
              : "auto"
          }
        }}
      >
        <DialogContent>
          <MDBox>
            <MDTypography
              variant="h3"
              // color= "primary"
              fontWeight="bold"
              // textGradient
              m={2}
              sx={{
                color: isCoachee
                  ? colors.coacheePortal.primary
                  : colors.primary.main
              }}
            >
              {title || ""}
            </MDTypography>
          </MDBox>
          {!isForm ? (
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent={justifyContent}
            >
              {body || ""}
            </MDBox>
          ) : (
            <MDBox>{body || ""}</MDBox>
          )}

          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={4}
            sx={{ marginLeft: btnWidth ? -4 : -2 }}
          >
            {primaryBtnText && (
              <MDButton
                variant="contained"
                color={
                  isCoachee
                    ? colors.coacheePortal.primary
                    : primaryBtnColor
                    ? "error"
                    : "primary"
                }
                disabled={isDisabled}
                circular
                size="large"
                sx={{
                  marginLeft: 3,
                  width: btnWidth ? `${btnWidth} !important` : "auto"
                }}
                onClick={() => {
                  setIsDisabled(!isPrimaryLoading ? isPrimaryLoading : true);
                  setIsLoading(!isPrimaryLoading ? isPrimaryLoading : true);
                  primaryAction();
                }}
              >
                {isLoading ? (
                  <CircularProgress color="white" size={22} />
                ) : (
                  primaryBtnText || "Proceed"
                )}
              </MDButton>
            )}
            {showCancelButton && (
              <MDButton
                variant={cacnelBtnColor ? "contained" : "outlined"}
                color={cacnelBtnColor ? "primary" : "error"}
                disabled={isSecondary.disabled}
                circular
                size="large"
                sx={{
                  marginLeft: 3,
                  width: btnWidth ? `${btnWidth} !important` : "auto"
                }}
                onClick={() => {
                  if (secondaryAction) {
                    setIsSecondary({
                      loading: true,
                      disabled: true
                    });
                    secondaryAction();
                  } else handleClose();
                }}
              >
                {isSecondary.loading ? (
                  <CircularProgress color="white" size={22} />
                ) : (
                  cancelText || "Cancel"
                )}
              </MDButton>
            )}
          </MDBox>
        </DialogContent>
      </BootstrapDialog>
    </MDBox>
  );
}
