import { system_time_zones } from "utils/constants";
import { noOrganizationSelectedOption } from "utils/constants";

const types = {
  text: "text",
  select: "select",
  radio: "radio",
  datePicker: "date",
  timePicker: "timePicker",
  agendaBody: "htmlEditor"
};
const { text, select, radio, datePicker, timePicker, agendaBody } = types;
const sessionForm = {
  customer_id: {
    name: "customer_id",
    label: "Customer Organization *",
    type: select,
    options: [],
    errorMsg: "Customer Organization is required"
  },
  engagement_id: {
    name: "engagement_id",
    label: "Active Engagements (Coach | Coachee/Group | Program Name) *",
    type: select,
    options: noOrganizationSelectedOption,
    errorMsg: "Engagement is required"
  },
  session_type: {
    name: "session_type",
    label: "Session Type *",
    errorMsg: "Session Type is required",
    type: "radio",
    radioItems: [
      { value: "Individual", label: "Individual" },
      { value: "Group", label: "Group" }
    ]
  },
  video_sessions: {
    name: "video_sessions",
    label: "Video Sessions",
    type: "radio",
    radioItems: [
      { value: true, label: "Yes" },
      { value: false, label: "No" }
    ]
  },
  session_status: {
    name: "session_status",
    label: "Session Status *",
    errorMsg: "Session Status is required",
    type: radio,
    radioItems: [
      { value: "Proposed", label: "Proposed (For individuals only)" },
      { value: "Confirmed", label: "Confirmed" }
    ]
  },

  session_date: {
    name: "session_date",
    label: "Session Date *",
    type: datePicker,
    errorMsg: "Date is required",
    notTypeMsg: "Invalid Format"
  },
  time_zone: {
    name: "time_zone",
    label: "Time Zone *",
    type: select,
    options: system_time_zones,
    errorMsg: "Time zone is required"
  },
  start_time: {
    name: "start_time",
    label: "Session Start Time *",
    type: timePicker,
    errorMsg: "Start Time is required",
    notTypeMsg: "Invalid Format"
  },
  end_time: {
    name: "end_time",
    label: "Session End Time *",
    type: timePicker,
    errorMsg: "End Time is required",
    notTypeMsg: "Invalid Format"
  },
  session_name: {
    name: "session_name",
    label: "Session Name *",
    type: text,
    errorMsg: "Session Name is required."
  },
  agenda_body: {
    name: "agenda_body",
    label: "Session Agenda",
    type: agendaBody,
    errorMsg: "Session Agenda is required."
  }
};

export default sessionForm;
