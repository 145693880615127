import axios from "axios";
import { URLs } from "./constants";
import { Config } from "config";

// config for mock api
export const mock_api = axios.create({
  baseURL: URLs.mock_baseUrl,
  headers: {
    "x-mock-response-code": 200
  }
});

// config for alternate mock api
export const mock_api_alt = axios.create({
  baseURL: URLs.mock_baseUrl_alt,
  headers: {
    "x-mock-response-code": 200
  }
});

// config for the actual backend api
export const api = axios.create({
  baseURL: Config.apiGateway.URL
});

// endpoints for the apis
export const endpoints = {
  auth: {
    loginUrl: URLs.loginUrl,
    forgotPasswordUrl: URLs.forgotPasswordUrl,
    verifyTokenUrl: URLs.verifyTokenUrl,
    resetPasswordUrl: URLs.resetPasswordUrl
  },
  profile: {
    updateUserProfile: URLs.updateUserProfile,
    changePassword: URLs.changePassword
  },
  dashboard: {
    // user APIs
    getUsersByRoleUrl: URLs.users.GET_USERS_BY_ROLE,
    getCoacheeFromSponsor: URLs.users.GET_COACHEE_FROM_SPONSOR,
    getUserBySponsor: URLs.users.GET_USERS_FROM_SPONSOR,
    getPMByCoachingProgram: URLs.users.GET_PM_BY_COACHING_PROGRAM,
    createNewUserUrl: URLs.createNewUserUrl,
    updateUserUrl: URLs.updateUserUrl,
    getUserById: URLs.getUserById,

    // group APIs
    getAllGroups: URLs.getAllGroups,
    createNewGroup: URLs.createNewGroupUrl,
    updateGroupUrl: URLs.updateGroup,
    getGroupById: URLs.getGroupById,
    deleteGroup: URLs.DELETE_GROUP,
    updateGroupStatus: URLs.UPDATE_GROUP_STATUS,
    getGroupBySponsor: URLs.GET_GROUPS_BY_SPONSORS,

    // customer APIs
    getCustomerOrganizationsForDropdown:
      URLs.getCustomerOrganizationsForDropdown,
    getCustomers: URLs.getCustomers,
    getCustomerById: URLs.getCustomerById,
    createCustomer: URLs.createCustomer,
    updateCustomer: URLs.updateCustomer,
    deletePrimaryContact: URLs.deletePrimaryContact,

    // roles and permissions APIs
    getAllPermissions: URLs.getAllPermissions,
    updatePermissions: URLs.updatePermissions,
    getUserRolePermissions: URLs.getUserRolePermissions,
    getUserRolePermissionsAndModules: URLs.getUserRolePermissionsAndModules,
    getUserRolePermissionsAndModulesbyRole:
      URLs.getUserRolePermissionsAndModulesbyRole,
    getCustomerRoleRights: URLs.GET_CUSTOMER_ROLE_RIGHTS,
    getAllCustomerOrganizationPermission:
      URLs.GET_ALL_CUSTOMERS_ORGANIZATION_PERMISSIONS,

    // sponsoring entity APIs
    getSponsoringEntities: URLs.getSponsoringEntities,
    createNewSponsoringEntity: URLs.createNewSponsoringEntity,
    getSponsoringEntityByID: URLs.getSponsoringEntityByID,
    updateSponsoringEntity: URLs.updateSponsoringEntity,
    getSponsoringEntitiesForDropdown: URLs.getSponsoringEntitiesForDropdown,

    // coaching program APIs
    getCoachingPrograms: URLs.getCoachingPrograms,
    getCoachingProgramByID: URLs.getCoachingProgramByID,
    deleteCoachinProgramByID:
      URLs.coachingPrograms.DELETE_COACHING_PROGRAM_BY_ID,
    updateCoachingProgram: URLs.updateCoachingProgram,
    createCoachingProgram: URLs.createCoachingProgram,
    getActionTemplateForCoachingPrograms:
      URLs.getActionTemplateForCoachingPrograms,
    getCoachingProgramBySponsor:
      URLs.coachingPrograms.GET_COACHING_PROGRAMS_BY_SPONSOR,

    // sessions
    getSessionsHistory: URLs.getSessionsHistory,
    getMyHistorySession: URLs.getMyHistorySession,
    getSessions: URLs.getSessions,
    getMyUpcomingSessions: URLs.getMyUpcomingSessions,
    getUpcomingSessions: URLs.getUpcomingSessions,
    getUpcomingSessionsBySponsor: URLs.GET_UPCOMING_SESSIONS_BY_SPONSOR,
    getHistorySessionBySponsor: URLs.GET_SESSIONS_HISTORY_BY_SPONSOR,
    createSessions: URLs.createUpcomingSession,
    getSessionById: URLs.getSessionById,
    updateSession: URLs.updateSession,

    rescheduleSession: URLs.rescheduleSession,
    confirmSession: URLs.confirmSession,

    conductVideoSession: URLs.sessions.CONDUCT_VIDEO_SESSION,
    getRecording: URLs.sessions.GET_RECORDING,
    // journal enteris
    getAllJournalEntries: URLs.getAllJournalEnteries,
    getMyJournalEntries: URLs.getMyJounalEnteries,
    createJournalEntry: URLs.createJournalEntry,
    updateJournalEntry: URLs.updateJournalEntry,
    getJounalEntryById: URLs.getJournalEntryById,

    // feedback sessions
    createFeedback: URLs.createFeedback,
    updateFeedback: URLs.updateFeedback,
    getMyFeedback: URLs.getMyFeedback,
    getAllFeedback: URLs.getAllFeedback,

    getEngagements: URLs.getEngagements,

    // manage no-show sessions
    manageNoShowSession: URLs.manageNoShowSession,
    getNoShowCoachees: URLs.getNoShowCoachees,

    // keywords substitutions APIs
    getKeywords: URLs.getKeywords,
    createKeywords: URLs.createKeywords,
    deleteKeywords: URLs.deleteKeywords,

    // validations
    checkEmail: URLs.checkEmail,
    checkUser: URLs.checkUser,

    //activity Reports
    getActivityReports: URLs.getActivityReports
  },
  admincontrol: {
    VideoSessionUpdate: URLs.VideoSessionUpdate,
    controlFeedBack: URLs.control_feedback
  },
  engagementConsole: {
    // engagement console APIs
    getAllEngagements: URLs.engagementConsole.getAllEngagements,
    getEngagementByType: URLs.engagementConsole.getEngagementByType,
    getEngagementByUserType: URLs.engagementConsole.GET_ENGAGEMENT_BY_TYPE,
    getEngagementsbyme: URLs.engagementConsole.getEngagementsbyme,
    getAllCoachingPrograms: URLs.engagementConsole.getAllCoachingPrograms,
    createEngagements: URLs.engagementConsole.createEngagements,
    getEngagementById: URLs.engagementConsole.getEngagementById,
    deleteEngagmentById: URLs.engagementConsole.deleteEngagmentById,
    updateEngagement: URLs.engagementConsole.updateEngagement,
    getCoachesByCoachingProgram:
      URLs.engagementConsole.getCoachesByCoachingProgram,
    getCoachByCoachingProgram:
      URLs.engagementConsole.GET_COACH_BY_COACHING_PROGRAM,
    getActionPlanByCoachingProgram:
      URLs.engagementConsole.getActionPlanByCoachingProgram,
    getSponsoringEntityByCoacheeORGroupID:
      URLs.engagementConsole.getSponsoringEntityByCoacheeORGroupID,
    getCoacheeActionPlanByGroup:
      URLs.engagementConsole.GET_COACHEE_ACTION_PLAN_BY_GROUP,
    getEngagementBySponsor: URLs.engagementConsole.GET_ENGAGEMENTS_BY_SPONSOR
  },
  // action plan APIs
  actionPlanTemplate: {
    getActionPlans: URLs.actionPlanTemplate.getActionPlans,
    createActionPlan: URLs.actionPlanTemplate.createActionPlan,
    updateActionPlan: URLs.actionPlanTemplate.UPDATE_ACTION_PLAN,

    createObjective: URLs.actionPlanTemplate.createObjective,
    updateObjective: URLs.actionPlanTemplate.updateObjective,
    deleteObjective: URLs.actionPlanTemplate.deleteObjective,

    createGoal: URLs.actionPlanTemplate.createGoal,
    updateGoal: URLs.actionPlanTemplate.updateGoal,
    deleteGoal: URLs.actionPlanTemplate.deleteGoal,

    createTask: URLs.actionPlanTemplate.createTask,
    updateTask: URLs.actionPlanTemplate.updateTask,
    deleteTask: URLs.actionPlanTemplate.deleteTask,
    getTaskByID: URLs.actionPlanTemplate.getTaskByID,

    createResource: URLs.actionPlanTemplate.createResource,
    updateResource: URLs.actionPlanTemplate.updateResource,
    deleteResource: URLs.actionPlanTemplate.deleteResource,
    getResourceByID: URLs.actionPlanTemplate.getResourceByID,

    getActionPlanByID: URLs.actionPlanTemplate.getActionPlanByID,
    reorderActionPlan: URLs.actionPlanTemplate.REORDER_ACTION_PLAN,
    mergeTemplates: URLs.actionPlanTemplate.MERGE_TEMPLATES,

    getCoacheeActionPlans: URLs.actionPlanTemplate.GET_ALL_COACHEE_ACTION_PLANS,
    getCoacheeActionPlanByID:
      URLs.actionPlanTemplate.GET_COACHEE_ACTION_PLAN_BY_ID,

    convertActionPlanToTemplate:
      URLs.actionPlanTemplate.CONVERT_ACTION_PLAN_TO_TEMPLATE
  },
  //access audit log
  accessAuditLog: {
    getAuditLogs: URLs.accessAuditLog.getAuditLogs,
    getActionType: URLs.accessAuditLog.getActionType
  },
  manageDocument: {
    createDocument: URLs.manageDocuments.createDocument,
    getDocumentbyDocId: URLs.manageDocuments.getDocumentbyDocId,
    getDocumentUploadedby: URLs.manageDocuments.getDocumentUploadedby,
    getSystemAdminAssignedDoc: URLs.manageDocuments.getSystemAdminAssignedDoc,
    getAssignedDocuments: URLs.manageDocuments.getAssignedDocuments,
    updateDocument: URLs.manageDocuments.updateDocument,
    assignDocument: URLs.manageDocuments.assignDocument,

    //get Coach , Coachee , ProgramManager , CoachingProgram , Groups for Manage Document
    getProgramManager: URLs.manageDocuments.getProgramManager,
    getCoachUser: URLs.manageDocuments.getCoachUser,
    getCoacheeUser: URLs.manageDocuments.getCoacheeUser,
    getCoachingProgram: URLs.manageDocuments.getCoachingProgram
  },
  sastoken: {
    getSASToken: URLs.getSASToken
  },
  coacheeSessions: {
    getProposedConfirmSession: URLs.coacheeSessions.getProposedConfirmSession,
    getMyUpcomingSession: URLs.coacheeSessions.getMyUpcomingSession,
    getMySessionHistory: URLs.coacheeSessions.getMySessionHistory
  },
  coacheeActionPlan: {
    getActionPlan: URLs.coacheeActionPlan.getActionPlan,
    getCoacheeActionPlansByEngagment:
      URLs.coacheeActionPlan.GET_ACTION_PLAN_BY_ENGAGMENT,
    getCoacheeNodeProgress: URLs.coacheeActionPlan.GET_COACHEE_NODE_PROGRESS,
    getEngagmentByCoachee: URLs.coacheeActionPlan.GET_ENGAGMENT_BY_COACHEE
  },
  coachingProgramByMe: {
    getCoachingProgramByMe: URLs.coachingProgramByMe.getCoachingProgramByMe
  },
  coacheeProgress: {
    getMyProgress: URLs.coacheeProgress.getMyProgress
  }
};
