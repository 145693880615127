import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { dashboard } from "utils/apiMethods";
const initialState = {
  data: { currentPage: 1, data: [], pageSize: 5, totalItem: 5, totalPage: 1 },
  allItemsChecked: false,
  isAnyItemChecked: true,
  checkedItems: [],
  alreadyAddedCoachee: [],
  coacheesData: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1
  },
  isGroupCoacheesLoading: false
};
export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    resetGroupSlice: (state) => {
      state = initialState;
    },
    setAllItemsChecked: (
      state,
      { payload: { allItemsChecked, isSubTable = false } }
    ) => {
      // if data length is empty, check box would not checke
      state.allItemsChecked =
        (!isSubTable && state.data.data.length) ||
        (isSubTable && state.coacheesData.data.length)
          ? allItemsChecked
          : false;
      state.isAnyItemChecked = allItemsChecked;
      if (allItemsChecked) {
        state.checkedItems = isSubTable
          ? state.coacheesData.data.filter((x) => x.is_active)
          : state.data.data;
      } else {
        state.checkedItems = [];
      }
      let dataArray = isSubTable ? state.coacheesData.data : state.data.data;
      dataArray.map((item) => {
        if (isSubTable) {
          item.isChecked = item.is_active ? allItemsChecked : false;
          return item;
        } else {
          item.isChecked = allItemsChecked;
          return item;
        }
      });
    },

    setItemChecked: (state, { payload: { id, isSubTable = false } }) => {
      let matchedItem;
      if (isSubTable) {
        // isSubTable will be true if the table for intended for sub table in add or edit group
        matchedItem = state?.coacheesData?.data?.find((item) => item.id === id);
      } else {
        matchedItem = state?.data?.data?.find((item) => item.group_id === id);
      }
      matchedItem.isChecked = !matchedItem?.isChecked;

      // adding and removing data from checked item array
      if (matchedItem.isChecked) {
        state.checkedItems = [...state.checkedItems, matchedItem];
      } else {
        // if any of item unchecked so check all item will be false so checked would be remove from check all
        state.allItemsChecked = false;

        state.checkedItems = state.checkedItems.filter((item) => {
          if (isSubTable) {
            return item.id !== matchedItem.id;
          } else {
            return item.group_id !== matchedItem.group_id;
          }
        });
      }

      // check if any item is checked
      if (state.data.data.some((item) => item.isChecked === true)) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },

    setClearCheckedItem: (state) => {
      state.checkedItems = [];
      state.isAnyItemChecked = true;
      state.alreadyAddedCoachee = [];
      state.coacheesData = {
        currentPage: 1,
        data: [],
        pageSize: 5,
        totalItem: 5,
        totalPage: 1
      };
      state.allItemsChecked = false;
    },
    getAlreadyAddedCoachee: (state, { payload }) => {
      state.alreadyAddedCoachee = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGroups.fulfilled, (state, { payload }) => {
      if (payload.data) {
        // this step is taken to add another property 'isChecked' to the data table
        payload.data.map((item) => {
          item.isChecked = false;
          return item;
        });
        state.allItemsChecked = false;
        state.isAnyItemChecked = false;
        state.data = payload;
      }
    });
    builder.addCase(getGroupsBySponsor.fulfilled, (state, { payload }) => {
      if (payload.data) {
        // this step is taken to add another property 'isChecked' to the data table
        payload.data.map((item) => {
          item.isChecked = false;
          return item;
        });
        state.allItemsChecked = false;
        state.isAnyItemChecked = false;
        state.data = payload;
      } else {
        state.data = [];
      }
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.isGroupCoacheesLoading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.isGroupCoacheesLoading = false;
      if (payload.data) {
        state.checkedItems = [];
        payload.data.map((item) => {
          if (state.alreadyAddedCoachee?.includes(item.id)) {
            item.isChecked = true;
            state.checkedItems = [...state.checkedItems, item];
          } else {
            item.isChecked = false;
          }
          return item;
        });
        payload.data.sort((a, b) => {
          if (a.isChecked && !b.isChecked) {
            return -1; // a should come before b
          } else if (!a.isChecked && b.isChecked) {
            return 1; // b should come before a
          } else {
            return 0; // keep the order unchanged
          }
        });
        // this step is taken to add another property 'isChecked' to the data table
        state.coacheesData = payload;
        state.coacheesData.pageSize = payload.totalItem;
        state.coacheesData.totalPage = 1;
      }
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.isGroupCoacheesLoading = false;
    });
    builder.addCase(
      getAllCoacheeFromSponsor.fulfilled,
      (state, { payload }) => {
        if (payload.data) {
          state.checkedItems = [];
          payload.data.map((item) => {
            if (state.alreadyAddedCoachee.includes(item.id)) {
              item.isChecked = true;
              state.checkedItems = [...state.checkedItems, item];
            } else {
              item.isChecked = false;
            }
            return item;
          });
          // this step is taken to add another property 'isChecked' to the data table
          state.coacheesData = payload;
          state.coacheesData.pageSize = payload.totalItem;
          state.coacheesData.totalPage = 1;
        }
      }
    );
  }
});

// Action creators are generated for each case reducer function

// thunks are used to making asynchronous api calls
export const getAllGroups = createAsyncThunk(
  "groups",
  async ({ pageSize, currentPage, customer_id, searchQuery }) => {
    const response = await dashboard.getAllGroups(
      pageSize,
      currentPage,
      customer_id,
      searchQuery
    );
    return response;
  }
);
export const getGroupsBySponsor = createAsyncThunk(
  "groups/getGroupsBySponsor",
  async ({ pageSize, currentPage, searchQuery }) => {
    const response = await dashboard.getGroupsBySponsor(
      currentPage,
      pageSize,
      searchQuery
    );
    return response.data;
  }
);
export const getAllUsers = createAsyncThunk(
  "groups/getCoachees",
  async ({ role, pageSize, currentPage, customer_id }) => {
    const response = await dashboard.getUsersByRole(
      role,
      currentPage,
      pageSize,
      customer_id
    );
    return response;
  }
);
export const getAllCoacheeFromSponsor = createAsyncThunk(
  "groups/getCoacheeFromSponsor",
  async ({ currentPage = 1, pageSize = 5 }) => {
    const response = await dashboard.getCoacheeFromSponsor(
      currentPage,
      pageSize
    );
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setItemChecked,
  setAllItemsChecked,
  setClearCheckedItem,
  getAlreadyAddedCoachee,
  resetGroupSlice
} = groupsSlice.actions;

export default groupsSlice.reducer;
