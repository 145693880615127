import * as Yup from "yup";
import sponsoringEntityForm from "./form";

const { sponsoring_entity_name, customer_id } = sponsoringEntityForm;

const validations = Yup.object().shape({
  [sponsoring_entity_name.name]: Yup.string().required(
    sponsoring_entity_name.errorMsg
  ),
  [customer_id.name]: Yup.string().required(customer_id.errorMsg).nullable(),
});

export default validations;
