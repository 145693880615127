import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import SidenavCollapse from "layouts/common/Navs/Sidenav/SidenavCollapse";
import SidenavList from "layouts/common/Navs/Sidenav/SidenavList";
import SidenavItem from "layouts/common/Navs/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "layouts/common/Navs/Sidenav/SidenavRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { isThisRouteAllowed } from "utils/helper-method";
import { Skeleton } from "@mui/material";

// import { navbarMobileMenu } from "components/Navs/DashboardNavbar/styles";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { modulesPermissions, skeletonLoading } = useSelector(
    (state) => state.Roles_Permissions
  );
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[0];
  const itemName = items[items.length - 1];
  const itemSecondName = items[items.length - 2];
  const itemThirdName = items[items.length - 3];
  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(
      ({ name, route, key, href, permissionName }) => {
        let isRouteAllowed = isThisRouteAllowed(
          { permissionName, name },
          modulesPermissions
        );
        href && isRouteAllowed ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        );
      }
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) => {
    let allowedCollapsesRoutes = [];
    for (let collapseRoute of collapses) {
      const { name, collapse, route, href, key, permissionName } =
        collapseRoute;
      let isRouteAllowed = isThisRouteAllowed(
        { permissionName, name },
        modulesPermissions
      );
      if (isRouteAllowed) {
        let returnValue;
        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) => {
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key);
              }}
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem
                name={name}
                active={
                  key === itemName ||
                  key === itemSecondName ||
                  key === itemThirdName
                }
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                color={color}
                name={name}
                active={
                  key === itemName ||
                  key === itemSecondName ||
                  key === itemThirdName
                }
              />
            </NavLink>
          );
        }
        allowedCollapsesRoutes.push(
          <SidenavList key={key}>{returnValue}</SidenavList>
        );
      }
    }
    return allowedCollapsesRoutes;
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      permissionName
    }) => {
      let isRouteAllowed = isThisRouteAllowed(
        { permissionName, name },
        modulesPermissions
      );
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route && isRouteAllowed) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          let collapseRoutes = collapse ? renderCollapse(collapse) : null;
          if (collapseRoutes?.length) {
            let first_route = collapseRoutes[0]?.props?.children?.props?.to;
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => {
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key);
                  //it will redirect to first route of collapseRoute
                  setTimeout(() => {
                    navigate(first_route);
                  }, 400);
                }}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={4} pb={4} px={1} textAlign="center"></MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {skeletonLoading ? (
        <List>
          {[1, 2, 3, 4, 5, 6].map(() => {
            return (
              <MDTypography component="div" variant={"h1"}>
                <Skeleton />
              </MDTypography>
            );
          })}
        </List>
      ) : (
        <List>{renderRoutes}</List>
      )}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: ""
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark"
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sidenav;
