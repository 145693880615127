import { resetSponsorEntities } from "redux-store/features/dashboard/sponsoringEntitySlice.js";
import { endpoints, api } from "./axios-config.js";
import { URLs } from "./constants.js";

export const auth = {
  // #region POST METHODS
  login: (body) => {
    return genericPostMethod(endpoints.auth.loginUrl, body);
  },
  forgotPassword: (body) => {
    return genericPostMethod(endpoints.auth.forgotPasswordUrl, body);
  },
  verifyToken: (body) => {
    return genericPostMethod(endpoints.auth.verifyTokenUrl, body);
  },
  resetPassword: async (body) => {
    const response = await api.put(endpoints.auth.resetPasswordUrl, body);
    return response;
  },
  refreshAuthToken: async (refreshToken) => {
    const response = await api.post(
      `${URLs.REFRESH_AUTH_TOKEN_URL}${refreshToken}`
    );
    return response;
  }
  // #endregion
};

export const profile = {
  updateProfile: async (body) => {
    const response = await api.put(endpoints.profile.updateUserProfile, body);
    return response;
  },
  changePassword: async (body) => {
    const response = await api.put(endpoints.profile.changePassword, body);
    return response;
  }
};

export const dashboard = {
  // using this api to extract customer organization name for CUSTOMER ADMIN
  getCustomerOrganizationNameByProfile: async () => {
    const response = await api.get(endpoints.profile.updateUserProfile);
    return response;
  },

  // #region Users
  getUsersByRole: async (
    role,
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    search
  ) => {
    const response = await api.get(
      `${endpoints.dashboard.getUsersByRoleUrl}${role}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          customer_id,
          search
        }
      }
    );
    return response.data;
  },
  getCoacheeFromSponsor: async (currentPage = 1, pageSize = 5, search) => {
    const response = await api.get(endpoints.dashboard.getCoacheeFromSponsor, {
      params: {
        page_size: pageSize,
        current_page: currentPage,
        search
      }
    });
    return response.data;
  },
  getUserBySponsor: async ({
    currentPage = 1,
    pageSize = 10,
    coaching_id = null
  }) => {
    const response = await api.get(endpoints.dashboard.getUserBySponsor, {
      params: {
        page_size: pageSize,
        current_page: currentPage,
        coaching_id
      }
    });
    return response;
  },
  getPMByCoachingProgram: async ({
    currentPage = 1,
    pageSize = null,
    searchQuery,
    coaching_id = null
  }) => {
    const response = await api.get(endpoints.dashboard.getPMByCoachingProgram, {
      params: {
        page_size: pageSize,
        current_page: currentPage,
        search: searchQuery,
        coaching_id
      }
    });
    return response.data;
  },
  createNewUser: async (body) => {
    const response = await api.post(endpoints.dashboard.createNewUserUrl, body);
    return response;
  },
  updateUser: async (body, id) => {
    const response = await api.put(
      `${endpoints.dashboard.getUserById}${id}`,
      body
    );
    return response;
  },
  getUserById: async (id) => {
    const response = await api.get(`${endpoints.dashboard.getUserById}${id}`);
    return response;
  },

  // #endregion

  // #region groups
  getAllGroups: async (pageSize, currentPage, customer_id = null, search) => {
    const response = await api.get(
      `${endpoints.dashboard.getAllGroups}?page_size=${pageSize}&current_page=${currentPage}`,
      {
        params: {
          customer_id,
          search
        }
      }
    );
    return response.data;
  },
  getGroupById: async (id) => {
    const response = await api.get(`${endpoints.dashboard.getGroupById}/${id}`);
    return response;
  },
  createNewGroup: async (body) => {
    const response = await api.post(endpoints.dashboard.createNewGroup, body);
    return response;
  },
  updateGroup: async (body, id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateGroupUrl}/${id}`,
      body
    );
    return response;
  },
  deleteGroup: async (group_id) => {
    const response = await api.delete(
      `${endpoints.dashboard.deleteGroup}/${group_id}`
    );
    return response;
  },
  getGroupsBySponsor: async (currentPage = 1, pageSize = 5, search) => {
    const response = await api.get(`${endpoints.dashboard.getGroupBySponsor}`, {
      params: {
        page_size: pageSize,
        current_page: currentPage,
        search
      }
    });
    return response;
  },
  // #endregion

  // #region customers
  getCustomers: async (
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    search
  ) => {
    let response = null;
    if (customer_id) {
      response = await api.get(
        `${endpoints.dashboard.getCustomerById}${customer_id}`
      );
    } else {
      response = await api.get(endpoints.dashboard.getCustomers, {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search
        }
      });
    }
    return response.data;
  },
  getCustomerOrganizationsForDropdown: async () => {
    const response = await api.get(
      endpoints.dashboard.getCustomerOrganizationsForDropdown,
      {
        params: {
          current_page: -1
        }
      }
    );
    return response;
  },
  getCustomerById: async (id) => {
    const response = await api.get(
      `${endpoints.dashboard.getCustomerById}${id}`
    );
    return response;
  },
  updateCustomer: async (body, id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateCustomer}${id}`,
      body
    );
    return response.data;
  },
  deletePrimaryContact: async (id) => {
    const response = await api.delete(
      `${endpoints.dashboard.deletePrimaryContact}${id}`
    );
    return response.data;
  },
  createCustomer: async (body) => {
    const response = await api.post(endpoints.dashboard.createCustomer, body);
    return response;
  },

  // Permissions and roles apis
  getPermisionsByRole: async (user_id, customer_id, role) => {
    const response = await api.get(endpoints.dashboard.getAllPermissions, {
      params: {
        user_id,
        customer_id,
        role
      }
    });
    return response;
  },
  setUpdatedPermissions: async (
    customer_id,
    role,
    user_id,
    future_update,
    body
  ) => {
    const response = await api.post(
      endpoints.dashboard.updatePermissions,
      body,
      {
        params: {
          user_id,
          customer_id,
          role,
          future_update
        }
      }
    );
    return response;
  },
  getModulesRolesPermissions: async () => {
    const response = await api.get(
      endpoints.dashboard.getUserRolePermissionsAndModules
    );
    return response;
  },
  getUserRolePermission: async (user_id) => {
    const response = await api.get(
      endpoints.dashboard.getUserRolePermissionsAndModules,
      {
        params: {
          user_id
        }
      }
    );
    return response;
  },
  getUserRolePermissionsAndModulesbyRole: async (role) => {
    const response = await api.get(
      `${endpoints.dashboard.getUserRolePermissionsAndModulesbyRole}${role}`
    );
    return response;
  },
  getAllCustomerOrganizationPermission: async (role) => {
    const response = await api.get(
      `${endpoints.dashboard.getAllCustomerOrganizationPermission}${role}`
    );
    return response;
  },
  getCustomerRoleRights: async (role, customer_id) => {
    const response = await api.get(
      `${endpoints.dashboard.getCustomerRoleRights}${role}/${customer_id}`
    );
    return response;
  },
  // #endregion

  // #region sponsoring entities
  getSponsoringEntities: async (
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    search
  ) => {
    const response = await api.get(endpoints.dashboard.getSponsoringEntities, {
      params: {
        page_size: pageSize,
        current_page: currentPage,
        customer_id,
        search
      }
    });
    return response.data;
  },
  createNewSponsoringEntity: async (body) => {
    const response = await api.post(
      endpoints.dashboard.createNewSponsoringEntity,
      body
    );
    return response;
  },
  getSponsoringEntityByID: async (id) => {
    const response = await api.get(
      `${endpoints.dashboard.getSponsoringEntityByID}${id}`
    );
    return response;
  },
  updateSponsoringEntity: async (body, id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateSponsoringEntity}${id}`,
      body
    );
    return response;
  },
  getSponsoringEntitiesForDropdown: async (customer_id) => {
    const response = await api.get(
      endpoints.dashboard.getSponsoringEntitiesForDropdown,
      {
        params: {
          customer_id
        }
      }
    );
    return response.data?.data;
  },
  // #endregion

  // #region coaching programs
  getCoachingPrograms: async (
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    search
  ) => {
    const response = await api.get(
      URLs.coachingPrograms.GET_COACHING_PROGRAMS,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          customer_id,
          search
        }
      }
    );
    return response.data;
  },
  getCoachingProgramBySponsor: async (
    currentPage = 1,
    pageSize = 5,
    search
  ) => {
    const response = await api.get(
      endpoints.dashboard.getCoachingProgramBySponsor,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search
        }
      }
    );
    return response;
  },
  createCoachingProgram: async (body) => {
    const response = await api.post(
      endpoints.dashboard.createCoachingProgram,
      body
    );
    return response;
  },
  getCoachingProgramByID: async (id) => {
    const response = await api.get(
      `${endpoints.dashboard.getCoachingProgramByID}${id}`
    );
    return response;
  },
  deleteCoachingProgramByID: async (id) => {
    const response = await api.delete(
      `${endpoints.dashboard.deleteCoachinProgramByID}/${id}`
    );
    return response;
  },
  updateCoachingProgram: async (body, id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateCoachingProgram}${id}`,
      body
    );
    return response.data;
  },
  getUsersForCoachingPrograms: async (
    role,
    customer_id = null,
    currentPage = -1
  ) => {
    const response = await api.get(
      `${endpoints.dashboard.getUsersByRoleUrl}${role}`,
      {
        params: {
          current_page: currentPage,
          customer_id
        }
      }
    );
    return response.data;
  },

  getActionTemplateForCoachingPrograms: async (
    customer_id = null,
    currentPage = -1
  ) => {
    const response = await api.get(
      `${endpoints.dashboard.getActionTemplateForCoachingPrograms}`,
      {
        params: {
          current_page: currentPage,
          customer_id
        }
      }
    );
    return response.data;
  },

  // #endregion

  // #region keywords substitutions
  getKeywords: async (customer_id) => {
    const response = await api.get(`${endpoints.dashboard.getKeywords}`, {
      params: { customer_id }
    });
    return response.data;
  },

  createKeywords: async (customer_id, body) => {
    const response = await api.post(
      `${endpoints.dashboard.createKeywords}`,
      body,
      {
        params: { customer_id }
      }
    );
    return response.data;
  },

  deleteKeywords: async (customer_id) => {
    const response = await api.delete(
      `${endpoints.dashboard.deleteKeywords}${customer_id}`
    );
    return response;
  },
  // #region sessions
  getSessionsHistory: async (
    current_page,
    page_size,
    customer_id,
    coaching_program_id,
    search
  ) => {
    const response = await api.get(endpoints.dashboard.getSessionsHistory, {
      params: {
        page_size,
        current_page,
        customer_id,
        coaching_program_id,
        search
      }
    });
    return response.data;
  },
  getMyHistorySession: async (
    current_page,
    page_size,
    search,
    coaching_program_id
  ) => {
    const response = await api.get(endpoints.dashboard.getMyHistorySession, {
      params: {
        page_size,
        current_page,
        search,
        coaching_program_id
      }
    });
    return response.data;
  },
  createSessions: async (body) => {
    const response = await api.post(
      `${endpoints.dashboard.createSessions}`,
      body
    );
    return response;
  },
  getSessions: async (
    current_page,
    page_size,
    customer_id,
    engagement_id,
    search
  ) => {
    const response = await api.get(endpoints.dashboard.getSessions, {
      params: {
        page_size,
        current_page,
        customer_id,
        search,
        engagement_id
      }
    });
    return response.data;
  },
  getUpcomingSessions: async ({
    current_page,
    page_size,
    customer_id,
    coaching_program_id,
    engagement_id,
    search
  }) => {
    const response = await api.get(endpoints.dashboard.getUpcomingSessions, {
      params: {
        current_page,
        page_size,
        customer_id,
        coaching_program_id,
        engagement_id,
        search
      }
    });
    return response;
  },
  getMyUpcomingSessions: async (
    current_page,
    page_size,
    search,
    coaching_program_id
  ) => {
    const response = await api.get(endpoints.dashboard.getMyUpcomingSessions, {
      params: { page_size, current_page, search, coaching_program_id }
    });
    return response.data;
  },
  getUpcomingSessionsBySponsor: async ({
    current_page,
    page_size,
    coaching_id = null,
    search
  }) => {
    const response = await api.get(
      endpoints.dashboard.getUpcomingSessionsBySponsor,
      {
        params: { page_size, current_page, coaching_id, search }
      }
    );
    return response.data;
  },
  getHistorySessionBySponsor: async ({
    current_page,
    page_size,
    coaching_id = null,
    search
  }) => {
    const response = await api.get(
      endpoints.dashboard.getHistorySessionBySponsor,
      {
        params: {
          page_size,
          current_page,
          coaching_id,
          search
        }
      }
    );
    return response.data;
  },
  getSessionById: async (session_id) => {
    const response = await api.get(
      `${endpoints.dashboard.getSessionById}${session_id}`
    );
    return response;
  },
  updateSession: async (body, session_id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateSession}${session_id}`,
      body
    );
    return response;
  },
  rescheduleSession: async (body, session_id) => {
    const response = await api.put(
      `${endpoints.dashboard.rescheduleSession}${session_id}`,
      body
    );
    return response;
  },
  confirmSession: async (body, session_id) => {
    const response = await api.put(
      `${endpoints.dashboard.confirmSession}${session_id}`,
      body
    );
    return response;
  },
  conductVideoSession: async (session_id) => {
    const response = await api.get(
      `${endpoints.dashboard.conductVideoSession}`,
      {
        params: {
          session_id
        }
      }
    );
    return response.data;
  },
  getRecording: async (session_id) => {
    const response = await api.get(`${endpoints.dashboard.getRecording}`, {
      params: {
        session_id
      }
    });
    return response;
  },
  // #endregion

  // #region journal enteries
  getAllJournalEnteries: async (params) => {
    const response = await api.get(
      `${endpoints.dashboard.getAllJournalEntries}`,
      {
        params
      }
    );
    return response;
  },
  getMyJournalEnteries: async (params) => {
    const response = await api.get(
      `${endpoints.dashboard.getMyJournalEntries}`,
      {
        params
      }
    );
    return response;
  },
  createJournalEntry: async (body) => {
    const response = await api.post(
      `${endpoints.dashboard.createJournalEntry}`,
      body
    );
    return response;
  },
  updateJournalEntry: async (body, journal_entry_id) => {
    const response = await api.put(
      `${endpoints.dashboard.updateJournalEntry}${journal_entry_id}`,
      body
    );
    return response;
  },
  getJournalEntryById: async (journal_entry_id) => {
    const response = await api.get(
      `${endpoints.dashboard.getJounalEntryById}${journal_entry_id}`
    );
    return response;
  },

  // #region Feedback Session
  createFeedback: async (session_id, body) => {
    const response = await api.post(
      `${endpoints.dashboard.createFeedback}?session_id=${session_id}`,
      body
    );
    return response;
  },
  updateFeedback: async (feed_back_id, body) => {
    const response = await api.put(
      `${endpoints.dashboard.updateFeedback}${feed_back_id}`,
      body
    );
    return response;
  },
  getMyFeedback: async (session_id) => {
    const response = await api.get(
      `${endpoints.dashboard.getMyFeedback}?session_id=${session_id}`
    );
    return response;
  },
  getAllFeedback: async ({ session_id, currentPage = 5, pageSize = 1 }) => {
    const response = await api.get(`${endpoints.dashboard.getAllFeedback}`, {
      params: {
        current_page: currentPage,
        page_size: pageSize,
        session_id
      }
    });
    return response;
  },

  // Manage No Show Sessions
  manageNoShowSession: async (session_id, body) => {
    const response = await api.put(
      `${endpoints.dashboard.manageNoShowSession}${session_id}`,
      body
    );
    return response;
  },

  getNoShowCoachees: async (session_id) => {
    const response = await api.get(
      `${endpoints.dashboard.manageNoShowSession}${session_id}`
    );
    return response;
  },

  // #Engagements Session
  getEngagements: async (page_size, current_page, customer_id, is_dropdown) => {
    const response = await api.get(endpoints.dashboard.getEngagements, {
      params: {
        customer_id,
        current_page,
        page_size,
        is_dropdown,
        status: null
      }
    });
    return response.data;
  }
};

export const userConsoleAPIs = {
  users: {
    getUsersByRole: async ({
      role,
      coaching_program_id = null,
      current_page = 1,
      page_size = 5,
      customer_id = null,
      search
    }) => {
      const response = await api.get(`${URLs.users.GET_USERS_BY_ROLE}${role}`, {
        params: {
          page_size,
          current_page,
          customer_id,
          coaching_program_id,
          search
        }
      });
      return response.data;
    },
    getUsersByCoachingProgram: async ({
      role,
      coaching_id = null,
      current_page = 1,
      customer_id = null
    }) => {
      const response = await api.get(
        `${URLs.users.GET_USERS_FROM_COACHING_PROGRAM}${role}`,
        {
          params: {
            current_page,
            customer_id,
            coaching_id
          }
        }
      );
      return response.data;
    },
    getTotalGroups: async ({ current_page = 1, customer_id = null }) => {
      const response = await api.get(`${URLs.GET_TOTAL_GROUPS}`, {
        params: {
          current_page,
          customer_id
        }
      });
      return response;
    },
    getTotalSponsoringEntity: async ({
      current_page = 1,
      customer_id = null
    }) => {
      const response = await api.get(
        `${URLs.users.GET_TOTAL_SPONSORING_ENTITY}`,
        {
          params: {
            current_page,
            customer_id
          }
        }
      );
      return response;
    }
  },
  customers: {
    getCustomersOrganizations: async ({
      current_page = 1,
      page_size = 5,
      search
    }) => {
      const response = await api.get(
        URLs.customers.GET_CUSTOMER_ORGANIZATIONS,
        {
          params: {
            page_size,
            current_page,
            search
          }
        }
      );

      return response.data;
    }
  }
};

export const coachingProgramAPIs = {
  getCoachingPrograms: async ({
    current_page = 1,
    page_size = 5,
    customer_id = null,
    search
  }) => {
    const response = await api.get(
      URLs.coachingPrograms.GET_COACHING_PROGRAMS,
      {
        params: {
          page_size,
          current_page,
          customer_id,
          search
        }
      }
    );
    return response.data;
  }
};

export const engagementConsoleAPIs = {
  getAllEngagements: async ({
    customer_id,
    coaching_program_id,
    is_dropdown = false,
    ind_current_page,
    ind_page_size = null,
    group_current_page,
    group_page_size = null,
    searchQuery,
    status = null
  }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getAllEngagements}`,
      {
        params: {
          customer_id,
          coaching_program_id,
          is_dropdown,
          ind_current_page,
          ind_page_size,
          group_current_page,
          group_page_size,
          search: searchQuery,
          status
        }
      }
    );
    const { individual_engagement, group_engagement } = response.data;
    return { individual_engagement, group_engagement };
  },
  getEngagementByType: async ({ type, customer_id }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getEngagementByType}`,
      { params: { type, customer_id } }
    );

    return response;
  },
  getEngagementByUserType: async ({ type, customer_id }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getEngagementByUserType}`,
      { params: { type, customer_id } }
    );

    return response;
  },
  getAllCoachingPrograms: async ({ current_page, customer_id }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getAllCoachingPrograms}`,
      {
        params: {
          customer_id,
          current_page
        }
      }
    );
    return response.data;
  },
  getUsersByRole: async ({ role, current_page, customer_id }) => {
    const response = await api.get(
      `${endpoints.dashboard.getUsersByRoleUrl}${role}`,
      {
        params: {
          current_page,
          customer_id
        }
      }
    );

    return response.data;
  },
  getEngagementById: async (engagement_id) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getEngagementById}${engagement_id}`
    );
    return response;
  },
  getCoachesByCoachingProgram: async (coaching_program_id) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getCoachesByCoachingProgram}`,
      {
        params: {
          coaching_program_id
        }
      }
    );
    return response;
  },
  getCoachByCoachingProgram: async (coaching_program_id) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getCoachByCoachingProgram}`,
      {
        params: {
          coaching_program_id
        }
      }
    );
    return response;
  },
  getActionPlanByCoachingProgram: async (coaching_program_id) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getActionPlanByCoachingProgram}`,
      {
        params: {
          coaching_program_id
        }
      }
    );
    return response;
  },
  getSponsoringEntityByCoacheeORGroupID: async (id, paramType = "user_id") => {
    const response = await api.get(
      `${endpoints.engagementConsole.getSponsoringEntityByCoacheeORGroupID}`,
      {
        params: {
          [paramType]: id
        }
      }
    );
    return response.data;
  },
  createEngagements: async (body) => {
    const response = await api.post(
      `${endpoints.engagementConsole.createEngagements}`,
      body
    );

    return response.data;
  },
  updateEngagement: async (body, engagement_id) => {
    const response = await api.put(
      `${endpoints.engagementConsole.updateEngagement}${engagement_id}`,
      body
    );

    return response.data;
  },
  deleteEngagmentById: async (id) => {
    const response = await api.delete(
      `${endpoints.engagementConsole.deleteEngagmentById}/${id}`
    );
    return response;
  },
  getEngagementsbyme: async ({
    coaching_program_id,
    is_dropdown = false,
    ind_current_page,
    ind_page_size = null,
    group_current_page,
    group_page_size = null,
    searchQuery,
    status = null
  }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getEngagementsbyme}`,
      {
        params: {
          coaching_program_id,
          is_dropdown,
          ind_current_page,
          ind_page_size,
          group_current_page,
          group_page_size,
          search: searchQuery,
          status
        }
      }
    );
    const { individual_engagement, group_engagement } = response.data;
    return { individual_engagement, group_engagement };
  },
  viewReports: async ({ engagement_id, coachee_id }) => {
    const response = await api.get(URLs.engagementConsole.VIEW_REPORTS, {
      params: { engagement_id, coachee_id }
    });
    return response.data;
  },
  viewReportsForGroup: async ({ engagement_id }) => {
    const response = await api.get(
      `${URLs.engagementConsole.VIEW_REPORTS_FOR_GROUP}/${engagement_id}`
    );
    return response.data;
  },
  getActiveEngagementsForDashboard: async ({
    customer_id,
    coaching_program_id,
    ind_current_page,
    ind_page_size = null,
    group_current_page,
    group_page_size = null,
    status = null
  }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getAllEngagements}`,
      {
        params: {
          customer_id,
          coaching_program_id,
          ind_current_page,
          ind_page_size,
          group_current_page,
          group_page_size,
          status
        }
      }
    );

    return response.data;
  },
  getEngagementsBySponsor: async ({
    coaching_program_id,
    ind_current_page,
    ind_page_size = null,
    group_current_page,
    group_page_size = null,
    status = null,
    is_dropdown = false,
    searchQuery
  }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getEngagementBySponsor}`,
      {
        params: {
          coaching_program_id,
          ind_current_page,
          ind_page_size,
          group_current_page,
          group_page_size,
          status,
          is_dropdown,
          search: searchQuery
        }
      }
    );
    return response.data;
  },
  getFeedbackByEngagementID: async ({ engagement_id }) => {
    const response = await api.get(
      URLs.engagementConsole.GET_FEEDBACK_BY_ENGAGEMENT_ID,
      {
        params: {
          engagement_id
        }
      }
    );
    return response.data;
  },
  getCoacheeActionPlanByGroup: async ({ engagement_id }) => {
    const response = await api.get(
      `${endpoints.engagementConsole.getCoacheeActionPlanByGroup}/${engagement_id}`
    );
    return response;
  }
};

export const sessionsAPIs = {
  getUpcomingSessions: async ({
    current_page,
    page_size,
    customer_id,
    engagement_id,
    coaching_program_id
  }) => {
    const response = await api.get(URLs.sessions.GET_UPCOMING_SESSIONS, {
      params: {
        current_page,
        page_size,
        customer_id,
        engagement_id,
        coaching_program_id
      }
    });
    return response;
  },
  getUpcomingSessionsByCoachingProgram: async ({
    current_page,
    page_size,
    customer_id,
    coaching_id
  }) => {
    const response = await api.get(
      URLs.sessions.GET_UPCOMING_SESSIONS_BY_COACHING_PROGRAM,
      {
        params: {
          current_page,
          page_size,
          customer_id,
          coaching_id
        }
      }
    );
    return response;
  },

  getSessionsHistory: async ({
    current_page,
    page_size,
    customer_id,
    coaching_program_id
  }) => {
    const response = await api.get(URLs.sessions.GET_SESSIONS_HISTORY, {
      params: {
        page_size,
        current_page,
        customer_id,
        coaching_program_id
      }
    });
    return response.data;
  },
  getSessionsHistoryByCoachingProgram: async ({ customer_id, coaching_id }) => {
    const response = await api.get(
      URLs.sessions.GET_SESSIONS_HISTORY_BY_COACHING_PROGRAM,
      {
        params: {
          customer_id,
          coaching_id
        }
      }
    );
    return response.data;
  },
  downloadICSFile: async ({ date }) => {
    const response = await api.get(URLs.sessions.DOWNLOAD_ICS_FILE, {
      params: { date },
      responseType: "stream"
    });
    return response;
  }
};

// #ActionPlans
export const actionPlanTemplateAPIs = {
  getActionPlans: async (
    currentPage = 1,
    pageSize = 5,
    customer_id = null,
    search
  ) => {
    const response = await api.get(
      endpoints.actionPlanTemplate.getActionPlans,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          customer_id: customer_id,
          search
        }
      }
    );
    return response.data;
  },
  createActionPlan: async (body) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.createActionPlan}`,
      body
    );
    return response.data;
  },

  updateActionPlan: async (body, action_plan_template_id) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.updateActionPlan}/${action_plan_template_id}`,
      body
    );
    return response.data;
  },

  createObjective: async (body) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.createObjective}`,
      body
    );
    return response.data;
  },
  updateObjective: async (body, objective_id) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.updateObjective}/${objective_id}`,
      body
    );
    return response.data;
  },
  deleteObjective: async (objective_id) => {
    const response = await api.delete(
      `${endpoints.actionPlanTemplate.deleteObjective}/${objective_id}`
    );
    return response;
  },

  createGoal: async (body) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.createGoal}`,
      body
    );
    return response.data;
  },
  updateGoal: async (body, goal_id) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.updateGoal}/${goal_id}`,
      body
    );
    return response.data;
  },
  deleteGoal: async (goal_id) => {
    const response = await api.delete(
      `${endpoints.actionPlanTemplate.deleteGoal}/${goal_id}`
    );
    return response;
  },

  createTask: async (body) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.createTask}`,
      body
    );
    return response.data;
  },
  updateTask: async (body, task_id) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.updateTask}/${task_id}`,
      body
    );
    return response.data;
  },
  deleteTask: async (task_id) => {
    const response = await api.delete(
      `${endpoints.actionPlanTemplate.deleteTask}/${task_id}`
    );
    return response;
  },
  getTaskByID: async (task_id) => {
    const response = await api.get(
      `${endpoints.actionPlanTemplate.getTaskByID}/${task_id}`
    );
    return response;
  },

  createResource: async (body) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.createResource}`,
      body
    );
    return response.data;
  },
  updateResource: async (body, resource_id) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.updateResource}/${resource_id}`,
      body
    );
    return response.data;
  },
  deleteResource: async (resource_id) => {
    const response = await api.delete(
      `${endpoints.actionPlanTemplate.deleteResource}/${resource_id}`
    );
    return response;
  },
  getResourceByID: async (resource_id) => {
    const response = await api.get(
      `${endpoints.actionPlanTemplate.getResourceByID}${resource_id}`
    );
    return response;
  },

  getActionPlanByID: async (action_plan_template_id) => {
    const response = await api.get(
      `${endpoints.actionPlanTemplate.getActionPlanByID}/${action_plan_template_id}`
    );
    return response.data;
  },
  reorderActionPlan: async ({ body, action_plan_template_id }) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.reorderActionPlan}`,
      body,
      { params: { action_plan_template_id } }
    );
    return response.data;
  },
  mergeTemplates: async ({
    action_plan_template_id,
    merger_action_plan_template_id
  }) => {
    const response = await api.put(
      `${endpoints.actionPlanTemplate.mergeTemplates}`,
      null,
      { params: { action_plan_template_id, merger_action_plan_template_id } }
    );
    return response.data;
  },

  getCoacheeActionPlans: async ({ currentPage = 1 }) => {
    const response = await api.get(
      endpoints.actionPlanTemplate.getCoacheeActionPlans,
      {
        params: {
          current_page: currentPage
        }
      }
    );
    return response.data;
  },
  getCoacheeActionPlanByID: async (coachee_action_plan_id) => {
    const response = await api.get(
      `${endpoints.actionPlanTemplate.getCoacheeActionPlanByID}${coachee_action_plan_id}`
    );

    return response.data;
  },
  convertActionPlanToTemplate: async ({
    body,
    action_plan_id,
    customer_id
  }) => {
    const response = await api.post(
      `${endpoints.actionPlanTemplate.convertActionPlanToTemplate}`,
      body,
      { params: { action_plan_id, customer_id } }
    );
    return response.data;
  },
  mergeIntoCoacheeAction: async ({
    action_plan_id,
    merger_action_plan_template_id
  }) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.MERGE_COACHEE_ACTION_PLAN}`,
      null,
      { params: { action_plan_id, merger_action_plan_template_id } }
    );
    return response.data;
  },
  reorderCoacheeActionPlan: async ({ body, action_plan_id }) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.REORDER_COACHEE_ACTION_PLAN}`,
      body,
      { params: { action_plan_id } }
    );
    return response.data;
  },

  updateCoacheeActionPlan: async (body, coachee_action_plan_id) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.UPDATE_COACHEE_ACTION_PLAN}/${coachee_action_plan_id}`,
      body
    );
    return response.data;
  },

  createCoacheeObjective: async (body) => {
    const response = await api.post(
      `${URLs.actionPlanTemplate.ADD_COACHEE_OBJECTIVE}`,
      body
    );
    return response.data;
  },
  updateCoacheeObjective: async (body, coachee_action_plan_id) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.UPDATE_COACHEE_OBJECTIVE}/${coachee_action_plan_id}`,
      body
    );
    return response.data;
  },
  deleteCoacheeObjective: async (coachee_objective_id) => {
    const response = await api.delete(
      `${URLs.actionPlanTemplate.DELETE_COACHEE_OBJECTIVE}/${coachee_objective_id}`
    );
    return response;
  },

  createCoacheeGoal: async (body) => {
    const response = await api.post(
      `${URLs.actionPlanTemplate.ADD_COACHEE_GOAL}`,
      body
    );
    return response.data;
  },
  updateCoacheeGoal: async (body, goal_id) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.UPDATE_COACHEE_GOAL}/${goal_id}`,
      body
    );
    return response.data;
  },
  deleteCoacheeGoal: async (goal_id) => {
    const response = await api.delete(
      `${URLs.actionPlanTemplate.DELETE_COACHEE_GOAL}/${goal_id}`
    );
    return response;
  },

  createCoacheeTask: async (body) => {
    const response = await api.post(
      `${URLs.actionPlanTemplate.ADD_COACHEE_TASK}`,
      body
    );
    return response.data;
  },
  updateCoacheeTask: async (body, task_id) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.UPDATE_COACHEE_TASK}/${task_id}`,
      body
    );
    return response.data;
  },
  deleteCoacheeTask: async (task_id) => {
    const response = await api.delete(
      `${URLs.actionPlanTemplate.DELETE_COACHEE_TASK}/${task_id}`
    );
    return response;
  },
  getCoacheeTaskByID: async (task_id) => {
    const response = await api.get(
      `${URLs.actionPlanTemplate.GET_COACHEE_TASK_BY_ID}/${task_id}`
    );
    return response;
  },

  createCoacheeResource: async (body) => {
    const response = await api.post(
      `${URLs.actionPlanTemplate.ADD_COACHEE_RESOURCE}`,
      body
    );
    return response.data;
  },
  updateCoacheeResource: async (body, resource_id) => {
    const response = await api.put(
      `${URLs.actionPlanTemplate.UPDATE_COACHEE_RESOURCE}/${resource_id}`,
      body
    );
    return response.data;
  },
  deleteCoacheeResource: async (resource_id) => {
    const response = await api.delete(
      `${URLs.actionPlanTemplate.DELETE_COACHEE_RESOURCE}/${resource_id}`
    );
    return response;
  },
  getCoacheeResourceByID: async (resource_id) => {
    const response = await api.get(
      `${URLs.actionPlanTemplate.GET_COACHEE_RESOURCE_BY_ID}/${resource_id}`
    );
    return response;
  },

  // INTERACTIVE TOOL - LISTING TOOL
  ListingTool: {
    createListingTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.ListingTool.CREATE_LISTING_TOOL,
        body
      );
      return response.data;
    },
    getListingTool: async ({ resource_id, listing_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.ListingTool.GET_LISTING_TOOL,
        {
          params: {
            resource_id,
            listing_tool_id
          }
        }
      );
      return response.data;
    },
    updateListingTool: async ({ listing_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.ListingTool.UPDATE_LISTING_TOOL}/${listing_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeListingTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.ListingTool.CREATE_COACHEE_LISTING_TOOL,
        body
      );
      return response.data;
    },
    getCoacheeListingTool: async ({ resource_id, listing_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.ListingTool.GET_COACHEE_LISTING_TOOL,
        {
          params: {
            resource_id,
            listing_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeListingTool: async ({ listing_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.ListingTool.UPDATE_COACHEE_LISTING_TOOL}/${listing_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeListingToolResponse: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.ListingTool.CREATE_COACHEE_RESPONSE,
        body
      );
      return response.data;
    },
    getCoacheeListingToolResponse: async ({
      resource_id,
      response_listing_tool_id,
      coachee_action_plan_listing_id
    }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.ListingTool.GET_COACHEE_RESPONSE,
        {
          params: {
            resource_id,
            response_listing_tool_id,
            coachee_action_plan_listing_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeListingToolResponse: async ({ response_listing_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.ListingTool.UPDATE_COACHEE_RESPONSE}/${response_listing_id}`,
        body
      );
      return response.data;
    },
    getListingToolsByActionPlanId: async ({ action_plan_template_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.ListingTool.GET_LISTING_TOOLS_BY_ACTION_PLAN_ID,
        {
          params: {
            action_plan_template_id
          }
        }
      );
      return response.data;
    },
    getListingToolsByCoacheeActionPlanId: async ({
      action_plan_template_id
    }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.ListingTool
          .GET_LISTING_TOOLS_BY_COACHEE_ACTION_PLAN_ID,
        {
          params: {
            coachee_action_plan_id: action_plan_template_id
          }
        }
      );
      return response.data;
    }
  },
  MatrixTool: {
    createMatrixTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.MatrixTool.CREATE_MATRIX_TOOL,
        body
      );
      return response.data;
    },
    getMatrixTool: async ({ resource_id, matrix_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.MatrixTool.GET_MATRIX_TOOL,
        {
          params: {
            resource_id,
            matrix_tool_id
          }
        }
      );
      return response.data;
    },
    updateMatrixTool: async ({ matrix_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.MatrixTool.UPDATE_MATRIX_TOOL}/${matrix_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeMatrixTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.MatrixTool.CREATE_COACHEE_MATRIX_TOOL,
        body
      );
      return response.data;
    },

    getCoacheeMatrixTool: async ({ resource_id, matrix_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.MatrixTool.GET_COACHEE_MATRIX_TOOL,
        {
          params: {
            resource_id,
            matrix_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeMatrixTool: async ({ matrix_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.MatrixTool.UPDATE_COACHEE_MATRIX_TOOL}/${matrix_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeResponseForMatrixTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.MatrixTool.CREATE_COACHEE_MATRIX_RESPONSE,
        body
      );
      return response.data;
    },
    getCoacheeMatrixToolResponse: async ({ resource_id, matrix_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.MatrixTool.GET_COACHEE_MATRIX_RESPONSE,
        {
          params: {
            resource_id,
            matrix_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeMatrixToolResponse: async ({ response_matrix_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.MatrixTool.UPDATE_COACHEE_MATRIX_RESPONSE}/${response_matrix_id}`,
        body
      );
      return response.data;
    }
  },
  TriangleTool: {
    createTriangleTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.TriangleTool.CREATE_TRIANGLE_TOOL,
        body
      );
      return response.data;
    },
    getTriangleTool: async ({ resource_id, triangle_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.TriangleTool.GET_TRIANGLE_TOOL,
        {
          params: {
            resource_id,
            triangle_tool_id
          }
        }
      );
      return response.data;
    },
    updateTriangleTool: async ({ triangle_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.TriangleTool.UPDATE_TRIANGLE_TOOL}/${triangle_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeTriangleTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.TriangleTool.CREATE_COACHEE_TRIANGLE_TOOL,
        body
      );
      return response.data;
    },
    getCoacheeTriangleTool: async ({ resource_id, triangle_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.TriangleTool.GET_COACHEE_TRIANGLE_TOOL,
        {
          params: {
            resource_id,
            triangle_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeTriangleTool: async ({ triangle_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.TriangleTool.UPDATE_COACHEE_TRIANGLE_TOOL}/${triangle_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeResponseForTriangleTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.TriangleTool.CREATE_COACHEE_TRIANGLE_RESPONSE,
        body
      );
      return response.data;
    },
    getCoacheeTriangleToolResponse: async ({
      resource_id,
      triangle_tool_id
    }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.TriangleTool.GET_COACHEE_TRIANGLE_RESPONSE,
        {
          params: {
            resource_id,
            triangle_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeTriangleToolResponse: async ({
      response_triangle_id,
      body
    }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.TriangleTool.UPDATE_COACHEE_TRIANGLE_RESPONSE}/${response_triangle_id}`,
        body
      );
      return response.data;
    }
  },
  SalesMeetingPlanner: {
    createSalesMeeting: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.SalesMeetingPlanner.CREATE_SALES_MEETING,
        body
      );
      return response.data;
    },
    getSalesMeeting: async ({ resource_id, sale_meeting_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.SalesMeetingPlanner.GET_SALES_MEETING,
        {
          params: {
            resource_id,
            sale_meeting_id
          }
        }
      );
      return response.data;
    },
    updateSalesMeeting: async ({ body, sale_meeting_id }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.SalesMeetingPlanner.UPDATE_SALES_MEETING}/${sale_meeting_id}`,
        body
      );
      return response.data;
    },
    createCoacheeSalesMeeting: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.SalesMeetingPlanner
          .CREATE_COACHEE_SALES_MEETING,
        body
      );
      return response.data;
    },
    getCoacheeSalesMeeting: async ({ resource_id, sale_meeting_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.SalesMeetingPlanner.GET_COACHEE_SALES_MEETNIG,
        {
          params: {
            resource_id,
            sale_meeting_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeSalesMeeting: async ({ body, sale_meeting_id }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.SalesMeetingPlanner.UPDATE_COACHEE_SALES_MEETING}/${sale_meeting_id}`,
        body
      );
      return response.data;
    },
    coacheePortal: {
      createSalesMeeting: async ({ body }) => {
        const response = await api.post(
          URLs.actionPlanTemplate.SalesMeetingPlanner.coachee
            .CREATE_SALES_MEETING,
          body
        );
        return response.data;
      },
      getSalesMeeting: async ({ resource_id, sale_meeting_id }) => {
        let response_sale_meeting_id = sale_meeting_id;
        const response = await api.get(
          URLs.actionPlanTemplate.SalesMeetingPlanner.coachee.GET_SALES_MEETING,
          {
            params: {
              resource_id,
              response_sale_meeting_id
            }
          }
        );
        return response.data;
      },
      updateSalesMeeting: async ({ body, sale_meeting_id }) => {
        let response_sale_meeting_id = sale_meeting_id;
        const response = await api.put(
          `${URLs.actionPlanTemplate.SalesMeetingPlanner.coachee.UPDATE_SALES_MEETING}/${response_sale_meeting_id}`,
          body
        );
        return response.data;
      }
    }
  },
  PreDefinedFunction: {
    createPreDefinedFunction: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.PreDefinedFunction.CREATE_PRE_DEFINED_FUNCTION,
        body
      );
      return response.data;
    },
    getPreDefinedFunction: async ({ resource_id, pre_define_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.PreDefinedFunction.GET_PRE_DEFINED_FUNCTION,
        {
          params: {
            resource_id,
            pre_define_id
          }
        }
      );
      return response.data;
    },
    updatePreDefinedFunction: async ({ pre_define_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.PreDefinedFunction.UPDATE_PRE_DEFINED_FUNCTION}/${pre_define_id}`,
        body
      );
      return response.data;
    },
    createCoacheePreDefinedFunction: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.PreDefinedFunction
          .CREATE_COACHEE_PRE_DEFINED_FUNCTION,
        body
      );
      return response.data;
    },
    getCoacheePreDefinedFunction: async ({ resource_id, pre_define_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.PreDefinedFunction
          .GET_COACHEE_PRE_DEFINED_FUNCTION,
        {
          params: {
            resource_id,
            pre_define_id
          }
        }
      );
      return response.data;
    },
    coacheePortal: {
      createPreDefinedFunction: async ({ body }) => {
        const response = await api.post(
          URLs.actionPlanTemplate.PreDefinedFunction.coachee
            .CREATE_PRE_DEFINED_FUNCTION,
          body
        );
        return response.data;
      },
      getPreDefinedFunction: async ({
        resource_id,
        response_pre_define_id
      }) => {
        const response = await api.get(
          URLs.actionPlanTemplate.PreDefinedFunction.coachee
            .GET_PRE_DEFINED_FUNCTION,
          {
            params: {
              resource_id,
              response_pre_define_id
            }
          }
        );
        return response.data;
      },
      updatePreDefinedFunction: async ({ response_pre_define_id, body }) => {
        const response = await api.put(
          `${URLs.actionPlanTemplate.PreDefinedFunction.coachee.UPDATE_PRE_DEFINED_FUNCTION}/${response_pre_define_id}`,
          body
        );
        return response.data;
      }
    }
  },
  AssessmentTool: {
    createAssessmentTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.AssessmentTool.CREATE_ASSESSMENT_TOOL,
        body
      );
      return response.data;
    },
    getAssessmentTool: async ({ resource_id, assesment_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.AssessmentTool.GET_ASSESSMENT_TOOL,
        {
          params: {
            resource_id,
            assesment_tool_id
          }
        }
      );
      return response.data;
    },
    updateAssessmentTool: async ({ assesment_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.AssessmentTool.UPDATE_ASSESSMENT_TOOL}/${assesment_tool_id}`,
        body
      );
      return response.data;
    },
    createCoacheeAssessmentTool: async ({ body }) => {
      const response = await api.post(
        URLs.actionPlanTemplate.AssessmentTool.CREATE_COACHEE_ASSESSMENT_TOOL,
        body
      );
      return response.data;
    },
    getCoacheeAssessmentTool: async ({ resource_id, assesment_tool_id }) => {
      const response = await api.get(
        URLs.actionPlanTemplate.AssessmentTool.GET_COACHEE_ASSESSMENT_TOOL,
        {
          params: {
            resource_id,
            assesment_tool_id
          }
        }
      );
      return response.data;
    },
    updateCoacheeAssessmentTool: async ({ assesment_tool_id, body }) => {
      const response = await api.put(
        `${URLs.actionPlanTemplate.AssessmentTool.UPDATE_COACHEE_ASSESSMENT_TOOL}/${assesment_tool_id}`,
        body
      );
      return response.data;
    },
    coacheePortal: {
      createAssessmentTool: async ({ body }) => {
        const response = await api.post(
          URLs.actionPlanTemplate.AssessmentTool.coachee.CREATE_ASSESSMENT_TOOL,
          body
        );
        return response.data;
      },
      updateAssessmentTool: async ({ response_assesment_tool_id, body }) => {
        const response = await api.put(
          `${URLs.actionPlanTemplate.AssessmentTool.coachee.UPDATE_ASSESSMENT_TOOL}/${response_assesment_tool_id}`,
          body
        );
        return response.data;
      }
    }
  }
};

export const adminControl = {
  videoSessionUpdate: async (value, customer_id) => {
    const response = await api.put(
      `${endpoints.admincontrol.VideoSessionUpdate}${customer_id}`,
      value
    );
    return response;
  },
  controlFeedback: async (body, customer_id) => {
    const response = await api.put(
      `${endpoints.admincontrol.controlFeedBack}${customer_id}`,
      body
    );
    return response;
  }
};
//Access Audit log
export const accessAuditLog = {
  getAllAuditLogs: async (
    customer_id,
    user_name,
    from_date,
    to_date,
    action_type,
    pageSize,
    currentPage
  ) => {
    const response = await api.get(`${endpoints.accessAuditLog.getAuditLogs}`, {
      params: {
        customer_id,
        user_name,
        from_date,
        to_date,
        action_type,
        page_size: pageSize,
        current_page: currentPage
      }
    });
    return response;
  },
  getAuditActionType: async () => {
    const response = await api.get(`${endpoints.accessAuditLog.getActionType}`);
    return response;
  }
};
//Manage Documents
export const manageDocumentConsole = {
  createDocument: async (body) => {
    const response = await api.post(
      `${endpoints.manageDocument.createDocument}`,
      body
    );
    return response;
  },
  getAllUploadedDocument: async (
    currentPage,
    pageSize,
    search,
    customer_id
  ) => {
    const response = await api.get(
      `${endpoints.manageDocument.getDocumentUploadedby}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search,
          customer_id
        }
      }
    );
    return response;
  },
  getDocumentById: async (id, customer_id = null) => {
    const response = await api.get(
      `${endpoints.manageDocument.getDocumentbyDocId}${id}`,
      {
        params: {
          customer_id
        }
      }
    );
    return response;
  },
  updateDocument: async (body, id) => {
    const response = await api.put(
      `${endpoints.manageDocument.updateDocument}${id}`,
      body
    );
    return response;
  },
  assignDocument: async (body) => {
    const response = await api.post(
      `${endpoints.manageDocument.assignDocument}`,
      body
    );
    return response;
  },
  getAssignedDocuments: async (
    currentPage,
    pageSize,
    coachee_id,
    search,
    customer_id
  ) => {
    const response = await api.get(
      `${endpoints.manageDocument.getAssignedDocuments}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search,
          customer_id,
          coachee_id
        }
      }
    );
    return response;
  },
  getSystemAdminAssignedDoc: async (
    currentPage,
    pageSize,
    search,
    customer_id
  ) => {
    const response = await api.get(
      `${endpoints.manageDocument.getSystemAdminAssignedDoc}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search,
          customer_id
        }
      }
    );
    return response;
  },
  //get Coach , Coachee , ProgramManager , CoachingProgram , Groups for Manage Document
  getCoachUser: async () => {
    const response = await api.get(`${endpoints.manageDocument.getCoachUser}`);
    return response;
  },
  getCoacheeUser: async () => {
    const response = await api.get(
      `${endpoints.manageDocument.getCoacheeUser}`
    );
    return response;
  },
  getProgramManager: async () => {
    const response = await api.get(
      `${endpoints.manageDocument.getProgramManager}`
    );
    return response;
  },
  getCoachingProgram: async () => {
    const response = await api.get(
      `${endpoints.manageDocument.getCoachingProgram}`
    );
    return response;
  }
};
//Coachee Dashboard
export const getCoacheeSession = {
  getMyUpcomingSessions: async (currentPage, pageSize, coachee_id) => {
    const response = await api.get(
      `${endpoints.coacheeSessions.getMyUpcomingSession}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          coachee_id
        }
      }
    );
    return response;
  },
  getMySessionHistory: async (currentPage, pageSize) => {
    const response = await api.get(
      `${endpoints.coacheeSessions.getMySessionHistory}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage
        }
      }
    );
    return response;
  },
  getProposedConfirmedSession: async (currentPage, pageSize) => {
    const response = await api.get(
      `${endpoints.coacheeSessions.getProposedConfirmSession}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage
        }
      }
    );
    return response;
  }
};
export const coacheeActionPlan = {
  getActionPlan: async (currentPage, pageSize) => {
    const response = await api.get(
      `${endpoints.coacheeActionPlan.getActionPlan}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage
        }
      }
    );
    return response;
  }
};
export const getCoacheeActionPlansbyEngagment = {
  getActionPlansbyEngagment: async (currentPage, pageSize, id) => {
    const response = await api.get(
      `${endpoints.coacheeActionPlan.getCoacheeActionPlansByEngagment}${id}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage
        }
      }
    );
    return response;
  }
};
export const getCoacheeNodeProgress = {
  getCoacheeNode_Progress: async (currentPage, pageSize, engagement_id) => {
    const response = await api.get(
      `${endpoints.coacheeActionPlan.getCoacheeNodeProgress}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          engagement_id
        }
      }
    );
    return response;
  }
};
export const getEngagmentByCoachee = {
  getEngagmentByCoachee: async (currentPage, pageSize, coachee_id) => {
    const response = await api.get(
      `${endpoints.coacheeActionPlan.getEngagmentByCoachee}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          coachee_id
        }
      }
    );
    return response;
  }
};
export const coachingProgramByMe = {
  getCoachingProgramByMe: async (currentPage, pageSize, search) => {
    const response = await api.get(
      `${URLs.coachingProgramByMe.GET_COACHING_PROGRAM_BY_ME}`,
      {
        params: {
          page_size: pageSize,
          current_page: currentPage,
          search
        }
      }
    );
    return response.data;
  }
};
export const coacheeProgress = {
  getMyProgress: async ({ currentPage, pageSize, coaching_program_id }) => {
    const response = await api.get(
      `${endpoints.coacheeProgress.getMyProgress}`,
      {
        params: {
          current_page: currentPage,
          page_size: pageSize,
          coaching_program_id
        }
      }
    );
    return response.data;
  },
  getOverAllCoacheeProgress: async ({ coachee_id }) => {
    const response = await api.get(
      `${URLs.coacheeProgress.getOverAllCoacheeProgress}`,
      {
        params: {
          coachee_id
        }
      }
    );
    return response.data;
  }
};
export const activityReport = {
  getActivityReports: async ({
    engagement_id,
    coaching_program_id,
    coachee_id,
    coach_id,
    start_date,
    end_date,
    current_page,
    page_size,
    searchQuery
  }) => {
    const response = await api.get(
      `${endpoints.dashboard.getActivityReports}`,
      {
        params: {
          engagement_id,
          coaching_program_id,
          coachee_id,
          coach_id,
          start_date,
          end_date,
          current_page,
          page_size,
          searchQuery
        }
      }
    );
    return response;
  }
};

export const coacheeDashboardAPIs = {
  todoList: {
    getAll: async ({ page_size, current_page, coachee_id }) => {
      const response = await api.get(URLs.coachee_dashboard.ToDoList.GET_ALL, {
        params: {
          page_size,
          current_page,
          coachee_id
        }
      });
      return response;
    },
    markCompleted: async ({ body }) => {
      const response = await api.put(
        URLs.coachee_dashboard.ToDoList.MARK_COMPLETE,
        body
      );
      return response;
    },
    mark_obj_goal_completed: async ({ body }) => {
      const response = await api.put(
        URLs.coachee_dashboard.ToDoList.MARK_OBJ_GOAL_COMPLETE,
        body
      );
      return response;
    }
  }
};

// #region helper methods
const genericPostMethod = async (endpoint, body) => {
  const response = await api.post(endpoint, body);
  return response;
};

// #endregion
//#azure SAS Token
export const sas_Token = {
  getSASToken: async (container_type) => {
    const response = await api.get(endpoints.sastoken.getSASToken, {
      params: {
        container_type
      }
    });
    return response;
  }
};
export const feedbackReminder = {
  getFeedbackReminder: async () => {
    const response = await api.get(`${URLs.SESSION_FEEDBACK_REMINDER}`);
    return response;
  }
};
export const tooltip = {
  getTooltip: async ({ tool_name }) => {
    const response = await api.get(`${URLs.tooltip.TOOLTIP_GET}`, {
      params: {
        tool_name
      }
    });
    return response.data;
  },
  editTooltip: async ({ field_name, tool_name, body }) => {
    const response = await api.put(
      `${URLs.tooltip.TOOLTIP_EDIT}/${field_name}/${tool_name}`,
      body
    );
    return response.data;
  }
};
