import { URLs } from "utils/constants";

const { BASE_URL_ALPHA, BASE_URL_BETA, BASE_URL_PROD } = URLs;

const alpha = {
  apiGateway: {
    REGION: "",
    URL: BASE_URL_ALPHA,
  },
};

const beta = {
  apiGateway: {
    REGION: "",
    URL: BASE_URL_BETA,
  },
};

const prod = {
  apiGateway: {
    REGION: "",
    URL: BASE_URL_PROD,
  },
};

export const Config = {
  // Add common config values here

  // Default to alpha if not set
  ...(process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "beta"
    ? beta
    : process.env.REACT_APP_STAGE === "alpha"
    ? alpha
    : alpha),
};
