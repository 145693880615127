const form = {
  customer_id: {
    name: "customer_id",
    label: "Customer Organization *",
    type: "select",
    options: [],
    errorMsg: "Customer Organization is required.",
  },
  group_title: {
    name: "group_title",
    label: "Group Title *",
    type: "text",
    errorMsg: "Group Title is required.",
  },
  group_description: {
    name: "group_description",
    label: "Group Description",
    type: "textarea",
    errorMsg: "Group Description is required.",
  },
  participatingCoachees: {
    name: "participatingCoachees",
    label: "Participating Coachees",
    type: "grid",
    gridPage: "group",
    rows: {
      currentPage: 1,
      data: [],
      pageSize: 5,
      totalItem: 5,
      totalPage: 1,
    },
  },
};

export default form;
