import * as Yup from "yup";
import form from "./form";

const { group_title, customer_id } = form;

const validations = Yup.object().shape({
  [group_title.name]: Yup.string().required(group_title.errorMsg),
  [customer_id.name]: Yup.string().required(customer_id.errorMsg).nullable(),
});

export default validations;
