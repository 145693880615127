import moment from "moment";
import journalEnteryForm from "./journalEnteryForm";

const { entry_title, entry_description, session_id, entry_date } = journalEnteryForm;

const journalEntryInitialValues = {
  [entry_title.name]: "",
  [entry_description.name]: "",
  [entry_date.name]: moment().endOf("day"),
};

export default journalEntryInitialValues;
