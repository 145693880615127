// #region Imports
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import signIn from "../../assets/images/signin.svg";
// @mui material components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";

// Material UI icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "./BasicLayout.jsx";
import { Icon } from "@iconify/react";
// custom components
import LoadingButton from "components/custom-components/LoadingButton.jsx";

// util imports
import { auth } from "utils/apiMethods.js";
import { capitalizeFirstLetters } from "utils/helper-method.js";

import { useDispatch } from "react-redux";
import { logIn } from "redux-store/features/auth/authSlice.js";
import { getKeywords } from "redux-store/features/dashboard/keywordSlice";
import { getRolesAndPermissions } from "redux-store/features/dashboard/roleAndPermissions";
import axios from "axios";
import { endpoints } from "utils/axios-config";
import { parseAuthToken } from "utils/helper-method";
import { Config } from "config";
import { setSelectedCustomer } from "redux-store/features/dashboard/customersSlice";

// #endregion

function SignIn() {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("remember-me") !== null
      ? JSON.parse(localStorage.getItem("remember-me")).isRememberMeChecked
      : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [SignInError, setSignInError] = useState({
    isError: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    // state values for userName & password
    initialValues: {
      userName: localStorage.getItem("remember-me")
        ? JSON.parse(localStorage.getItem("remember-me")).user_name
        : "",
      password: "",
    },
    // this onSubmit gets called when all fields are validated successfully, (after button click)
    onSubmit: async (values) => {
      setIsLoading(true); // set isLoading to true to show loading icon
      let body = { user_name: values.userName, password: values.password }; // post request body
      try {
        let result = await auth.login(body);
        if (result?.data?.token) {
          let token = result?.data?.token;
          const customer_id = parseAuthToken(token)?.customerid;
          setUserDetailsForRememberMeFeature();

          if (customer_id) {
            let response = await axios.get(
              `${Config.apiGateway.URL}${endpoints.dashboard.getCustomerById}${customer_id}`,
              {
                //...data
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.data) {
              await dispatch(setSelectedCustomer(response.data));
            }
          }
          localStorage.setItem("page_title", "");
          setIsLoading(false);

          await dispatch(
            logIn({
              token: result.data.token,
              user_name: result.data.user_name || values.userName,
              user_role: result.data.user_role,
              refresh_token: result.data.Refresh_token,
            })
          );
          dispatch(getKeywords());
          dispatch(getRolesAndPermissions());
        }
      } catch (err) {
        console.log(err);
        setSignInError({
          ...SignInError,
          isError: true,
          message:
            (err.response?.data?.error_message &&
              err.response?.data?.error_message[0]) ||
            err.response?.data?.title ||
            err.response?.data?.error?.message ||
            "Error",
        }); // show sign in error on catch
        hideLoaderAndErrors();
      }
    },
    // set up validations here
    validationSchema: Yup.object({
      userName: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),
  });

  const { userName, password } = formik.values;

  const handleSetRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const hideLoaderAndErrors = () => {
    setIsLoading(false); // set isLoading to false to hide loading icon
    setTimeout(() => {
      setSignInError({ ...SignInError, isError: false, message: "" });
    }, 3000);
  };

  const setUserDetailsForRememberMeFeature = () => {
    if (rememberMe) {
      let rememberMeObj = {
        isRememberMeChecked: true,
        user_name: userName,
      };
      localStorage.setItem("remember-me", JSON.stringify(rememberMeObj));
    } else {
      localStorage.removeItem("remember-me");
    }
  };
  const handlesubmit = formik.handleSubmit;
  return (
    <BasicLayout>
      <MDBox mx={2} mt={-3} p={2} mb={6} textAlign="center">
        {/* Heading */}
        <MDTypography
          variant="h1"
          fontWeight="medium"
          color="white"
          mt={1}
          sx={{ fontWeight: "400" }}
        >
          Sign In
        </MDTypography>
      </MDBox>

      {/* Form */}
      <MDBox pt={4} pb={3} px={3}>
        <MDBox
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handlesubmit();
            }
          }}
        >
          {/* userName Section*/}
          <MDBox mb={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                htmlFor="userName"
                sx={{
                  color: "white !important",
                  padding: "0 15px 5px 0 !important",
                }}
              >
                Username *
              </InputLabel>
              <Input
                id="userName"
                type="text"
                name="userName"
                value={userName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="userName"
                error={
                  formik.touched.userName && formik.errors.userName
                    ? true
                    : false
                }
                startAdornment={
                  <InputAdornment position="start">
                    <Icon
                      icon={"bxs:user"}
                      color="white"
                      width={25}
                      sx={{ marginLeft: "-5px" }}
                    />
                  </InputAdornment>
                }
                inputProps={{
                  form: {},
                }}
              />
            </FormControl>
            {formik.touched.userName && formik.errors.userName ? (
              <MDTypography variant="caption" color="error" fontWeight="bold">
                {formik.errors.userName}
              </MDTypography>
            ) : null}
          </MDBox>

          {/* Password Section*/}
          <MDBox mb={4} component="form">
            <FormControl variant="standard" fullWidth>
              <InputLabel
                htmlFor="password"
                sx={{
                  color: "white !important",
                  padding: "0 15px 5px 0 !important",
                }}
              >
                Password *
              </InputLabel>
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                inputProps={{
                  // autoComplete: "new-password",
                  form: {
                    // autoComplete: "none",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon
                      icon={"bxs:lock-alt"}
                      color="white"
                      width={25}
                      sx={{ marginLeft: "-5px" }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      color="white"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formik.touched.password && formik.errors.password ? (
              <MDTypography variant="caption" color="error" fontWeight="bold">
                {formik.errors.password}
              </MDTypography>
            ) : null}
          </MDBox>

          {/* Remember Me & Forgot Password Section */}
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            ml={-1}
          >
            {/* Remember Me  */}
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="white"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              <Checkbox
                checked={rememberMe}
                inputProps={{ "aria-label": "controlled" }}
                label="remember-me"
              />
              Remember me
            </MDTypography>

            {/* Forgot Password */}
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="white"
              component={Link}
              to="/forgot-password"
              id="btn-forgotPassword"
              sx={{ paddingRight: 0, fontWeight: 400 }}
            >
              Forgot your password?
            </MDTypography>
          </MDBox>

          {/* Sign-In Button Section */}
          <MDBox
            mt={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              isLoading={isLoading}
              handleSubmit={formik.handleSubmit}
              text="sign in"
              Icon={
                <img
                  src={signIn}
                  alt="signin"
                  style={{
                    height: 30,
                    width: "30px",
                    objectFit: "cover",
                    marginTop: "2px",
                  }}
                />
              }
            />
          </MDBox>

          {/* Error Section */}
          {SignInError.isError && (
            <>
              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  //color="error"
                  sx={{ color: "#ffff" }}
                  textAlign="center"
                  fontWeight="medium"
                >
                  {capitalizeFirstLetters(SignInError.message.split(" "))}
                </MDTypography>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default SignIn;
