import { createSlice } from "@reduxjs/toolkit";
export const coacheeTabsSlice = createSlice({
  name: "coacheeTabs",
  initialState: {
    activePanel: 1,
    subActivePanel: false,
    authorValuesChanged: false,
    interactiveToolsList: null,
    isExistingActionPlan: false
  },
  reducers: {
    setActivePanel: (state, { payload: value }) => {
      state.activePanel = value;
    },
    setSubActivePanel: (state, { payload }) => {
      state.subActivePanel = payload;
    },
    setAuthorValuesChanged: (state, { payload }) => {
      state.authorValuesChanged = payload;
    },
    setInteractiveToolsList: (state, { payload }) => {
      state.interactiveToolsList = payload;
    },
    setIsExistingActionPlan: (state, { payload }) => {
      state.isExistingActionPlan = payload;
    }
  }
});
export const {
  setActivePanel,
  setSubActivePanel,
  setAuthorValuesChanged,
  setInteractiveToolsList,
  setIsExistingActionPlan
} = coacheeTabsSlice.actions;
export default coacheeTabsSlice.reducer;
