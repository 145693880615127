import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  dashboard,
  engagementConsoleAPIs,
  getEngagmentByCoachee,
  coachingProgramByMe,
} from "utils/apiMethods";

const initialState = {
  individual_data: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
  },
  group_data: {
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
  },
  allItemsCheckedIndividual: false,
  allItemsCheckedGroup: false,
  isAnyItemCheckedGroup: false,
  isAnyItemCheckedInd: false,
  isAnyItemChecked: true,
  searchQuery: "",
  checkedItemsIndividual: [],
  checkedItemsGroup: [],
  coachingProgramsDropdown: [],
  isCoachProgramsLoading: false,
  selectedCoachingProgram: null,
  engagementsByCoachee: [],
  selectedEngagement: null,
  feedbackData: {
    loading: false,
    currentPage: 1,
    data: [],
    pageSize: 5,
    totalItem: 5,
    totalPage: 1,
    error: null,
  },
};
export const engagementConsoleSlice = createSlice({
  name: "engagementConsole",
  initialState,
  reducers: {
    resetDataTable: (state) => {
      state = initialState;
    },

    setAllItemsCheckedIndividual: (
      state,
      { payload: allItemsCheckedIndividual }
    ) => {
      state.allItemsCheckedIndividual = allItemsCheckedIndividual;
      state.isAnyItemCheckedInd = allItemsCheckedIndividual;
      if (allItemsCheckedIndividual) {
        state.checkedItemsIndividual = state.individual_data.data;
      } else {
        state.checkedItemsIndividual = [];
      }

      state.individual_data.data.map((item) => {
        item.isCheckedIndividual = allItemsCheckedIndividual;
        return item;
      });
    },
    setAllItemsCheckedGroup: (state, { payload: allItemsCheckedGroup }) => {
      state.allItemsCheckedGroup = allItemsCheckedGroup;
      state.isAnyItemCheckedGroup = allItemsCheckedGroup;
      if (allItemsCheckedGroup) {
        state.checkedItemsGroup = state.group_data.data;
      } else {
        state.checkedItemsGroup = [];
      }
      state.group_data.data.map((item) => {
        item.isCheckedGroup = allItemsCheckedGroup;
        return item;
      });
    },

    setItemCheckedIndividual: (state, { payload: id }) => {
      let matchedItem = state?.individual_data?.data?.find(
        (item) => item.engagement_id === id
      );
      matchedItem.isCheckedIndividual = !matchedItem?.isCheckedIndividual;
      // adding and removing data from checked item array
      if (matchedItem.isCheckedIndividual) {
        state.checkedItemsIndividual = [
          ...state.checkedItemsIndividual,
          matchedItem,
        ];
      } else {
        state.checkedItemsIndividual = state.checkedItemsIndividual.filter(
          (item) => {
            return item.engagement_id !== matchedItem.engagement_id;
          }
        );
      }

      // check if any item is checked
      if (
        state.individual_data.data.some(
          (item) => item.isCheckedIndividual === true
        )
      ) {
        state.isAnyItemCheckedInd = true;
      } else {
        state.isAnyItemCheckedInd = false;
      }
    },
    setItemCheckedGroup: (state, { payload: id }) => {
      let matchedItem = state?.group_data?.data?.find(
        (item) => item.engagement_id === id
      );
      matchedItem.isCheckedGroup = !matchedItem?.isCheckedGroup;
      // adding and removing data from checked item array
      if (matchedItem.isCheckedGroup) {
        state.checkedItemsGroup = [...state.checkedItemsGroup, matchedItem];
      } else {
        state.checkedItemsGroup = state.checkedItemsGroup.filter((item) => {
          return item.engagement_id !== matchedItem.engagement_id;
        });
      }

      // check if any item is checked
      if (state.group_data.data.some((item) => item.isCheckedGroup === true)) {
        state.isAnyItemCheckedGroup = true;
      } else {
        state.isAnyItemCheckedGroup = false;
      }
    },

    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;
    },
    setClearCheckedItem: (state) => {
      state.checkedItemsIndividual = [];
      state.checkedItemsGroup = [];
      state.isAnyItemCheckedInd = false;
      state.isAnyItemCheckedGroup = false;
    },

    setSelectedCoachingProgram: (state, { payload: index }) => {
      state.selectedCoachingProgram = state.coachingProgramsDropdown.find((item , i) => i === index );
    },
    setClearCoachingProgram: (state) => {
      state.coachingProgramsDropdown = [];
    },
    setSelectedEngagement: (state, { payload: index }) => {
      state.selectedEngagement = state.engagementsByCoachee.find((item , i) => i === index );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEngagements.fulfilled, (state, { payload }) => {
      const { individual_engagement, group_engagement } = payload;
      setStateData(
        state,
        {
          currentPage: group_engagement.current_page,
          pageSize: group_engagement.page_size,
          totalItem: group_engagement.total_item,
          totalPage: group_engagement.total_page,
          data: group_engagement.engagement_dto_list,
        },
        "group_data"
      );
      setStateData(
        state,
        {
          currentPage: individual_engagement.current_page,
          pageSize: individual_engagement.page_size,
          totalItem: individual_engagement.total_item,
          totalPage: individual_engagement.total_page,
          data: individual_engagement.engagement_dto_list,
        },
        "individual_data"
      );
    });
    builder.addCase(getEngagementsbyme.fulfilled, (state, { payload }) => {
      const { individual_engagement, group_engagement } = payload;
      setStateData(
        state,
        {
          currentPage: group_engagement.current_page,
          pageSize: group_engagement.page_size,
          totalItem: group_engagement.total_item,
          totalPage: group_engagement.total_page,
          data: group_engagement.engagement_dto_list,
        },
        "group_data"
      );
      setStateData(
        state,
        {
          currentPage: individual_engagement.current_page,
          pageSize: individual_engagement.page_size,
          totalItem: individual_engagement.total_item,
          totalPage: individual_engagement.total_page,
          data: individual_engagement.engagement_dto_list,
        },
        "individual_data"
      );
    });
    builder.addCase(getEngagementsBySponsor.fulfilled, (state, { payload }) => {
      const { individual_engagement, group_engagement } = payload;
      setStateData(
        state,
        {
          currentPage: group_engagement.current_page,
          pageSize: group_engagement.page_size,
          totalItem: group_engagement.total_item,
          totalPage: group_engagement.total_page,
          data: group_engagement.engagement_dto_list,
        },
        "group_data"
      );
      setStateData(
        state,
        {
          currentPage: individual_engagement.current_page,
          pageSize: individual_engagement.page_size,
          totalItem: individual_engagement.total_item,
          totalPage: individual_engagement.total_page,
          data: individual_engagement.engagement_dto_list,
        },
        "individual_data"
      );
    });
    builder.addCase(getAllCoachingPrograms.pending, (state) => {
      state.isCoachProgramsLoading = true;
    });
    builder.addCase(
      getAllCoachingPrograms.fulfilled,
      (state, { payload: { response: payload, coaching } }) => {
        state.isCoachProgramsLoading = false;
        if (payload.data) {
          if (payload.data?.length) {
            payload.data.unshift({
              coaching_program_id: null,
              coaching_program_name: `All ${coaching} Programs`,
            });
          } else {
            payload.data.unshift({
              coaching_program_id: null,
              coaching_program_name: `No ${coaching} Program Found`,
            });
          }
          state.coachingProgramsDropdown = payload.data;
          state.selectedCoachingProgram = state.coachingProgramsDropdown[0];
        }
      }
    );
    builder.addCase(getAllCoachingPrograms.rejected, (state) => {
      state.isCoachProgramsLoading = false;
    });
    builder.addCase(getCoachingProgramByMe.pending, (state) => {
      state.isCoachProgramsLoading = true;
    });
    builder.addCase(
      getCoachingProgramByMe.fulfilled,
      (state, { payload: { response: payload, coaching } }) => {
        state.isCoachProgramsLoading = false;

        if (payload.data) {
          payload.data.unshift({
            coaching_program_id: null,
            coaching_program_name: `All ${coaching} Programs`,
          });
        } else {
          payload.data.unshift({
            coaching_program_id: null,
            coaching_program_name: `No ${coaching} Program Found`,
          });
        }
        state.coachingProgramsDropdown = payload.data;
        state.selectedCoachingProgram = state.coachingProgramsDropdown[0];
      }
    );
    builder.addCase(getCoachingProgramByMe.rejected, (state) => {
      state.isCoachProgramsLoading = false;
    });
    builder.addCase(getCoachingProgramsBySponsor.pending, (state) => {
      state.isCoachProgramsLoading = true;
    });
    builder.addCase(
      getCoachingProgramsBySponsor.fulfilled,
      (state, { payload: { data: payload, coaching } }) => {
        state.isCoachProgramsLoading = false;

        if (payload.data) {
          payload.data.unshift({
            coaching_program_id: null,
            coaching_program_name: `All ${coaching} Programs`,
          });
        } else {
          payload.data.unshift({
            coaching_program_id: null,
            coaching_program_name: `No ${coaching} Program Found`,
          });
        }
        state.coachingProgramsDropdown = payload.data;
        state.selectedCoachingProgram = state.coachingProgramsDropdown[0];
      }
    );
    builder.addCase(getCoachingProgramsBySponsor.rejected, (state) => {
      state.isCoachProgramsLoading = false;
    });
    builder.addCase(getEngagmentsByCoachee.pending, (state) => {
      state.isCoachProgramsLoading = true;
    });
    builder.addCase(
      getEngagmentsByCoachee.fulfilled,
      (state, { payload: { data: payload, keywords } }) => {
        const { engagement, coach, coachee, action_plan, coaching } = keywords;
        let newData;
        if (payload.data) {
          state.isCoachProgramsLoading = false;
          newData = payload.data.map((item) => {
            return {
              ...item,
              engagement_name: `${!item.coach_name ? "" : item.coach_name}_${
                !item.coachee_name ? "" : item.coachee_name
              }_${
                !item.coaching_program_name
                  ? ""
                  : item.coaching_program_name + "Program"
              }`,
            };
          });
          newData.unshift({
            engagement_id: null,
            engagement_name: `Select ${engagement} (${coach}_${coachee}_${coaching} Program)`,
          });
        } else {
          newData.unshift({
            engagement_id: null,
            engagement_name: `No ${engagement}`,
          });
        }
        state.engagementsByCoachee = newData;
        state.selectedEngagement = state.engagementsByCoachee[0];
      }
    );
    builder.addCase(getEngagmentsByCoachee.rejected, (state) => {
      state.isCoachProgramsLoading = false;
    });
    builder
      .addCase(getFeedbackByEngagementID.pending, (state) => {
        state.feedbackData.loading = true;
      })
      .addCase(getFeedbackByEngagementID.fulfilled, (state, { payload }) => {
        state.feedbackData.loading = false;
        setStateData(state, payload, "feedbackData");
      })
      .addCase(getFeedbackByEngagementID.rejected, (state, { error }) => {
        state.feedbackData.loading = false;
        state.feedbackData.error = error;
      });
  },
});

const setStateData = (state, payload, dataType) => {
  if (dataType === "feedbackData") {
    state[dataType] = { ...state[dataType], ...payload };
  } else if (payload.data) {
    // this step is taken to add another property 'isChecked' to the data table
    payload.data.map((item) => {
      if (dataType === "individual_data") {
        item.isCheckedIndividual = false;
      } else if (dataType === "group_data") {
        item.isCheckedGroup = false;
      }
      return item;
    });
    if (dataType === "individual_data") {
      state.allItemsCheckedIndividual = false;
    } else if (dataType === "group_data") {
      state.allItemsCheckedGroup = false;
    }
    state[dataType] = payload;
  } else {
    state[dataType] = [];
  }
  return state;
};

// thunks are used to making asynchronous api calls
export const getAllEngagements = createAsyncThunk(
  "engagementConsole/getAllEngagements",
  async ({
    coaching_program_id = null,
    customer_id = null,
    ind_current_page,
    ind_page_size,
    group_current_page,
    group_page_size,
    is_dropdown,
    status,
    searchQuery,
  }) => {
    const response = await engagementConsoleAPIs.getAllEngagements({
      ind_current_page,
      ind_page_size,
      group_current_page,
      group_page_size,
      coaching_program_id,
      customer_id,
      is_dropdown,
      status,
      searchQuery,
    });
    return response;
  }
);
export const getEngagementsbyme = createAsyncThunk(
  "engagementConsole/getEngagementsbyme",
  async ({
    coaching_program_id = null,
    ind_current_page,
    ind_page_size,
    group_current_page,
    group_page_size,
    is_dropdown,
    status,
    searchQuery,
  }) => {
    const response = await engagementConsoleAPIs.getEngagementsbyme({
      ind_current_page,
      ind_page_size,
      group_current_page,
      group_page_size,
      coaching_program_id,
      is_dropdown,
      status,
      searchQuery,
    });
    return response;
  }
);
export const getEngagementsBySponsor = createAsyncThunk(
  "engagementConsole/getEngagementsBySponsor",
  async ({
    coaching_program_id,
    ind_current_page,
    ind_page_size = null,
    group_current_page,
    group_page_size = null,
    status = null,
    is_dropdown,
    searchQuery,
  }) => {
    const response = await engagementConsoleAPIs.getEngagementsBySponsor({
      coaching_program_id,
      ind_current_page,
      ind_page_size,
      group_current_page,
      group_page_size,
      status,
      is_dropdown,
      searchQuery,
    });
    return response;
  }
);
export const updateEngagement = createAsyncThunk(
  "engagementConsole/updateEngagement",
  async ({ body, engagement_id }) => {
    const response = await engagementConsoleAPIs.updateEngagement({
      body,
      engagement_id,
    });

    return response;
  }
);
export const getAllCoachingPrograms = createAsyncThunk(
  "engagementConsole/getAllCoachingPrograms",
  async ({ currentPage = -1, customer_id = null }, { getState }) => {
    let coaching = getState().keywords.keywords.coaching;
    const response = await engagementConsoleAPIs.getAllCoachingPrograms({
      current_page: currentPage,
      customer_id,
    });

    return { response, coaching };
  }
);
export const getCoachingProgramByMe = createAsyncThunk(
  "engagementConsole/getCoachingProgramByMe",
  async ({ current_page = -1, page_size = 5 }, { getState }) => {
    let coaching = getState().keywords.keywords.coaching;
    const response = await coachingProgramByMe.getCoachingProgramByMe(
      current_page,
      page_size
    );
    return { response, coaching };
  }
);
export const getCoachingProgramsBySponsor = createAsyncThunk(
  "engagementConsole/getCoachingProgramsBySponsor",
  async ({ currentPage = 1, pageSize = 5 }, { getState }) => {
    let coaching = getState().keywords.keywords.coaching;
    const response = await dashboard.getCoachingProgramBySponsor(
      currentPage,
      pageSize
    );
    const data = response.data;
    return { data, coaching };
  }
);
export const getEngagmentsByCoachee = createAsyncThunk(
  "engagementConsole/getEngagmentsByCoachee",
  async ({ currentPage = -1, pageSize = 5, coachee_id }, { getState }) => {
    let keywords = getState().keywords.keywords;
    const response = await getEngagmentByCoachee.getEngagmentByCoachee(
      currentPage,
      pageSize,
      coachee_id
    );
    const data = response.data;
    return { data, keywords };
  }
);

export const getFeedbackByEngagementID = createAsyncThunk(
  "engagementConsole/getFeedbackByEngagementID",
  async ({ engagement_id }) => {
    const response = await engagementConsoleAPIs.getFeedbackByEngagementID({
      engagement_id,
    });
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setAllItemsCheckedIndividual,
  setAllItemsCheckedGroup,
  setItemCheckedIndividual,
  setItemCheckedGroup,
  setClearCheckedItem,
  setSearchQuery,
  resetDataTable,
  setSelectedCoachingProgram,
  setSelectedEngagement,
  setClearCoachingProgram,
} = engagementConsoleSlice.actions;

export default engagementConsoleSlice.reducer;
