import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material UI icons
import EmailIcon from "@mui/icons-material/Email";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TelegramIcon from "@mui/icons-material/Telegram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

import BasicLayout from "./BasicLayout.jsx";

import LoadingButton from "components/custom-components/LoadingButton.jsx";

import { auth } from "utils/apiMethods.js";
import { capitalizeFirstLetters } from "utils/helper-method.js";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [ForgotPasswordError, setForgotPasswordError] = useState({
    isError: false,
    message: "",
  });
  const navigate = useNavigate();

  const formik = useFormik({
    // state value for email
    initialValues: {
      email: "",
    },
    // this onSubmit gets called when all fields are validated successfully
    onSubmit: async (values) => {
      setIsLoading(true); // set isLoading to true to show loading icon
      let body = { email: values.email };
      try {
        let result = await auth.forgotPassword(body);
        if (result && result.data && result.data.id) {
          setIsLoading(false);
          localStorage.setItem("forgotPassword-email", values.email);
          navigate("/verify-code");
        }
      } catch (err) {
        setForgotPasswordError({
          ...ForgotPasswordError,
          isError: true,
          message:
            (err.response?.data?.error_message?.length > 0 &&
              err.response?.data?.error_message[0]) ||
            err.response?.data?.title ||
            "Error",
        }); // show sign in error on catch
        hideLoaderAndErrors();
      }
    },
    // set up validations here
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email Address is Invalid")
        .required("Email Address is required"),
    }),
  });

  const hideLoaderAndErrors = () => {
    setIsLoading(false); // set isLoading to false to hide loading icon

    setTimeout(() => {
      setForgotPasswordError({
        ...ForgotPasswordError,
        isError: false,
        message: "",
      });
    }, 3000);
  };

  return (
    <BasicLayout>
      <MDBox mx={2} mt={-3} mb={10} textAlign="center">
        {/* Heading */}
        <MDTypography
          variant="h1"
          fontWeight="medium"
          color="white"
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MDTypography
            component={Link}
            to="/sign-in"
            variant="h1"
            color="white"
            fontWeight="bold"
            textGradient
          >
            <ChevronLeftIcon fontSize="xlarge" sx={{ mb: -1 }} />
          </MDTypography>
          <span style={{ marginLeft: 5, fontWeight: 400 }}>
            Forgot your password?
          </span>
        </MDTypography>
        <MDTypography
          variant="subtitle1"
          fontWeight="light"
          color="white"
          mt={1}
          textAlign="center"
          sx={{ maxWidth: 310, margin: "auto", marginTop: 2 }}
        >
          Please enter your email address to receive a verification code.
        </MDTypography>
      </MDBox>

      {/* Form */}
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          {/* Email Section*/}
          <MDBox mb={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="email"
                sx={{
                  color: "white !important",
                  padding: "0 15px 5px 0 !important",
                }}
              >
                Email *
              </InputLabel>
              <Input
                id="email"
                type="email"
                sx={{ marginLeft: 1.3 }}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon fontSize="medium" color="white" />
                  </InputAdornment>
                }
                label="Email"
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
              />
            </FormControl>
            {formik.touched.email && formik.errors.email ? (
              <MDTypography
                variant="caption"
                color="error"
                fontWeight="bold"
                sx={{ marginLeft: 1.5 }}
              >
                {formik.errors.email}
              </MDTypography>
            ) : null}
          </MDBox>

          {/* Sign-In Button Section */}
          <MDBox
            mt={16}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              isLoading={isLoading}
              handleSubmit={formik.handleSubmit}
              text="send"
              Icon={<TelegramIcon color="white" />}
            />
          </MDBox>

          {/* Error Alert Section */}
          {ForgotPasswordError.isError && (
            <>
              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  fontWeight="medium"
                >
                  {capitalizeFirstLetters(
                    ForgotPasswordError.message.split(" ")
                  )}
                </MDTypography>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}
