import colors from "assets/theme/base/colors";

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, functions } = theme;
  const {
    active,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    children,
    isCoachee,
  } = ownerState;

  const { white, transparent, dark, grey } = palette;

  const { pxToRem, rgba } = functions;
  // const isCoachee = useIsThisUser(all_roles.coachee);
  return {
    background: () => {
      let backgroundValue;

      if (transparentSidenav && darkMode) {
        backgroundValue = active ? rgba(white.main, 0.2) : transparent.main;
      } else if (transparentSidenav && !darkMode) {
        backgroundValue = active ? grey[300] : transparent.main;
      } else if (whiteSidenav) {
        backgroundValue = active ? grey[200] : transparent.main;
      } else {
        // backgroundValue = active && children ? rgba(white.main, 0.2) : active && !children ? palette.primary.main : transparent.main;
        backgroundValue =
          active && children
            ? rgba(white.main, 0.2)
            : isCoachee && active && !children
            ? colors.coacheePortal.primary
            : active && !children
            ? palette.primary.main
            : transparent.main;
      }

      return backgroundValue;
    },
    color:
      (transparentSidenav && !darkMode) || whiteSidenav
        ? dark.main
        : white.main,
    display: "flex",
    alignItems: "center",
    width: "90%",
    padding: `${pxToRem(3)} ${pxToRem(16)}`,
    margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
    marginRight: pxToRem(10),
    borderRadius: pxToRem(30),
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: "none",
    transition: transitions.create(["box-shadow", "background-color"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    "&:hover, &:focus": {
      backgroundColor: active && !children ? "" : "#dfe0e8",
      // backgroundColor: active && !children ?"" : colors.coacheePortal.backgroundColor,
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, borders, functions } = theme;
  const { active, children, isCoachee } = ownerState;

  const { borderRadius } = borders;
  const { pxToRem } = functions;
  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    // color: active && !children ? palette.white.main : "#000",
    // color: active && !children ? palette.white.main : palette.primary.main,
    color : isCoachee ? colors.coacheePortal.secondary :  active && !children ? palette.white.main : palette.primary.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    // "& svg, svg g": {
    //   color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    // },
  };
}

const collapseIcon = ({ palette: { primary } }, { active, children }) => ({
  // color: primary.main,
  color:"#fff"
});

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions, palette } = theme;
  const { miniSidenav, transparentSidenav, active, children, isCoachee } = ownerState;

  const { size } = typography;
  const { pxToRem } = functions;
  const { white } = palette;
  return {
    // marginLeft: pxToRem(10),
    // color: active && !children ? white.main : "#000",
    color : isCoachee ? colors.coacheePortal.secondary :  active && !children ? white.main : palette.primary.main,
    fontWeight: "bold",
    opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
    maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
    marginLeft:
      miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
    transition: transitions.create(["opacity", "margin"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
    // [breakpoints.up("xl")]: {
    //   opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
    //   maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
    //   marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
    //   transition: transitions.create(["opacity", "margin"], {
    //     easing: transitions.easing.easeInOut,
    //     duration: transitions.duration.standard,
    //   }),
    // },

    "& span": {
      fontWeight: "bold",
      fontSize: size.md,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme, ownerState) {
  const { transitions, breakpoints, functions, palette } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open, isCoachee, active , children } =
    ownerState;

  const { pxToRem } = functions;

  return {
    // fontSize: `x-large !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: () => {
      let colorValue = isCoachee ?colors.coacheePortal.secondary :palette.primary.main;
      // let colorValue = "#fff";

      return colorValue;
    },
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
    display:
      noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
        ? "none !important"
        : "block !important",
    // [breakpoints.up("xl")]: {
    //   display:
    //     noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
    //       ? "none !important"
    //       : "block !important",
    // },
  };
}

export {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
};
