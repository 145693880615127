import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accessAuditLog } from "utils/apiMethods";
import { dashboard } from "utils/apiMethods";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    data: { currentPage: 1, data: [], pageSize: 5, totalItem: 5, totalPage: 1 },
    userDropdown: [],
    action_typeData: [],
    selectedUser: {},
    selectedAction_type: null,
    allItemsChecked: false,
    isAnyItemChecked: true,
    selectedImage: null,
    start_date: null,
    end_date: null,
    noShowUser: null
  },
  reducers: {
    setAllItemsChecked: (state, { payload: allItemsChecked }) => {
      state.allItemsChecked = allItemsChecked;
      state.isAnyItemChecked = allItemsChecked;

      state.data.data.map((item) => {
        item.isChecked = allItemsChecked;
        return item;
      });
    },

    setItemChecked: (state, { payload: id }) => {
      let matchedItem = state.data.data.find((item) => item.id === id);
      matchedItem.isChecked = !matchedItem.isChecked;

      // check if any item is checked
      if (state.data.data.some((item) => item.isChecked === true)) {
        state.isAnyItemChecked = true;
      } else {
        state.isAnyItemChecked = false;
      }
    },
    setSelectedUser: (state, { payload: index }) => {
      if (state.selectedUser) state.selectedUser = state.userDropdown[index];
    },
    setSelectAction_type: (state, { payload: index }) => {
      state.selectedAction_type = index;
    },
    setSelectedImage: (state, { payload: image }) => {
      state.selectedImage = image;
    },
    setPagination: (state, { payload }) => {
      state.data.currentPage = payload.pageIndex + 1;
      state.data.pageSize = payload.pageSize;
    },
    setClearDropdown: (state) => {
      state.userDropdown = [];
      state.start_date = null;
      state.end_date = null;
    },
    resetPagination: (state, { payload }) => {
      state.data.currentPage = payload.pageIndex + 1;
      state.data.pageSize = payload.pageSize;
    },
    setTimeDate: (state, { payload }) => {
      const { date, flag } = payload;
      if (flag === "start_date") {
        state.start_date = date;
      } else if (flag === "end_date") {
        state.end_date = date;
      }
    },
    setNoShowUser: (state, { payload }) => {
      state.noShowUser = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersData.fulfilled, (state, { payload }) => {
      if (payload.data) {
        // this step is taken to add another property 'isChecked' to the data table
        payload.data.map((item) => {
          item.isChecked = false;
          return item;
        });

        state.allItemsChecked = false;
        state.isAnyItemChecked = false;
        state.data = payload;
        payload.data.unshift({
          id: null,
          first_name: "Select",
          last_name: " User"
        });

        state.userDropdown = payload.data;
        state.selectedUser = state.userDropdown[0];
      } else {
        state.data = [];
      }
    });
    builder.addCase(getActionType.fulfilled, (state, { payload }) => {
      if (payload.data) {
        payload.data.map((item) => {
          return item;
        });
        state.action_typeData = payload.data;
      }
    });
  }
});

// Action creators are generated for each case reducer function

// thunks are used to making asynchronous api calls
export const getUsersData = createAsyncThunk(
  "users/getUsersByRole",
  async ({ userType, currentPage, pageSize, customer_id }) => {
    const response = await dashboard.getUsersByRole(
      userType,
      currentPage,
      pageSize,
      customer_id
    );
    return response;
  }
);
export const getActionType = createAsyncThunk(
  "user/getActionType",
  async () => {
    const response = await accessAuditLog.getAuditActionType();
    return response;
  }
);
// Action creators are generated for each case reducer function
export const {
  setAllItemsChecked,
  setItemChecked,
  setPagination,
  resetPagination,
  setSelectedUser,
  setTimeDate,
  setSelectAction_type,
  setSelectedImage,
  setClearDropdown,
  setNoShowUser
} = usersSlice.actions;

export default usersSlice.reducer;
